import { useCallback, useMemo, useState } from 'react';
import { StateField } from '@prodelio/hooks/form/reducer';
import { ValidatorFunctions } from '@prodelio/hooks/form/validator.functions';

export interface ValidatorOptions {
  min?: number;
  max?: number;
  comparison?: {
    field: string;
    value: any;
  };
  pattern?: RegExp;
  isPatternEmpty?: boolean;
}

interface ValidatorField {
  validators: string[];
  options?: ValidatorOptions;
}

interface Validator {
  [key: string]: ValidatorField;
}

interface FormValues {
  [key: string]: StateField;
}

export const useValidation = (initialValidator: Validator) => {
  const validator = useMemo<Validator>(
    () => initialValidator,
    [initialValidator]
  );
  const [formErrors, setFormErrors] = useState<any>({});

  const validate = useCallback(
    (formValues: FormValues) => {
      let valid = true;
      const errors = Object.entries(formValues).reduce(
        (previousValue, [field, { value }]) => {
          const error = {
            [field]: '',
          };

          const validatorField = validator[field];
          if (!validatorField) {
            return previousValue;
          }

          for (const validatorMethod of validatorField.validators) {
            const msg = ValidatorFunctions[validatorMethod](
              field,
              value,
              validatorField.options
            );
            if (msg) {
              valid = false;
              error[field] = msg;
              break;
            }
          }

          return {
            ...previousValue,
            ...error,
          };
        },
        {}
      );

      setFormErrors({
        ...errors,
      });
      return valid;
    },
    [validator]
  );

  const resetFormErrors = useCallback(() => {
    setFormErrors({});
  }, []);

  return {
    validate,
    formErrors,
    resetFormErrors,
  };
};
