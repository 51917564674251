import { Request } from '@prodelio/hooks/api/Request.class';
import { apiWrapper } from '@prodelio/config/api/api';
import { toastError } from '@prodelio/config/toast/toast';
import { Project } from '@prodelio/config/state/projects/Project.interface';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const createProject = (set: any, get: any, request: Request) => {
  const localId = `${get().projects.length}`;

  const createdAt = new Date().toISOString();
  const updatedAt = createdAt;

  apiWrapper(request)
    .then((res) => updateProject(set, res.data.data, localId))
    .catch((e: any) => {
      toastError('Error when creating the project.', e.error);
      const syncProjects = get().syncProjects;
      set(() => ({
        syncProjects: [
          ...syncProjects,
          {
            id: localId,
            ...request.body,
            createdAt,
            updatedAt,
            syncStatus: SyncStatus.CREATED,
          },
        ],
      }));
    });

  const createdProject = {
    ...request.body,
    id: localId,
    createdAt,
    updatedAt,
  };
  set(({ projects }: any) => ({
    projects: [...projects, createdProject],
  }));
};

const updateProject = (set: any, serverProject: Project, localId: string) => {
  set(({ projects }: any) => ({
    projects: projects.map((project: Project) => {
      if (project.id !== localId) {
        return project;
      }

      return {
        ...project,
        ...serverProject,
      };
    }),
  }));
};

export type CreateProjectType = (request: Request) => void;
