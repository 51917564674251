export const reorder = (listData: any[], activeItem: any, overItem: any) => {
  if (!activeItem || activeItem.id === overItem?.id) {
    return listData;
  }

  const activeIndex = listData.findIndex((item) => item.id === activeItem.id);
  const overIndex = overItem
    ? listData.findIndex((item) => item.id === overItem.id)
    : 0;

  if (activeIndex === 0 && !overItem) {
    return listData;
  }

  let activeMoveGroup = activeItem.moveGroup;
  let overMoveGroup = overItem?.moveGroup ?? 0;

  const isSameTree =
    activeItem.parent &&
    (activeItem.parent === overItem?.parent ||
      activeItem.parent === overItem?.id);
  if (isSameTree) {
    activeMoveGroup = 0;
    overMoveGroup = 0;
  }

  const startSubsetIndex = Math.min(activeIndex, overIndex);
  const endSubsetIndex = Math.max(
    activeIndex + activeMoveGroup,
    overIndex + overMoveGroup
  );

  const leftSubset = listData.slice(0, startSubsetIndex);
  const rightSubset = listData.slice(endSubsetIndex + 1);
  const subsetToOrder = listData.slice(startSubsetIndex, endSubsetIndex + 1);

  const isBelowOver = activeIndex < overIndex;

  const swapTopElements = subsetToOrder.splice(
    0,
    (isBelowOver ? activeMoveGroup : overMoveGroup) + 1
  );
  const swapBottomElements = subsetToOrder.splice(
    -((isBelowOver ? overMoveGroup : activeMoveGroup) + 1)
  );

  if (isBelowOver) {
    return [
      ...leftSubset,
      ...subsetToOrder,
      ...swapBottomElements,
      ...swapTopElements,
      ...rightSubset,
    ];
  }

  if (!overItem) {
    return [
      ...leftSubset,
      ...swapBottomElements,
      ...swapTopElements,
      ...subsetToOrder,
      ...rightSubset,
    ];
  }

  return [
    ...leftSubset,
    ...swapTopElements,
    ...swapBottomElements,
    ...subsetToOrder,
    ...rightSubset,
  ];
};
