import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FormEvent } from 'react';
import { useLoginValidation } from '@prodelio/modules/auth/features/login/useLoginValidation.hook';
import { useAuth } from '@prodelio/hooks/auth/useAuth';
import { useForm } from '@prodelio/hooks/form/useForm';
import { AuthBody } from '@prodelio/hooks/auth/Login.request';
import { ApiErrorClass } from '@prodelio/hooks/api/ApiError.class';
import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';

export const LoginComponent = () => {
  const { setState } = useRouteProps();
  const { login } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const { formValues, onChange } = useForm({
    email: {
      value: '',
    },
    password: {
      value: '',
    },
  });
  const { formErrors, validate } = useLoginValidation();

  const transformFormValues = () => {
    return {
      username: formValues.email.value,
      password: formValues.password.value,
    };
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validate(formValues)) {
      return;
    }

    try {
      await login(transformFormValues() as AuthBody);
      navigate('/app/inbox');
    } catch (e: unknown) {
      if (e instanceof ApiErrorClass && e.data) {
        if (e.code === 403) {
          navigate('/account/confirmation');
          setState({
            email: e.data.email,
          });
        }

        toast({
          status: 'error',
          duration: 5000,
          title: 'Error signing up.',
          description: e.message,
          isClosable: true,
          position: 'top-left',
        });
      } else {
        toast({
          status: 'error',
          duration: 5000,
          title: 'Error signing up.',
          description: 'Something went wrong.',
          isClosable: true,
          position: 'top-left',
        });
      }
    }
  };

  return (
    <>
      <Heading
        as="h2"
        size="md"
        textAlign="center"
        color="typography.heading.light"
      >
        Sign In and Be Productive
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={5} mt={8} align="left" mx="auto">
          <FormControl isInvalid={!!formErrors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={formValues.email.value}
              onChange={onChange}
            />
            {formErrors.email && (
              <FormErrorMessage>{formErrors.email}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!formErrors.password}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={formValues.password.value}
              onChange={onChange}
            />
            {formErrors.password && (
              <FormErrorMessage>{formErrors.password}</FormErrorMessage>
            )}
          </FormControl>
          <HStack pt={2} justifyContent="center">
            <Button width={200} py="13px" variant="primary" type="submit">
              Sign In
            </Button>
          </HStack>
          <VStack alignItems="center">
            <Button variant="link" as={Link} to="/forgot-password" size="sm">
              Forgot password?
            </Button>
            <Button variant="link" as={Link} to="/register" size="sm">
              Don't have an account? Sign Up
            </Button>
          </VStack>
        </VStack>
      </form>
    </>
  );
};
