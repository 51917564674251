import { Project } from '@prodelio/config/state/projects/Project.interface';

export const editLocalProject = (
  set: any,
  get: any,
  projectId: string,
  data: Partial<Project>
) => {
  const projects = get().projects;
  set(() => ({
    projects: projects.map((project: Project) => {
      if (project.id === projectId) {
        return {
          ...project,
          ...data,
        };
      }

      return project;
    }),
  }));
};

export type EditLocalProjectType = (
  projectId: string,
  data: Partial<Project>
) => void;
