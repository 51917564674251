import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect } from 'react';
import { add, startOfTomorrow } from 'date-fns';
import { ListNextFourteenDaysTasks } from '@prodelio/modules/tasks/features/list-next-fourteen-days-tasks/ListNextFourteenDaysTasks';

export const NextFourteenDaysPage = () => {
  const { setTitle, setState } = useRouteProps();

  useEffect(() => {
    setTitle('Next 14 Days');
    setState({
      date: add(startOfTomorrow(), { days: 7 }),
      tags: 'next_actions',
    });
  }, []);

  return (
    <>
      <ListNextFourteenDaysTasks />
    </>
  );
};
