import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    background: 'primary.main',
  },
  body: {
    padding: '60px',
    boxShadow: '0 2px 7px 0 rgba(22, 22, 22, 0.57)',
    color: 'typography.text.light',
  },
});

export const Card = defineMultiStyleConfig({ baseStyle });
