import {
  TaskComponent,
  TaskProps,
} from '@prodelio/components/task/Task.component';
import { Box } from '@chakra-ui/react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { memo } from 'react';
import { isEqual } from 'lodash';

const DraggableTask = memo(
  (props: TaskProps) => {
    const {
      setNodeRef,
      listeners,
      attributes,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props.id,
      data: {
        date: props.dueDate,
        boardId: props.boardId,
      },
      transition: {
        duration: 200,
        easing: 'ease-in-out',
      },
    });

    const baseStyles = {
      transform: CSS.Translate.toString(transform),
      transition,
      pointerEvents: (isDragging
        ? 'none'
        : 'auto') as React.CSSProperties['pointerEvents'],
    };

    const draggingStyles = {
      ...baseStyles,
      height: '4px',
      overflow: 'hidden',
      '& .task': {
        background: 'var(--chakra-colors-accent-default)',
      },
    };

    const task = (
      <Box
        sx={isDragging ? draggingStyles : baseStyles}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        <TaskComponent {...props} />
      </Box>
    );

    return <>{task}</>;
  },
  (oldProps, newProps) => {
    return isEqual(oldProps, newProps);
  }
);

export default DraggableTask;
