import { ObjectId } from 'bson';

export enum SyncStatus {
  'DELETED' = 'DELETED',
  'EDITED' = 'EDITED',
  'CREATED' = 'CREATED',
}

export type TaskSort = {
  [key: string]: number;
};

export enum StatusEnum {
  TODO = 'TODO',
  DONE = 'DONE',
}

export interface Task {
  id: string;
  title: string;
  priority: number;
  dueDate: string;
  timeEstimation: number;
  timeTracked: number;
  status: StatusEnum;
  projectId: string;
  boardId: ObjectId | null;
  updatedAt: string;
  createdAt: Date | null;
  sort?: TaskSort;
  tags: string[];
  parentTaskId: string | null;
  subtasks?: Task[];
  assignedTo: string | null;
  syncStatus?: false | SyncStatus;
}
