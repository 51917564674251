import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect } from 'react';
import { ListTomorrowTasks } from '@prodelio/modules/tasks/features/list-tomorrow-tasks/ListTomorrowTasks';
import { startOfTomorrow } from 'date-fns';

export const TomorrowPage = () => {
  const { setTitle, setState } = useRouteProps();

  useEffect(() => {
    setTitle('Tomorrow');
    setState({
      date: startOfTomorrow(),
      tags: 'next_actions',
    });
  }, []);

  return (
    <>
      <ListTomorrowTasks />
    </>
  );
};
