import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Tooltip,
  VStack,
  Text,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useRef, useState } from 'react';
import { ModalComponent } from '@prodelio/components/modal/Modal.component';
import { useValidation } from '@prodelio/hooks/form/useValidation';
import { useForm } from '@prodelio/hooks/form/useForm';
import { PriorityConverter } from '@prodelio/modules/tasks/types/Priority.converter';
import { DatepickerComponent } from '@prodelio/components/datepicker/Datepicker.component';
import { CreateTaskRequest } from '@prodelio/modules/tasks/features/create-task/requests/CreateTask.request';
import { timeParser } from '@prodelio/modules/tasks/time.parser';
import { TaskConstants } from '@prodelio/modules/tasks/Task.constants';
import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useParams } from 'react-router-dom';
import { Select } from '@prodelio/components/form/select/Select';
import { useProjects } from '@prodelio/config/state/projects/selectors/getProjects';
import { useKeyShortcut } from '@prodelio/modules/common/hooks/useKeyShortcut';
import { useEventDispatch } from '@prodelio/event-system/hooks/useEventDispatch';
import { TaskEvents } from '@prodelio/modules/tasks/Task.events';
import { useEvent } from '@prodelio/event-system/hooks/useEvent';
import { Shortcut } from '@prodelio/components/shortcut/Shortcut.component';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';

export const CreateTask = () => {
  const {
    state: { date, tags },
  } = useRouteProps();
  const { projectId, taskId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { formValues, onChange, resetForm } = useForm(
    {
      title: {
        value: '',
      },
      timeEstimation: {
        value: '',
      },
      priority: {
        value: 0,
      },
      dueDate: {
        value: date,
      },
      project: {
        value: projectId ? projectId : '',
      },
      tags: {
        value: tags,
      },
    },
    true
  );
  const { formErrors, validate, resetFormErrors } = useValidation({
    title: {
      validators: ['empty'],
    },
    timeEstimation: {
      validators: ['pattern'],
      options: {
        pattern: TaskConstants.TIME_PATTERN,
        isPatternEmpty: true,
      },
    },
  });

  const projects = useProjects(undefined, [
    { orderBy: 'title', sortType: 'ASC' },
  ]);

  const createTaskAction = useTaskStore((state) => state.create);

  const initialRef = useRef<HTMLInputElement | null>(null);

  const dispatchEvent = useEventDispatch();

  const handleOpenModal = () => {
    setOpenModal(true);

    if (initialRef.current) {
      initialRef.current.focus();
    }
  };

  const handleCloseModal = () => {
    resetForm();
    resetFormErrors();
    setOpenModal(false);
  };

  const handleSubmit = () => {
    if (!validate(formValues)) {
      return;
    }

    setIsLoading(true);

    const projectId = formValues.project.value;
    const request = new CreateTaskRequest({
      title: formValues.title.value,
      dueDate: formValues.dueDate.value
        ? formValues.dueDate.value.toISOString()
        : formValues.dueDate.value,
      timeEstimation: timeParser(formValues.timeEstimation.value),
      timeTracked: 0,
      priority: +formValues.priority.value,
      projectId: projectId.length ? projectId : null,
      tags:
        formValues.tags.value || formValues.tags.value.length
          ? [formValues.tags.value].join(';')
          : '',
      parentTaskId: taskId ?? null,
    });
    createTaskAction(request, {});

    setIsLoading(false);
    handleCloseModal();
  };

  useEvent(TaskEvents.CREATE_TASK, handleSubmit);

  useKeyShortcut({
    shortcuts: {
      c: handleOpenModal,
      'CTRL+Enter': () => {
        dispatchEvent(TaskEvents.CREATE_TASK);
      },
    },
  });

  const modalActions = () => {
    const createShortcut = <Shortcut>CTRL + Enter</Shortcut>;

    return (
      <HStack pt="26px" pb="16px" spacing="20px">
        <Tooltip label={createShortcut}>
          <Button
            isLoading={isLoading}
            onClick={handleSubmit}
            variant="primary"
            loadingText="Creating..."
          >
            Create
          </Button>
        </Tooltip>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
      </HStack>
    );
  };

  const createTaskShorcut = (
    <HStack>
      <Text mb={1}>Create task</Text>
      <Shortcut>C</Shortcut>
    </HStack>
  );

  return (
    <>
      <Tooltip label={createTaskShorcut}>
        <IconButton
          onClick={handleOpenModal}
          size="sm"
          variant="icon-primary"
          aria-label="Create Task"
          icon={<AddIcon />}
        />
      </Tooltip>
      <ModalComponent
        open={openModal}
        onClose={handleCloseModal}
        title="Create Task"
      >
        <VStack spacing={6} alignItems="left">
          <FormControl ref={initialRef} isInvalid={!!formErrors.title}>
            <FormLabel>Task name</FormLabel>
            <Input
              variant="filled"
              type="text"
              name="title"
              value={formValues.title.value}
              onChange={onChange}
              ref={initialRef}
            />
            {formErrors.title && (
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!formErrors.timeEstimation}>
            <FormLabel>Time estimation</FormLabel>
            <Input
              variant="filled"
              type="text"
              name="timeEstimation"
              value={formValues.timeEstimation.value}
              onChange={onChange}
            />
            {formErrors.timeEstimation && (
              <FormErrorMessage>{formErrors.timeEstimation}</FormErrorMessage>
            )}
          </FormControl>
          <HStack gap="25px">
            <FormControl>
              <FormLabel>Project</FormLabel>
              <Select
                name="project"
                selected={formValues.project.value}
                onChange={onChange}
                options={[
                  { value: '', label: 'No Project Selected' },
                  ...projects.map(({ id, title }) => ({
                    value: id,
                    label: title,
                  })),
                ]}
              />
              {formErrors.priority && (
                <FormErrorMessage>{formErrors.priority}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Tags</FormLabel>
              <Select
                name="tags"
                selected={formValues.tags.value}
                onChange={onChange}
                options={[
                  { value: '', label: 'No Tags' },
                  { value: 'next_actions', label: 'Next Actions' },
                  { value: 'maybe', label: 'Maybe' },
                ]}
              />
              {formErrors.tags && (
                <FormErrorMessage>{formErrors.tags}</FormErrorMessage>
              )}
            </FormControl>
          </HStack>
          <HStack gap="25px">
            <FormControl isInvalid={!!formErrors.priority}>
              <FormLabel>Priority</FormLabel>
              <Select
                name="priority"
                selected={String(formValues.priority.value)}
                onChange={onChange}
                options={new Array(7).fill(null).map((_, index) => ({
                  value: String(index),
                  label:
                    index === 0
                      ? 'No Priority Selected'
                      : PriorityConverter[index],
                }))}
              />
              {formErrors.priority && (
                <FormErrorMessage>{formErrors.priority}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Due date</FormLabel>
              <DatepickerComponent
                inputProps={{
                  variant: 'filled',
                }}
                name="dueDate"
                value={formValues.dueDate.value}
                onChange={onChange}
              />
            </FormControl>
          </HStack>
          {modalActions()}
        </VStack>
      </ModalComponent>
    </>
  );
};
