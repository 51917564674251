export interface IRoute {
  slug: string;
  title: string;
}

export const routes: IRoute[] = [
  {
    slug: 'settings/general',
    title: 'General Settings',
  },
];
