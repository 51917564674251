import { Box, Heading } from '@chakra-ui/react';
import {
  TaskList,
  TaskListProps,
} from '@prodelio/components/task-list/TaskList';
import { getProject } from '@prodelio/config/state/projects/selectors/getProject';
import { useEffect } from 'react';
import { Request } from '@prodelio/hooks/api/Request.class';
import { Task } from '@prodelio/modules/tasks/state/types/Task';
import {
  TaskFilters,
  useSelectTasksV2,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';

export enum GroupedByType {
  PROJECT = 'PROJECT',
}

const GroupByFunctions = {
  [GroupedByType.PROJECT]: (
    currValue: { [key: string]: Task[] },
    element: Task
  ) => {
    const project = getProject(element.projectId);
    const projectName = project?.title ?? 'Inbox';

    const group = currValue[projectName] ?? [];
    group.push(element);

    currValue[projectName] = group;

    return currValue;
  },
};

interface GroupedListTaskProps {
  displayHeader?: boolean;
  TaskListProps: TaskListProps;
  filters: Partial<TaskFilters>;
  groupedBy: GroupedByType;
  request: Request;
}

export const GroupedListTask = ({
  displayHeader = true,
  filters,
  groupedBy,
  TaskListProps,
  request,
}: GroupedListTaskProps) => {
  const syncTasks = useTaskStore((state) => state.syncTasks);

  useEffect(() => {
    syncTasks(request, filters);
  }, []);

  const tasks = useSelectTasksV2(filters, TaskListProps.sort);

  const groupedTasks: { [key: string]: Task[] } = tasks.reduce<any>(
    GroupByFunctions[groupedBy],
    {}
  );

  const taskList = Object.entries(groupedTasks).map(([projectName, tasks]) => {
    return (
      <Box position="relative" key={projectName}>
        {displayHeader && (
          <Heading size="md" mb={6}>
            {projectName}
          </Heading>
        )}
        <TaskList
          {...TaskListProps}
          filters={{ ...TaskListProps.filters, projectId: tasks[0].projectId }}
          initialTasks={tasks}
        />
      </Box>
    );
  });

  return (
    <>
      {taskList.length ? (
        taskList
      ) : (
        <Box position="relative">
          <Heading size="md" mb={6}>
            Inbox
          </Heading>
          <TaskList {...TaskListProps} initialTasks={[]} />
        </Box>
      )}
    </>
  );
};
