import React from 'react';
import { SidebarComponent } from '@prodelio/components/sidebar/Sidebar.component';
import { Box, HStack } from '@chakra-ui/react';
import { DesktopNavbarComponent } from '@prodelio/components/navbar/desktop/DesktopNavbar.component';
import { LogoComponent } from '@prodelio/components/logo/Logo.component';
import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { HeaderPageComponent } from '@prodelio/components/header-page/HeaderPage.component';
import { ReactNode, useEffect } from 'react';
import { NavbarTimeTrackerFeature } from '@prodelio/modules/tasks/features/navbar-time-tracker/NavbarTimeTracker.feature';

import '@prodelio/layouts/app/App.layout.scss';
import {
  MobileNavbar,
  MobileNavbarMenu,
} from '@prodelio/components/navbar/mobile/MobileNavbar.component';
import { UserMenu } from '@prodelio/components/user-menu/UserMenu.component';
import { CreateTask } from '@prodelio/modules/tasks/features/create-task/CreateTask';

interface AppLayoutProps {
  sidebarLinks: JSX.Element;
  children: ReactNode;
  headerComponents?: JSX.Element | JSX.Element[];
  fullWidthHeader?: boolean;
}

export const AppLayout = ({
  children,
  headerComponents,
  sidebarLinks,
  fullWidthHeader = true,
}: AppLayoutProps) => {
  const { title } = useRouteProps();

  useEffect(() => {
    document.title = `Prodelio - ${title}`;
  }, [title]);

  return (
    <>
      <Box className="app-layout catd-app" h="100%">
        <Box>
          <DesktopNavbarComponent>
            <LogoComponent />
            <HStack spacing="30px">
              <NavbarTimeTrackerFeature />
              <CreateTask />
              <UserMenu />
            </HStack>
          </DesktopNavbarComponent>
          <MobileNavbar>
            <MobileNavbarMenu>
              <UserMenu direction="vertical" />
              <NavbarTimeTrackerFeature />
              {sidebarLinks}
            </MobileNavbarMenu>
            <LogoComponent />
            <CreateTask />
          </MobileNavbar>
        </Box>
        <Box>
          <SidebarComponent>{sidebarLinks}</SidebarComponent>
        </Box>
        <Box className="app-layout__tasks">
          <Box className="app-layout__tasks__content">
            <Box
              className="app-layout__tasks__container"
              maxW="800px"
              mx="auto"
            >
              <HeaderPageComponent fullWidth={fullWidthHeader} title={title}>
                {headerComponents}
              </HeaderPageComponent>
            </Box>
            <Box flexGrow={1} position="relative">
              <Box
                height="100%"
                className="app-layout__tasks__container"
                maxW="800px"
                mx="auto"
              >
                {children}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
