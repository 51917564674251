import { Alert, Skeleton, VStack } from '@chakra-ui/react';
import { store } from '@prodelio/config/state/store';
import { ReactNode, useEffect } from 'react';
import { TaskComponent } from '@prodelio/components/task/Task.component';
import { Request } from '@prodelio/hooks/api/Request.class';
import { Status } from '@prodelio/hooks/api/useApi';
import DraggableTask from '@prodelio/components/draggable-task/DraggableTask';
import { CreateInlineTask } from '@prodelio/modules/tasks/features/create-inline-task/CreateInlineTask';
import { useProjects } from '@prodelio/config/state/projects/selectors/getProjects';
import {
  TaskFilters,
  TaskSortOptions,
  useSelectTasksV2,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';
import { Task } from '@prodelio/modules/tasks/state/types/Task';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';

export interface TaskListProps {
  filters: Partial<TaskFilters>;
  request?: Request;
  deps?: any[];
  areDraggable?: boolean;
  children?: (
    taskElements: JSX.Element[] | JSX.Element,
    task: Task[]
  ) => ReactNode;
  initialTasks?: Task[];
  sort?: TaskSortOptions[];
  quickCreateTasks?: boolean;
}

export const TaskList = ({
  filters,
  request,
  deps = [],
  areDraggable = false,
  children,
  sort,
  initialTasks,
  quickCreateTasks: quickCreateInlineTasks,
}: TaskListProps) => {
  const syncTasks = useTaskStore((state) => state.syncTasks);
  const status = useTaskStore((state) => state.statusTask);

  useEffect(() => {
    request && syncTasks(request, filters);
  }, deps);

  const tasks = initialTasks ?? useSelectTasksV2(filters, sort);
  const projects = useProjects(undefined, [
    { orderBy: 'title', sortType: 'ASC' },
  ]);

  const renderBody = () => {
    const taskElements = tasks.length ? (
      tasks.map(
        ({
          id,
          title,
          priority,
          dueDate,
          timeEstimation,
          timeTracked,
          status,
          sort,
          tags,
          assignedTo,
          projectId,
          boardId,
        }: Task) => {
          const taskProps = {
            id,
            name: title,
            priority,
            dueDate: dueDate ? new Date(dueDate) : null,
            boardId: boardId,
            timeEstimated: timeEstimation,
            timeTracked: timeTracked,
            status: status,
            sort: sort,
            tags: tags,
            assignedTo: assignedTo
              ? projects
                  .find(({ id }) => id === projectId)
                  ?.sharedWith.find(({ userId }) => assignedTo === userId)
                  ?.username
              : '',
          };

          return areDraggable ? (
            <DraggableTask key={id} {...taskProps} />
          ) : (
            <TaskComponent key={id} {...taskProps} />
          );
        }
      )
    ) : (
      <Alert status="info">There are no tasks for this project</Alert>
    );

    return (
      <VStack alignItems="stretch" gap={4}>
        {children ? children(taskElements, tasks) : taskElements}
        {quickCreateInlineTasks !== false && (
          <CreateInlineTask
            dueDate={filters.date}
            project={filters.projectId}
            boardId={filters.boardId}
          />
        )}
      </VStack>
    );
  };

  const renderSkeleton = () => {
    return (
      <VStack alignItems="stretch" gap={6}>
        <Skeleton height="61px" />
        <Skeleton height="61px" />
        <Skeleton height="61px" />
        <Skeleton height="61px" />
        <Skeleton height="61px" />
      </VStack>
    );
  };

  const isLoading =
    [Status.WAITING, Status.LOADING].includes(status) && !tasks.length;

  return isLoading ? renderSkeleton() : renderBody();
};
