import { Button, HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import { useBoardStore } from '@prodelio/modules/projects/state/useBoardStore';
import { Board } from '@prodelio/modules/projects/state/Board';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DeleteBoardRequest } from '@prodelio/modules/projects/features/delete-board/DeleteBoardRequest';
import { useState } from 'react';
import { ModalComponent } from '@prodelio/components/modal/Modal.component';

interface Props {
  board: Board;
}

export const DeleteBoard = ({ board }: Props) => {
  const { projectId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);

  const deleteBoard = useBoardStore().deleteBoard;

  if (!projectId) {
    return null;
  }

  const handleSubmit = async () => {
    setLoading(true);
    const request = new DeleteBoardRequest(board);

    deleteBoard(request, board);

    setLoading(false);
    onClose();
  };

  const renderActions = () => (
    <HStack spacing="20px" mt="35px">
      <Button colorScheme="red" isLoading={loading} onClick={handleSubmit}>
        Delete
      </Button>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
    </HStack>
  );

  return (
    <>
      <IconButton
        onClick={onOpen}
        size="sm"
        variant="icon"
        color="error.default"
        aria-label="Delete board"
        icon={<FontAwesomeIcon icon={solid('trash')} />}
      />
      <ModalComponent title="Delete board" open={isOpen} onClose={onClose}>
        <>Are you sure? You can't undo this action afterwards.</>
        {renderActions()}
      </ModalComponent>
    </>
  );
};
