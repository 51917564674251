import { TimeTracker } from '@prodelio/components/time-tracker/TimeTracker.component';
import { useTimeTrackerContext } from '@prodelio/hooks/time-tracker/useTimeTrackerContext';
import { TrackTimeInterval } from '@prodelio/modules/tasks/time-tracker/TrackTime.interval';

export const NavbarTimeTrackerFeature = () => {
  const {
    state: { time, taskId },
    onStop,
    onPause,
  } = useTimeTrackerContext();

  const onPlay = TrackTimeInterval(taskId || '');

  return (
    <>
      {taskId && (
        <TimeTracker
          time={time}
          onStop={onStop}
          onPause={onPause}
          onPlay={onPlay}
        />
      )}
    </>
  );
};
