import { useContext, useEffect } from 'react';
import {
  KeyShortcutsContext,
  TKeyShortcutHandler,
} from '@prodelio/modules/common/contexts/KeyShortcuts.context';
import { KeyCombination } from '@prodelio/modules/common/models/KeyCombination';

type TAction = () => void;

type TKeyShortcut = Record<string, TAction>;

interface IShortcutConfig {
  shortcuts: TKeyShortcut;
}

export const useKeyShortcut = ({ shortcuts }: IShortcutConfig) => {
  const combinations = Object.keys(shortcuts);

  const attachedShortcuts = useContext(KeyShortcutsContext);

  const generateAction = (
    combination: string,
    action: TAction
  ): TKeyShortcutHandler => {
    return (e: KeyboardEvent) => {
      const keyCombination = new KeyCombination(combination);
      const { key, altKey, shiftKey, ctrlKey } = keyCombination.value;

      let target: HTMLElement | null = null;
      if (e.target instanceof HTMLElement) {
        target = e.target;
      }

      if (
        e.key === key &&
        e.altKey === altKey &&
        e.shiftKey === shiftKey &&
        e.ctrlKey === ctrlKey
      ) {
        const metaKeys = altKey || shiftKey || ctrlKey;

        if (target && !target.contains(document.body) && !metaKeys) {
          return;
        }

        e.preventDefault();
        action();
      }
    };
  };

  const attachCombinations = () => {
    for (const combination of combinations) {
      const prevAction = attachedShortcuts.get(combination);

      if (prevAction) {
        window.removeEventListener('keydown', prevAction);
      }

      const action = generateAction(combination, shortcuts[combination]);

      attachedShortcuts.set(combination, action);

      window.addEventListener('keydown', action);
    }
  };

  const dettachCombinations = () => {
    for (const combination of combinations) {
      const action = attachedShortcuts.get(combination);
      if (action) {
        window.removeEventListener('keydown', action);
      }
    }
  };

  useEffect(() => {
    attachCombinations();

    return dettachCombinations;
  }, []);
};
