import { Button, useToast } from '@chakra-ui/react';
import { store } from '@prodelio/config/state/store';
import { useAuth } from '@prodelio/hooks/auth/useAuth';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
  const { logout } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  const cleanStore = store((state) => state.cleanStore);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
      cleanStore('tasks');
      cleanStore('syncTasks');
      cleanStore('projects');
      cleanStore('syncProjects');
    } catch (e: any) {
      toast({
        status: 'error',
        duration: 5000,
        title: 'Error when logging out.',
        description: e.error,
        isClosable: true,
        position: 'top-left',
      });
    }
  };

  return (
    <Button
      width="100%"
      variant="menu"
      justifyContent="left"
      onClick={handleLogout}
    >
      Logout
    </Button>
  );
};
