import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { DateRange } from '@prodelio/modules/common/date/DateRange';
import { addDays, endOfDay, startOfDay } from 'date-fns';

export class GetNextSevenDaysTasksRequest extends Request {
  public method: ApiMethods = ApiMethods.GET;
  public url = '/tasks';

  constructor() {
    super();

    const today = new Date();
    const dateRange = new DateRange(
      startOfDay(today),
      endOfDay(addDays(today, 7))
    );

    this.addFilters({
      dueDate__lte: dateRange.end.toISOString(),
    });
  }
}
