import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import {
  closestCenter,
  CollisionDetection,
  DndContext,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  SortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from '@prodelio/components/sortable-list/SortableItem';
import { reorder } from '@prodelio/components/sortable-list/utils/reorder';

interface SortableItemList {
  id: string;
  indentation: number;
  moveGroup?: number;
  display?: boolean;
  props: Record<any, any>;
}

interface SortableList {
  items: SortableItemList[];
  Component: any;
  collisionDetection?: CollisionDetection;
  strategy?: SortingStrategy;
  spacing?: number | string;
  onDragEnd: (items: string[]) => void;
  onDragMove?: (dragEvent: DragMoveEvent) => void;
  onDragOver?: (dragEvent: DragOverEvent) => void;
  onDragStart?: (dragEvent: DragStartEvent) => void;
}

export const SortableList = ({
  items,
  Component,
  spacing = '12px',
  onDragEnd,
  onDragOver = () => {},
  onDragStart = () => {},
  onDragMove = () => {},
  collisionDetection = closestCenter,
  strategy = verticalListSortingStrategy,
}: SortableList) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 10,
      },
    })
  );

  const [activeId, setActiveId] = useState<string | null>(null);
  const [overId, setOverId] = useState<string | null>(null);
  const activeItem = useMemo(() => {
    if (!activeId) {
      return null;
    }

    const item = items.find(({ id }) => id === activeId);
    if (!item) return null;

    return <Component {...item.props} />;
  }, [items, activeId]);

  const [listData, setListData] = useState<SortableItemList[]>(items);
  const itemsId = useMemo(() => {
    const ids = listData.map(({ id }) => id);
    ids.unshift('start');

    return ids;
  }, [listData]);

  useEffect(() => {
    if (items.length !== listData.length) {
      setListData(items);
      return;
    }

    setListData(
      listData.map((listItem) => {
        const item = items.find(({ id }) => id === listItem.id);

        return {
          ...listItem,
          ...item,
        };
      })
    );
  }, [items]);

  const handleDragStart = useCallback(
    (dragEvent: DragStartEvent) => {
      setActiveId(dragEvent.active.id as string);
      onDragStart(dragEvent);
    },
    [onDragStart]
  );

  const handleDragOver = (overEvent: DragOverEvent) => {
    setOverId(overEvent.over?.id as string);
    onDragOver(overEvent);
  };

  const handleDragEnd = () => {
    setActiveId(null);

    if (!overId) return;

    const activeItem = listData.find(({ id }) => activeId === id);
    const overItem = listData.find(({ id }) => overId === id);
    const reorderedItems = reorder(listData, activeItem, overItem);

    setListData(reorderedItems);
    onDragEnd(reorderedItems.map(({ id }) => id));
  };

  return (
    <Box position="relative">
      <DndContext
        collisionDetection={collisionDetection}
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragMove={onDragMove}
        onDragEnd={handleDragEnd}
      >
        <SortableContext strategy={strategy} items={itemsId}>
          <Box position="absolute" top="0" width="100%">
            <SortableItem indentation={0} id="start">
              <Box h="6px" width="100%" />
            </SortableItem>
          </Box>
          <VStack align="stretch" spacing={spacing}>
            {listData.map(
              ({ id, indentation, props, display }) =>
                display && (
                  <SortableItem key={id} indentation={indentation} id={id}>
                    <Component {...props} />
                  </SortableItem>
                )
            )}
          </VStack>
        </SortableContext>
        <DragOverlay>
          <Box opacity={0.7}>{activeItem}</Box>
        </DragOverlay>
      </DndContext>
    </Box>
  );
};
