import { NavLink } from 'react-router-dom';
import { Box, Button, IconButton } from '@chakra-ui/react';
import { Dropdown } from '@prodelio/components/dropdown/Dropdown.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';

import '@prodelio/components/project-with-dropdown/ProjectWithDropdown.component.scss';

interface ProjectWithDropdownProps {
  id: string;
  title: string;
  isDraggable?: boolean;
  actions?: JSX.Element[] | JSX.Element;
  isOverlay?: boolean;
  isSubproject?: boolean;
}

export const ProjectWithDropdown = ({
  id,
  title,
  actions,
  isOverlay = false,
}: ProjectWithDropdownProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickAction = () => {
    setIsEditing(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box
      className={`project-with-dropdown ${
        isEditing && 'project-with-dropdown--editing'
      }`}
    >
      <Button
        className="project-with-dropdown__link"
        variant="navlink"
        as={NavLink}
        to={`/app/project/${id}`}
      >
        {title}
      </Button>
      {isOverlay || (
        <Box onClick={handleClickAction} ref={dropdownRef}>
          <Dropdown
            className="project-with-dropdown__actions"
            button={
              <IconButton
                size="sm"
                variant="icon-link-primary"
                aria-label="Open project actions"
                icon={<FontAwesomeIcon size="lg" icon={faEllipsisVertical} />}
              />
            }
          >
            {actions}
          </Dropdown>
        </Box>
      )}
    </Box>
  );
};
