import { Heading, HStack } from '@chakra-ui/react';

interface Props {
  title: string;
  fullWidth?: boolean;
  children?: JSX.Element | JSX.Element[];
}

export const HeaderPageComponent = ({
  title,
  fullWidth = true,
  children,
}: Props) => {
  return (
    <HStack
      justifyContent={fullWidth ? 'space-between' : 'start'}
      alignItems="center"
      mb={10}
      spacing="30px"
    >
      <Heading as="h2" fontSize="22px">
        {title}
      </Heading>
      <HStack spacing={4}>{children}</HStack>
    </HStack>
  );
};
