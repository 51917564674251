export class DateRange {
  public start: Date;
  public end: Date;

  constructor(start: Date | string, end: Date | string) {
    this.start = new Date(start);
    this.end = new Date(end);
  }

  toString(): string {
    return `${this.start.toISOString()},${this.end.toISOString()}`;
  }
}
