import { SortingStrategy } from '@dnd-kit/sortable';
import { FlattenedProject } from '@prodelio/config/state/projects/Project.interface';
import { getProject } from '@prodelio/config/state/projects/selectors/getProject';
import { getItemGap } from '@prodelio/components/sortable-list/utils/getItemGap';
import { getDefaultDraggableScale } from '@prodelio/components/sortable-list/utils/getDefaultDraggableScale';
import { bottomVerticalStrategy } from '@prodelio/components/sortable-list/strategies/bottomVerticalStrategy';

export const sortingStrategy = (
  projects: FlattenedProject[],
  activeId: string | null,
  overId: string | null,
  canBeSubproject: boolean,
  verticalSpacing: number
): SortingStrategy => {
  if (canBeSubproject) {
    return bottomVerticalStrategy(activeId, overId, projects);
  }

  return ({
    activeIndex,
    activeNodeRect: fallbackActiveRect,
    index,
    rects,
  }) => {
    const result = getDefaultDraggableScale();
    const activeNodeRect = rects[activeIndex] ?? fallbackActiveRect;

    if (!activeNodeRect || !activeId || !overId || activeId === overId) {
      return null;
    }

    let overProject = getProject(overId);
    if (overProject && overProject.parentProject) {
      overProject = getProject(overProject?.parentProject);
    }
    const overIndex =
      overId !== 'start'
        ? projects.findIndex(({ id }) => id === overProject?.id) + 1
        : 0;

    const overChildren = projects.filter(
      ({ parentProject, id }) =>
        overProject?.id === parentProject && id !== activeId
    );

    const elementsToSkip = overChildren.length + 1;
    const distributedGap = verticalSpacing / elementsToSkip;

    if (index === activeIndex && overId !== 'start') {
      const overIndexRect = rects[overIndex];

      if (!overIndexRect) {
        return null;
      }

      const cursorGap = getItemGap(rects, overIndex, activeIndex);

      const elementToSkipHeight =
        (overIndexRect.height + cursorGap) * overChildren.length;
      const topPosition =
        overIndexRect.top +
        overIndexRect.height +
        cursorGap +
        elementToSkipHeight;
      let yPosition = topPosition - activeNodeRect.top;

      // Position going down
      if (activeIndex < overIndex) {
        const bottomOverIndex = overIndexRect.top + overIndexRect.height;
        const bottomActiveIndex = activeNodeRect.top + activeNodeRect.height;

        yPosition = bottomOverIndex + elementToSkipHeight - bottomActiveIndex;
      }

      result.y = yPosition;
    }

    if (activeIndex === index && overId === 'start') {
      result.y = rects[0].top - activeNodeRect.top;
    }

    const baselineIndex = overIndex + overChildren.length;

    // Above static active index
    if (index > activeIndex && index <= baselineIndex) {
      result.y = (-activeNodeRect.height - distributedGap) * elementsToSkip;
    }

    // Below static active index
    if (index < activeIndex && index > baselineIndex) {
      result.y = (activeNodeRect.height + distributedGap) * elementsToSkip;
    }

    return result;
  };
};
