import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import '@prodelio/components/modal/Modal.component.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
  open: boolean;
  onClose: () => void;
  actions?: JSX.Element | JSX.Element[];
  title: string;
}

export const ModalComponent = ({
  children,
  open,
  onClose,
  actions,
  title,
}: Props) => {
  return (
    <>
      <Modal isOpen={open} onClose={onClose} closeOnEsc>
        <ModalOverlay className="modal__overlay" />
        <ModalContent className="modal">
          <ModalHeader className="modal__header">{title}</ModalHeader>
          <ModalBody className="modal__body">{children}</ModalBody>
          {actions && (
            <ModalFooter className="modal__footer">{actions}</ModalFooter>
          )}
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
