import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ListTasksByProject } from '@prodelio/modules/tasks/features/list-tasks-by-project/ListTasksByProject';
import { store } from '@prodelio/config/state/store';

export const ProjectPage = () => {
  const { setTitle, setState } = useRouteProps();

  const { projectId } = useParams();
  const project = store((state) =>
    state.projects.find(({ id }) => id === projectId)
  );

  useEffect(() => {
    setTitle(project?.title ? project.title : 'Project');
    setState({
      date: null,
    });
  }, [project]);

  return (
    <>
      <ListTasksByProject />
    </>
  );
};
