import { Button } from '@prodelio/config/theme/components/Button';
import { FormLabel } from '@prodelio/config/theme/components/FormLabel';
import { Input } from '@prodelio/config/theme/components/Input';
import { Select } from '@prodelio/config/theme/components/Select';
import { Alert } from '@prodelio/config/theme/components/Alert';
import { Card } from '@prodelio/config/theme/components/Card';
import { Menu } from '@prodelio/config/theme/components/Menu';

export const components = {
  Button,
  FormLabel,
  Input,
  Select,
  Alert,
  Menu,
  Card,
};
