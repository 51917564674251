import { apiWrapper } from '@prodelio/config/api/api';
import { toastError } from '@prodelio/config/toast/toast';
import {
  Project,
  SyncProject,
} from '@prodelio/config/state/projects/Project.interface';
import { DeleteProjectRequest } from '@prodelio/modules/projects/features/delete-project/request/DeleteProject.request';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const deleteProject = (set: any, get: any, projectId: string) => {
  if (isNaN(+projectId)) {
    const deleteProjectReq = new DeleteProjectRequest(projectId);
    apiWrapper(deleteProjectReq).catch((e: any) => {
      toastError('Error when deleting the project.', e.error);

      const syncProjects = get().syncProjects;
      set(() => ({
        syncProjects: [
          ...syncProjects,
          {
            id: projectId,
            ...deleteProjectReq.body,
            syncStatus: SyncStatus.DELETED,
          },
        ],
      }));
    });
  }

  set(() => {
    const newState: { projects: Project[]; syncProjects?: SyncProject[] } = {
      projects: get().projects.filter(({ id }: Project) => id !== projectId),
    };

    if (!isNaN(+projectId)) {
      newState.syncProjects = get().syncProjects.filter(
        ({ id }: Project) => id !== projectId
      );
    }

    return newState;
  });
};

export type DeleteProjectType = (projectId: string) => void;
