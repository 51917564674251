import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MsToTimeParser } from '@prodelio/modules/tasks/MsToTime.parser';
import { TimeTrackerConstants } from '@prodelio/hooks/time-tracker/TimeTracker.constants';

import '@prodelio/components/time-tracker/TimeTracker.component.scss';
import { useTimeTrackerContext } from '@prodelio/hooks/time-tracker/useTimeTrackerContext';
import { TimeTrackerStatus } from '@prodelio/context/time-tracker/TimeTracker.context';
import { SyntheticEvent } from 'react';

interface TimeTrackerProps {
  time: number;
  isActive?: boolean;
  displayTimer?: boolean;
  onStop?: () => void;
  onPause?: () => void;
  onPlay?: () => void;
}

export const TimeTracker = ({
  time,
  isActive = true,
  onPlay,
  onPause,
  onStop,
  displayTimer = true,
}: TimeTrackerProps) => {
  const { state } = useTimeTrackerContext();
  const status = isActive ? state.status : TimeTrackerStatus.IDLE;
  const timeTrackerClassName = `time-tracker--${status}`.toLowerCase();

  const valuesToDisplay = {
    hours: time >= TimeTrackerConstants.HOUR,
    minutes: true,
    seconds: true,
  };

  const timer = displayTimer && (
    <div className="time-tracker__timer">
      <strong>{MsToTimeParser(time, 'CLOCK', valuesToDisplay)}</strong>
    </div>
  );

  const handlePlay = () => {
    onPlay && onPlay();
  };

  const handlePause = () => {
    onPause && onPause();
  };

  const handleStop = () => {
    onStop && onStop();
  };

  const handlePropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div
      className={`time-tracker ${timeTrackerClassName}`}
      onClick={handlePropagation}
    >
      {timer}
      <FontAwesomeIcon
        onClick={handlePause}
        className="time-tracker__icon time-tracker__pause"
        icon={solid('pause')}
      />
      <FontAwesomeIcon
        onClick={handlePlay}
        className="time-tracker__icon time-tracker__play"
        icon={solid('play')}
      />
      <FontAwesomeIcon
        onClick={handleStop}
        className="time-tracker__icon time-tracker__stop"
        icon={solid('stop')}
      />
    </div>
  );
};
