import { createContext, ReactNode } from 'react';

export type TKeyShortcutHandler = (e: KeyboardEvent) => void;

export type TKeyShortcuts = Map<string, TKeyShortcutHandler>;

export const KeyShortcutsContext = createContext<TKeyShortcuts>(new Map());

export const KeyShortcuts = ({ children }: { children: ReactNode }) => {
  return (
    <KeyShortcutsContext.Provider value={new Map()}>
      {children}
    </KeyShortcutsContext.Provider>
  );
};
