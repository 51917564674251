import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '@prodelio/components/datepicker/Datepicker.component.scss';

import es from 'date-fns/locale/es';
import { forwardRef } from 'react';
import {
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react';
import { CalendarIcon, IconProps } from '@chakra-ui/icons';
registerLocale('es', es);

interface DatepickerComponentProps {
  onChange: ({ target }: any) => void;
  value: Date | null | undefined;
  name: string;
  inputProps?: InputProps;
  inputGroupProps?: InputGroupProps;
  iconProps?: IconProps;
}

export const DatepickerComponent = ({
  iconProps = {},
  inputGroupProps = {},
  inputProps = {},
  onChange,
  value,
  name,
}: DatepickerComponentProps) => {
  const DatePickerInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <InputGroup {...inputGroupProps}>
      <InputLeftElement children={<CalendarIcon {...iconProps} />} />
      <Input
        {...inputProps}
        readOnly
        value={value}
        onClick={onClick}
        ref={ref}
      />
    </InputGroup>
  ));

  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      locale="es"
      name={name}
      selected={value}
      onChange={(date: Date) => onChange({ target: { value: date, name } })}
      customInput={<DatePickerInput />}
      calendarClassName="datepicker"
      isClearable
    />
  );
};
