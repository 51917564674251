import { Request } from '@prodelio/hooks/api/Request.class';

export class GetBoardsRequest extends Request {
  url = '/projects/:projectId/boards';

  constructor(projectId: string) {
    super();
    this.url = this.url.replace(':projectId', projectId);
  }
}
