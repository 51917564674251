import { TaskView } from '@prodelio/components/task-view/TaskView';
import { useEffect } from 'react';
import { GetTodayTasksRequest } from '@prodelio/modules/tasks/features/list-today-tasks/GetTodayTasks.request';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import {
  TaskSortOptions,
  useSelectTasksV2,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';
import { endOfToday } from 'date-fns';

export const ListTodayTasks = () => {
  const request = new GetTodayTasksRequest();

  const filters = {
    endDate: endOfToday(),
  };

  const syncTasks = useTaskStore((state) => state.syncTasks);

  useEffect(() => {
    syncTasks(request, filters);
  }, []);

  const sort = [TaskSortOptions.MANUAL];

  const tasks = useSelectTasksV2(filters, sort);

  const sections = [
    {
      key: 'ListTodayTasks',
      header: '',
      tasks,
      filters,
      sort,
      displayHeader: false,
    },
  ];

  return <TaskView taskFilters={filters} sections={sections} />;
};
