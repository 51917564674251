import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { apiWrapper } from '@prodelio/config/api/api';
import { toastError, toastSuccess } from '@prodelio/config/toast/toast';
import { useAuth } from '@prodelio/hooks/auth/useAuth';
import { useForm } from '@prodelio/hooks/form/useForm';
import { useValidation } from '@prodelio/hooks/form/useValidation';
import { ForgotPasswordRequest } from '@prodelio/modules/auth/features/forgot-password/requests/ForgotPassword.request';
import { SyntheticEvent, useMemo } from 'react';
import { EditUserRequest } from '@prodelio/modules/settings/features/edit-user/requests/EditUser.request';

export const EditUserFeature = () => {
  const { user, setAuth } = useAuth();

  const initialState = useMemo(
    () => ({
      name: {
        value: user?.name ?? '',
      },
      username: {
        value: user?.username,
      },
    }),
    [user]
  );

  const { formValues, onChange } = useForm(initialState, true);
  const { formErrors, validate } = useValidation({
    name: {
      validators: ['length', 'empty'],
    },
    username: {
      validators: ['length', 'empty'],
    },
  });

  const handleChangePassword = async () => {
    try {
      const forgotPasswordRequest = new ForgotPasswordRequest({
        email: user?.email,
      });

      await apiWrapper(forgotPasswordRequest);
      toastSuccess('Reset link has been sent.', '');
    } catch (e: any) {
      toastError('Error sending password link.', '');
    }
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!validate(formValues)) {
      return;
    }

    try {
      const editRequest = new EditUserRequest({
        name: formValues.name.value,
        username: formValues.username.value,
      });

      await apiWrapper(editRequest);
      setAuth(editRequest.body);
    } catch (error) {
      toastError(
        'Something wrong happened.',
        'User could not be edited, try again later.'
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing="20px">
        <FormControl isInvalid={!!formErrors.name}>
          <FormLabel>Name</FormLabel>
          <Input
            variant="filled"
            type="text"
            name="name"
            value={formValues.name.value}
            onChange={onChange}
          />
          {formErrors.name && (
            <FormErrorMessage>{formErrors.name}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!formErrors.username}>
          <FormLabel>Username</FormLabel>
          <Input
            variant="filled"
            type="text"
            name="username"
            value={formValues.username.value}
            onChange={onChange}
          />
          {formErrors.username && (
            <FormErrorMessage>{formErrors.username}</FormErrorMessage>
          )}
        </FormControl>
        <Box width="100%">
          <FormControl isDisabled>
            <FormLabel>Password</FormLabel>
            <Input type="text" name="password" value="*******************" />
          </FormControl>
          <Button onClick={handleChangePassword} mt="5px" variant="secondary">
            Change password
          </Button>
        </Box>
        <Box width="100%">
          <FormControl isDisabled>
            <FormLabel>Email</FormLabel>
            <Input type="text" name="email" value={user?.email} />
          </FormControl>
        </Box>
        <Button type="submit" variant="primary" width="100%">
          Save
        </Button>
      </VStack>
    </form>
  );
};
