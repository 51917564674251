import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import { BreadcrumbBuilder } from '@prodelio/router/breadcrumbs/BreadcrumbBuilder';

import '@prodelio/components/breadcrumbs/Breadcrumbs.component.scss';

export const Breadcrumbs = () => {
  const location = useLocation();
  const { taskId } = useParams();

  const urlStrategy = new BreadcrumbBuilder(location.pathname, taskId);
  const links = urlStrategy.getLinks();

  return (
    <Breadcrumb className="breadcrumbs">
      {links.map(({ title, url, active }) => (
        <BreadcrumbItem
          className={`breadcrumbs__item${
            active ? ' breadcrumbs__item--active' : ''
          }`}
          key={url}
          isCurrentPage={active}
        >
          <BreadcrumbLink className="breadcrumbs__item__link" href={url}>
            {title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
