import { Button } from '@chakra-ui/react';
import { useEventDispatch } from '@prodelio/event-system/hooks/useEventDispatch';
import { TaskEvents } from '@prodelio/modules/tasks/Task.events';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import { DeleteTaskRequest } from '@prodelio/modules/tasks/delete-task/request/DeleteTask.request';

interface DeleteTaskProps {
  id: string;
}

export const DeleteTask = ({ id }: DeleteTaskProps) => {
  const deleteTask = useTaskStore((state) => state.delete);
  const dispatch = useEventDispatch();

  const request = new DeleteTaskRequest(id);

  const handleDeleteTask = () => {
    deleteTask(request, {});
    dispatch(TaskEvents.DELETED_TASK);
  };

  return (
    <Button
      loadingText="Deleting..."
      variant="error"
      onClick={handleDeleteTask}
    >
      Delete
    </Button>
  );
};
