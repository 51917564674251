import { useLocation } from 'react-router-dom';
import { AppRoutes } from '@prodelio/config/state/app-config/AppConfig';

export const useGetCurrentView = () => {
  const location = useLocation();

  return Object.entries(AppRoutes)
    .filter(([, value]) => location.pathname.startsWith(value.pathname))
    .map(([key]) => key)[0];
};

export const getCurrentView = () => {
  const location = window.location;

  return Object.entries(AppRoutes)
    .filter(([, value]) => location.pathname.startsWith(value.pathname))
    .map(([key]) => key)[0];
};
