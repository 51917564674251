import { apiWrapper } from '@prodelio/config/api/api';
import { toastError } from '@prodelio/config/toast/toast';
import { Request } from '@prodelio/hooks/api/Request.class';
import { Board } from '@prodelio/modules/projects/state/Board';
import { BoardStore } from '@prodelio/modules/projects/state/BoardStore';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';
import { StoreApi } from 'zustand';

export const deleteBoard = (
  set: StoreApi<BoardStore>['setState'],
  request: Request,
  data: Board
): void => {
  apiWrapper(request).catch((e: Error) => {
    toastError('Board could not be deleted', e.message);
    set((state: BoardStore) => ({
      boards: [
        ...state.boards,
        {
          ...data,
          syncStatus: SyncStatus.DELETED,
        },
      ],
    }));
  });

  set((state: BoardStore) => ({
    ...state,
    boards: state.boards.filter((board) => {
      if (board._id.equals(data._id)) {
        return false;
      }

      return true;
    }),
  }));
};
