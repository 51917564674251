export class ApiErrorClass extends Error {
  message: string;
  code: number | null;
  public data: Record<any, any> | null;

  constructor(
    code: number | null,
    message: string,
    data: Record<any, any> | null = null
  ) {
    super();
    this.message = message;
    this.code = code;
    this.data = data;
  }
}
