import { useEffect } from 'react';
import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { ForgoPasswordComponent } from '@prodelio/modules/auth/features/forgot-password/ForgoPassword.component';

export const ForgotPasswordPage = () => {
  const { setTitle } = useRouteProps();

  useEffect(() => {
    setTitle('Forgot Password');
  }, []);

  return (
    <>
      <ForgoPasswordComponent />
    </>
  );
};
