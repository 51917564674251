import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useReducer,
  useState,
} from 'react';

interface Props {
  children: JSX.Element;
}

interface RoutePropsState {
  title: string;
  date: Date | null;
  displayTaskViewSelector: boolean;
  tags: string;
  modalTaskId: string | null;
  email: string;
}

enum RoutePropsPayloadType {
  'SET_STATE' = 'SET_STATE',
}

interface RoutePropsPayload {
  type: RoutePropsPayloadType;
  payload: Partial<RoutePropsState>;
}

export interface Context {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  state: RoutePropsState;
  setState: (state: Partial<RoutePropsState>) => void;
}

const actions = {
  [RoutePropsPayloadType.SET_STATE]: (
    prevState: RoutePropsState,
    newState: Partial<RoutePropsState>
  ) => ({
    ...prevState,
    ...newState,
  }),
};

const Reducer = (prevState: RoutePropsState, action: RoutePropsPayload) => {
  return actions[action.type](prevState, action.payload);
};

const defaultState: RoutePropsState = {
  title: '',
  date: null,
  displayTaskViewSelector: false,
  tags: '',
  modalTaskId: null,
  email: '',
};

const defaultContext = {
  title: '',
  setTitle: () => {},
  state: defaultState,
  setState: () => {},
};

export const RoutePropsContext = createContext<Context>(defaultContext);

export const RoutePropsProvider = ({ children }: Props) => {
  const [title, setTitle] = useState('');
  const [state, dispatch] = useReducer(Reducer, defaultState);

  const setState = useCallback((newState: Partial<RoutePropsState>) => {
    dispatch({
      type: RoutePropsPayloadType.SET_STATE,
      payload: newState,
    });
  }, []);

  return (
    <RoutePropsContext.Provider value={{ title, setTitle, state, setState }}>
      {children}
    </RoutePropsContext.Provider>
  );
};
