import { apiWrapper } from '@prodelio/config/api/api';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { Request } from '@prodelio/hooks/api/Request.class';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import { SyncStatus, Task } from '@prodelio/modules/tasks/state/types/Task';

class BulkCreateTasksRequest extends Request {
  public method: ApiMethods = ApiMethods.POST;
  public url = '/tasks/bulk';
}

class BulkDeleteTasksRequest extends Request {
  public method: ApiMethods = ApiMethods.DELETE;
  public url = '/tasks/bulk';
}

class BulkEditTasksRequest extends Request {
  public method: ApiMethods = ApiMethods.PUT;
  public url = '/tasks/bulk';
}

export class TaskSyncronizer {
  protected retryTimer = 5000;

  async syncTasks(tasks: Task[]) {
    try {
      await apiWrapper(
        new BulkDeleteTasksRequest(
          tasks
            .filter((task) => task.syncStatus === SyncStatus.DELETED)
            .map((task) => ({ id: task.id }))
        )
      );

      await apiWrapper(
        new BulkEditTasksRequest(
          tasks.filter((task) => task.syncStatus === SyncStatus.EDITED)
        )
      );

      await apiWrapper(
        new BulkCreateTasksRequest(
          tasks.filter((task) => task.syncStatus === SyncStatus.CREATED)
        )
      );

      const taskMap = tasks.reduce((taskMap, task) => {
        taskMap.set(task.id, task);

        return taskMap;
      }, new Map());

      useTaskStore.setState((state) => ({
        tasks: state.tasks
          .filter((task) => task.syncStatus !== SyncStatus.DELETED)
          .map((task) => {
            const updatedTask = taskMap.get(task.id);
            if (!updatedTask) {
              return task;
            }

            return {
              ...updatedTask,
              syncStatus: false,
            };
          }),
      }));
    } catch (error) {
      console.info('Tasks could not be syncronized with the server', error);
      setTimeout(() => {
        this.syncTasks(tasks);
        this.retryTimer *= 2;
      }, Math.min(this.retryTimer, 5 * 60 * 1000));
    }
  }
}
