import { routes } from '@prodelio/router/routes';
import { ILink } from '@prodelio/router/breadcrumbs/interfaces/ILink';
import { IStrategy } from '@prodelio/router/breadcrumbs/interfaces/IStrategy';
import { PageStrategy } from '@prodelio/router/breadcrumbs/strategies/PageStrategy';
import { TaskStrategy } from '@prodelio/router/breadcrumbs/strategies/TaskStrategy';

export class BreadcrumbBuilder {
  private strategy: IStrategy = new PageStrategy(routes);

  constructor(pathname: string, id?: string) {
    if (pathname.startsWith('/app/tasks')) {
      this.strategy = new TaskStrategy(id!);
    }
  }

  public getLinks(): ILink[] {
    if (!this.strategy) {
      return [];
    }

    return this.strategy.getLinks();
  }
}
