import { Status, useApi } from '@prodelio/hooks/api/useApi';
import { AuthBody, LoginRequest } from '@prodelio/hooks/auth/Login.request';
import { LogoutRequest } from '@prodelio/hooks/auth/Logout.request';
import { useContext, useEffect } from 'react';
import { AuthContext } from '@prodelio/context/auth-provider/Auth.provider';
import { apiWrapper } from '@prodelio/config/api/api';
import { MeRequest } from '@prodelio/hooks/auth/Me.request';

export interface User {
  username: string;
  id: string;
  email: string;
  name: string;
  confirmed: boolean;
}

export const useAuth = (): {
  user: User | undefined | null;
  login: any;
  logout: any;
  checkAuth: any;
  setAuth: (user: User | null) => void;
} => {
  const { user: auth, setUser: setAuth } = useContext(AuthContext);
  const loginRequest = new LoginRequest();
  const { data, status, fetch: loginFetch } = useApi(loginRequest, false);

  const logoutRequest = new LogoutRequest();
  const { fetch: logoutFetch } = useApi(logoutRequest, false);

  const login = async (userData: AuthBody) => {
    if (userData) {
      loginRequest.body = userData;
      const user = await loginFetch();
      setAuth(user.data);
      localStorage.setItem('catd-user', JSON.stringify(user.data));
    }
  };

  const logout = async () => {
    await logoutFetch();
    setAuth(null);
    localStorage.removeItem('catd-user');
  };

  const checkAuth = async (): Promise<boolean> => {
    if (auth) {
      return true;
    }

    try {
      const meRequest = new MeRequest();
      const {
        data: { data: user },
      } = await apiWrapper(meRequest);
      if (!user) {
        return false;
      }

      setAuth(user as User);
    } catch (error: any) {
      const localUser = localStorage.getItem('catd-user');
      if (error.response?.status === 401) {
        localStorage.removeItem('catd-user');
        return false;
      }

      if (localUser) {
        setAuth(JSON.parse(localUser));
        return true;
      }

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (status === Status.DONE) {
      localStorage.setItem('catd-user', JSON.stringify(data));
      setAuth(data as User);
    }
  }, [status]);

  return {
    user: auth,
    login,
    logout,
    checkAuth,
    setAuth,
  };
};
