import { store } from '@prodelio/config/state/store';
import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { Project } from '@prodelio/config/state/projects/Project.interface';
import { apiWrapper } from '@prodelio/config/api/api';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

class BulkProjectRequest extends Request {
  public method: ApiMethods = ApiMethods.POST;
  public url = '/projects/bulk';

  constructor(body: any, method: ApiMethods) {
    super(body);
    this.method = method;
  }
}

export const useSyncProjects = () => {
  const locallyCreatedProjects = store((state) =>
    state.syncProjects.filter(
      ({ syncStatus }) => syncStatus === SyncStatus.CREATED
    )
  );
  const deletedProjects = store((state) =>
    state.syncProjects.filter(
      ({ syncStatus }) => syncStatus === SyncStatus.DELETED
    )
  );
  const editedProjects = store((state) =>
    state.syncProjects.filter(
      ({ syncStatus }) => syncStatus === SyncStatus.EDITED
    )
  );

  const editProject = store((state) => state.editLocalProject);
  const cleanSyncProjects = store((state) => state.cleanStore);

  return async () => {
    try {
      const deletedProjectIds = deletedProjects.map(({ id }) => id);
      await apiWrapper(
        new BulkProjectRequest(deletedProjectIds, ApiMethods.DELETE)
      );
      await apiWrapper(new BulkProjectRequest(editedProjects, ApiMethods.PUT));

      const createdProjects = await apiWrapper(
        new BulkProjectRequest(
          locallyCreatedProjects.map(
            ({ id, syncStatus, ...project }) => project
          ),
          ApiMethods.POST
        )
      ).then((res) => res.data.data);

      createdProjects.forEach(({ id }: Project, index: number) => {
        editProject(id, createdProjects[index]);
      });

      cleanSyncProjects('syncProjects');
    } catch (e: any) {}
  };
};
