import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect } from 'react';
import { add, startOfTomorrow } from 'date-fns';
import { ListCurrentWeekTasks } from '@prodelio/modules/tasks/features/list-current-week-tasks/ListCurrentWeekTasks';

export const CurrentWeekPage = () => {
  const { setTitle, setState } = useRouteProps();

  useEffect(() => {
    setTitle('Current Week');
    setState({
      date: add(startOfTomorrow(), { days: 1 }),
      tags: 'next_actions',
    });
  }, []);

  return (
    <>
      <ListCurrentWeekTasks />
    </>
  );
};
