import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';

export class ListTasksByTagsRequest extends Request {
  public method: ApiMethods = ApiMethods.GET;
  public url = '/tasks';

  constructor(tags: string[]) {
    super();
    this.url = this.url + `?tags=${tags.join(';')}`;
  }
}
