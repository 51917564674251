import { Box, Stack } from '@chakra-ui/react';
import { Kanban } from '@prodelio/components/kanban/Kanban';
import {
  AppViews,
  TaskViewEnum,
} from '@prodelio/config/state/app-config/AppConfig';
import { useViewConfig } from '@prodelio/config/state/app-config/selectors/useViewConfig';
import { useGetCurrentView } from '@prodelio/hooks/route-props/useGetCurrentView';
import { arrayMove } from '@dnd-kit/sortable';
import {
  Active,
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  Over,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { EditTaskRequest } from '@prodelio/modules/tasks/features/edit-task/request/EditTask.request';
import { SectionTaskList } from '@prodelio/components/section-task-list/SectionTaskList';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { TaskComponent } from '@prodelio/components/task/Task.component';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import { getTask } from '@prodelio/modules/tasks/state/selectors/getTask';
import {
  SyncStatus,
  Task,
  TaskSort,
} from '@prodelio/modules/tasks/state/types/Task';
import {
  TaskFilters,
  TaskSortOptions,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';
import { TaskSyncronizer } from '@prodelio/modules/tasks/services/task-synchronizer/TaskSynchronizer';

export interface TaskViewSection {
  key: string;
  header: string;
  tasks: Task[];
  filters: Partial<TaskFilters>;
  sort: TaskSortOptions[];
  displayHeader?: boolean;
  droppable?: boolean;
  quickCreateTasks?: boolean;
  actions?: ReactElement[];
}

interface TaskViewProps {
  viewId?: string;
  children?: JSX.Element | JSX.Element[];
  canChange?: boolean;
  taskFilters?: Partial<TaskFilters>;
  sections?: TaskViewSection[];
}

export const TaskView = ({
  canChange = false,
  sections,
  viewId = '',
}: TaskViewProps) => {
  const activeTaskRef = useRef<Task | undefined>(undefined);

  const editTask = useTaskStore((store) => store.edit);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 10,
      },
    })
  );

  const currentView = useGetCurrentView();
  const { taskView } = useViewConfig(currentView ? currentView : '');

  const [localSections, setLocalSections] = useState<TaskViewSection[]>(
    sections || []
  );

  useEffect(() => {
    setLocalSections(sections ?? []);
  }, [sections]);

  const renderSections =
    localSections.map(
      ({
        header,
        tasks,
        key,
        filters,
        sort,
        displayHeader = true,
        quickCreateTasks = true,
        actions,
      }) => {
        return (
          <SectionTaskList
            header={header}
            key={key}
            sectionId={key}
            tasks={tasks}
            TaskListProps={{ filters, sort, quickCreateTasks }}
            displayHeader={displayHeader}
            actions={actions}
          />
        );
      }
    ) ?? [];

  const ListView = (
    <Stack direction="column" gap={50} pb={70}>
      {renderSections}
    </Stack>
  );

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    activeTaskRef.current = getTask(active.id as string);
  }, []);

  const isSectionUndroppable = (over: Over) => {
    const overSection = localSections?.find(({ tasks }) =>
      tasks.find(({ id }) => id === over.id)
    );

    return overSection && overSection.droppable === false;
  };

  const getDroppableDate = (
    doppableContainer: Over | Active
  ): Date | undefined => {
    if (Number.isNaN(Number(doppableContainer.id))) {
      return doppableContainer.data.current?.date;
    }

    return new Date(Number(doppableContainer.id));
  };

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      activeTaskRef.current = undefined;
      if (!over?.id) return;

      if (isSectionUndroppable(over)) {
        return;
      }

      const overSection = localSections.find((section) => {
        if (section.key === over.id) {
          return true;
        }

        const task = section.tasks.find((task) => over.id === task.id);
        if (task) {
          return true;
        }

        return false;
      });
      const overDate = overSection?.filters.date ?? getDroppableDate(over);

      const tasks = localSections?.flatMap(({ tasks }) => tasks) ?? [];
      const oldIndex = tasks.findIndex(({ id }) => active.id === id);
      const newIndex = tasks.findIndex(({ id }) => over.id === id);
      const modifiedContainer = arrayMove(tasks, oldIndex, newIndex);

      const tasksToSync = modifiedContainer.map((task, index) => {
        const sort = {
          ...((task.sort || {}) as TaskSort),
          [(currentView as AppViews) + viewId]: index,
        };

        if (task.id === active.id) {
          return {
            ...task,
            syncStatus: SyncStatus.EDITED,
            dueDate: canChange
              ? overDate?.toISOString() ?? task.dueDate
              : task.dueDate,
            boardId:
              canChange && over.data.current
                ? over.data.current.boardId
                : task.boardId,
            updatedAt: new Date().toISOString(),
            sort,
          };
        }

        return {
          ...task,
          syncStatus: SyncStatus.EDITED,
          updatedAt: new Date().toISOString(),
          sort: sort,
        };
      });

      const requests = tasksToSync.map((task) => {
        const request = new EditTaskRequest(
          task.id,
          { ...task, tags: task.tags.join(';') },
          false
        );
        return request;
      });
      editTask(requests, {});

      const taskSyncronizer = new TaskSyncronizer();
      taskSyncronizer.syncTasks(tasksToSync as Task[]);
    },
    [localSections]
  );

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      const activeTask = activeTaskRef.current;
      if (!active || !over || !canChange || !activeTask) {
        return;
      }

      if (isSectionUndroppable(over)) {
        return;
      }

      const activeId = active.id;
      const overId = over.id;

      setLocalSections((prevSections) => {
        let activeSectionIndex = -1;
        let activeTaskIndex = -1;
        let overSectionIndex = -1;
        let overTaskIndex = -1;

        prevSections.forEach((section, sectionIndex) => {
          const activeIndex = section.tasks.findIndex(
            (task) => task.id === activeId
          );
          if (activeIndex !== -1) {
            activeSectionIndex = sectionIndex;
            activeTaskIndex = activeIndex;
          }

          const overIndex = section.tasks.findIndex(
            (task) => task.id === overId
          );
          if (overIndex !== -1) {
            overSectionIndex = sectionIndex;
            overTaskIndex = overIndex;
          }
        });

        // If over task not found, check if overId matches a section key (empty section)
        if (overSectionIndex === -1) {
          overSectionIndex = prevSections.findIndex(
            (section) => section.key === overId
          );
          overTaskIndex = overSectionIndex - activeSectionIndex; // Insert at the beginning if section is empty
        }

        const sectionDelta = overSectionIndex - activeSectionIndex;
        if (sectionDelta === 0) {
          overTaskIndex = activeTaskIndex;
        } else if (sectionDelta < 0) {
          overTaskIndex = prevSections[overSectionIndex].tasks.length - 1;
        } else {
          overTaskIndex = 0;
        }

        if (activeSectionIndex !== -1 && overSectionIndex !== -1) {
          const activeSection = prevSections[activeSectionIndex];
          const overSection = prevSections[overSectionIndex];

          if (activeSectionIndex === overSectionIndex) {
            const newSectionTasks = activeSection.tasks.filter(
              (task) => task.id !== activeId
            );
            newSectionTasks.splice(overTaskIndex, 0, activeTask);

            // Create new sections array with updated tasks
            const newSections = prevSections.map((section, index) => {
              if (index === overSectionIndex) {
                return { ...section, tasks: newSectionTasks };
              } else {
                return section;
              }
            });

            return newSections;
          }

          // Remove the task from the active section
          const newActiveTasks = activeSection.tasks.filter(
            (task) => task.id !== activeId
          );

          // Insert the task into the over section
          const newOverTasks = [...overSection.tasks];
          newOverTasks.splice(overTaskIndex, 0, activeTask);

          // Create new sections array with updated tasks
          const newSections = prevSections.map((section, index) => {
            if (index === activeSectionIndex) {
              return { ...section, tasks: newActiveTasks };
            } else if (index === overSectionIndex) {
              return { ...section, tasks: newOverTasks };
            } else {
              return section;
            }
          });

          return newSections;
        }

        return prevSections;
      });
    },
    [canChange, isSectionUndroppable]
  );

  const KanbanView = <Kanban>{renderSections}</Kanban>;

  return (
    <Box pb={70}>
      <DndContext
        collisionDetection={closestCenter}
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
      >
        {taskView === TaskViewEnum.LIST || !canChange ? ListView : KanbanView}
        <DragOverlay>
          {activeTaskRef.current && (
            <Box opacity={0.7}>
              <TaskComponent
                id={activeTaskRef.current.id}
                name={activeTaskRef.current.title}
                boardId={activeTaskRef.current.boardId}
                priority={activeTaskRef.current.priority}
                dueDate={
                  activeTaskRef.current.dueDate
                    ? new Date(activeTaskRef.current.dueDate)
                    : null
                }
                tags={activeTaskRef.current.tags}
                timeEstimated={activeTaskRef.current.timeEstimation}
                timeTracked={activeTaskRef.current.timeTracked}
                status={activeTaskRef.current.status}
              />
            </Box>
          )}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};
