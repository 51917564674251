import { Request } from '@prodelio/hooks/api/Request.class';
import { StatusEnum } from '@prodelio/modules/tasks/types/Status.enum';
import { apiWrapper } from '@prodelio/config/api/api';
import { toastError } from '@prodelio/config/toast/toast';
import { SetStore, TaskState } from '@prodelio/modules/tasks/state/TaskStore';
import { v4 } from 'uuid';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const createTask = (set: SetStore<TaskState>, request: Request) => {
  const id = v4();

  const currentTimestamp = new Date();

  const taskExtraFields = {
    id,
    status: StatusEnum.TODO,
    createdAt: currentTimestamp,
    updatedAt: currentTimestamp,
  };

  request.body = {
    ...request.body,
    ...taskExtraFields,
  };

  apiWrapper(request).catch((e: Error) => {
    toastError('Task could not be stored.', e.message);
    set((state) => ({
      tasks: state.tasks.map((task) => {
        if (task.id === id) {
          return {
            ...task,
            syncStatus: SyncStatus.CREATED,
          };
        }

        return task;
      }),
    }));
  });

  const createdTask = {
    ...request.body,
    ...taskExtraFields,
    tags: request.body.tags?.split(';') ?? [],
  };

  set((state) => ({
    ...state,
    tasks: [...state.tasks, createdTask],
  }));
};
