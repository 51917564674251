import { useAuth } from '@prodelio/hooks/auth/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: JSX.Element;
}

export const AuthRoutes = ({ children }: Props) => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();

  useEffect(() => {
    checkAuth().then((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        navigate('/app/inbox');
      }
    });
  }, []);

  return <>{children}</>;
};
