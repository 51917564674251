import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect } from 'react';
import { RestorePasswordComponent } from '@prodelio/modules/auth/features/restore-password/RestorePassword.component';

export const RestorePasswordPage = () => {
  const { setTitle } = useRouteProps();

  useEffect(() => {
    setTitle('Restore Password');
  }, []);

  return (
    <>
      <RestorePasswordComponent />
    </>
  );
};
