import {
  Box,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  KeyboardEventHandler,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  SelectOption,
  SelectOptionKey,
} from '@prodelio/components/form/select/SelectOption';
import { SelectMultiInput } from '@prodelio/components/form/select/SelectMultiInput';
import { KeyCombination } from '@prodelio/modules/common/models/KeyCombination';

interface SelectProps {
  options: SelectOption[];
  selected?: SelectOptionKey | SelectOptionKey[];
  name?: string;
  onChange?: (e: {
    target: {
      name: string | null;
      value: SelectOptionKey | SelectOptionKey[] | null;
    };
  }) => void;
  multi?: boolean;
  writable?: boolean;
  writableLabel?: string;
}

const getSelectedOption = (
  options: SelectOption[],
  value: SelectOptionKey | SelectOptionKey[] | null
): SelectOption | SelectOption[] => {
  if (Array.isArray(value)) {
    const selected = value.map((key) =>
      options.find((option) => option.value === key)
    );

    return selected as SelectOption[];
  }

  const selected = options.find((option) => value === option.value)!;

  return selected;
};

export const Select = ({
  options: initialOptions,
  onChange,
  name,
  selected,
  multi,
  writable,
  writableLabel = 'Add ',
}: SelectProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [options, setOptions] = useState<SelectOption[]>(initialOptions);

  const [selectedOption, setSelectedOption] = useState<
    SelectOption | SelectOption[] | null
  >(selected ? getSelectedOption(options, selected) : null);
  const [search, setSearch] = useState<string>('');

  const selectElement = useRef<HTMLDivElement | null>(null);
  const selectInputElement = useRef<HTMLInputElement | null>(null);

  const getOptionDisplayLabel = (
    option: SelectOption | SelectOption[] | null
  ): string => {
    if (!option) {
      return '';
    }

    if (Array.isArray(option)) {
      return option.map(({ label, value }) => label ?? value).join(', ');
    }

    return (option.label ?? option.value) as string;
  };

  const onClickOption = (e: SyntheticEvent<HTMLDivElement>) => {
    if (!(e.target instanceof HTMLDivElement)) {
      return;
    }

    const value = e.target.dataset.value;

    const eventValue: {
      name: string | null;
      value: SelectOptionKey | SelectOptionKey[] | null;
    } = { name: name ?? null, value: value ?? null };

    if (multi && Array.isArray(selectedOption)) {
      const valueIndex = selectedOption.findIndex(
        (option) => option.value === value
      );
      if (valueIndex >= 0) {
        eventValue.value = selectedOption.map((option) => option.value);

        eventValue.value.splice(valueIndex, 1) as string[];
      } else {
        eventValue.value = [
          ...selectedOption.map(({ value }) => value),
          value,
        ].filter((val) => val) as string[];
      }
    }

    if (onChange) {
      onChange({
        target: {
          ...eventValue,
          value: Array.isArray(eventValue.value)
            ? eventValue.value.join(',')
            : eventValue.value,
        },
      });
    }

    setSelectedOption(getSelectedOption(options, eventValue.value));
    onClose();
  };

  const onSearch = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.target instanceof HTMLInputElement) {
      setSearch(e.target.value ?? '');
    }
  };

  const addOption = () => {
    const sanitizedValue = search.trim();
    if (!sanitizedValue) {
      return;
    }

    const option = {
      value: sanitizedValue.toLowerCase(),
      label: sanitizedValue,
    };

    const optionExists =
      options.findIndex(({ value }) => value === option.value) >= 0;

    if (!optionExists) {
      setOptions([...options, option]);
    }

    if (multi && Array.isArray(selectedOption)) {
      const newOptions = [...selectedOption, option];
      setSelectedOption(newOptions);
      onChange &&
        onChange({
          target: {
            name: name ?? null,
            value: newOptions.map(({ value }) => value).join(',') ?? null,
          },
        });
    } else {
      onChange &&
        onChange({
          target: {
            name: name ?? null,
            value: option.value ?? null,
          },
        });
      setSelectedOption(option);
    }

    setDeleteCounter(0);
    setSearch('');
  };

  const removeSelectedOption = (option: SelectOption) => {
    if (multi && Array.isArray(selectedOption)) {
      setSelectedOption(
        selectedOption.filter(({ value }) => option.value !== value)
      );
    } else {
      setSelectedOption(null);
    }
  };

  const [deleteCounter, setDeleteCounter] = useState<number>(0);

  const createOptionCombination = new KeyCombination('Enter');

  const deleteOptionCombination = new KeyCombination('Backspace');

  const stopDeleteOptionCombination = new KeyCombination('ArrowRight');

  const handleInputKeyPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === createOptionCombination.value.key) {
      addOption();

      return;
    }

    if (e.key === stopDeleteOptionCombination.value.key && search === '') {
      setDeleteCounter(0);
      return;
    }

    const isEventAbleToDelete =
      e.key === deleteOptionCombination.value.key || e.key.length === 1;

    if (
      e.key === deleteOptionCombination.value.key &&
      search === '' &&
      deleteCounter === 0
    ) {
      setDeleteCounter(deleteCounter + 1);

      return;
    }

    if (deleteCounter > 0 && isEventAbleToDelete) {
      setDeleteCounter(0);

      if (
        selectedOption &&
        Array.isArray(selectedOption) &&
        selectedOption.length
      ) {
        removeSelectedOption(selectedOption.slice(-1)[0]);
      } else if (selectedOption && !Array.isArray(selectedOption)) {
        removeSelectedOption(selectedOption);
      }

      return;
    }
  };

  useEffect(() => {
    const clickOutside = (e: Event) => {
      if (!(e.target instanceof HTMLElement)) {
        return;
      }

      if (selectElement.current && !selectElement.current.contains(e.target)) {
        onClose();
      }
    };

    window.addEventListener('mousedown', clickOutside);

    return () => {
      window.removeEventListener('mousedown', clickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setSearch('');
    }
  }, [isOpen]);

  const selectStyles = useMemo(
    () => ({
      '.select__search__input': {
        cursor: 'pointer',

        '&:focus': {
          cursor: 'initial',
        },
      },
    }),
    []
  );

  const selectOptionWrapperStyles = useMemo(
    () => ({
      minWidth: '220px',
      width: '100%',
      maxHeight: '300px',
      borderRadius: 0,
      backgroundColor: 'primary.main',
      borderColor: 'transparent',
      boxShadow: '0px 2px 15px rgba(0,0,0,0.4)',
      overflow: 'auto',
      '& .chakra-popover__body': {
        width: selectElement.current?.scrollWidth ?? 'auto',
        padding: 0,
      },
    }),
    [selectElement.current]
  );

  const selectOptionSytles = useMemo(
    () => ({
      width: '100%',
      paddingY: 2,
      paddingX: 8,
      textAlign: 'center',
      transition: 'all 0.2s ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'primary.secondary',
        '&.select__option--no-hover': {
          backgroundColor: 'transparent',
        },
      },
    }),
    []
  );

  const filteredOptions = options.filter((option) => {
    const displayLabel = getOptionDisplayLabel(option).toLowerCase();

    return displayLabel.includes(search.toLowerCase());
  });

  const Options = filteredOptions.map((option) => {
    const displayLabel = getOptionDisplayLabel(option);

    return (
      <Box
        className="select__option"
        onClick={onClickOption}
        data-value={option.value}
        sx={selectOptionSytles}
      >
        {displayLabel}
      </Box>
    );
  });

  if (writable && search.length) {
    Options.unshift(
      <Box
        className="select__option"
        onClick={addOption}
        sx={selectOptionSytles}
      >
        {writableLabel}
        {search}...
      </Box>
    );
  }

  const NoResultsOption = (
    <Box
      className="select__option select__option--no-hover"
      sx={selectOptionSytles}
    >
      No results...
    </Box>
  );

  return (
    <Box className="select" ref={selectElement}>
      <Popover isLazy isOpen={isOpen} initialFocusRef={selectInputElement}>
        <PopoverTrigger>
          <Box className="select__search" onClick={onOpen} sx={selectStyles}>
            {multi && writable ? (
              <SelectMultiInput
                removeSelectedOption={removeSelectedOption}
                addOption={addOption}
                onChange={onSearch}
                onWrite={handleInputKeyPress}
                deleteCounter={deleteCounter}
                value={search}
                selectedOptions={selectedOption as SelectOption[]}
                inputRef={selectInputElement}
              />
            ) : (
              <Input
                ref={selectInputElement}
                className="select__search__input"
                value={isOpen ? search : getOptionDisplayLabel(selectedOption)}
                onChange={onSearch}
              />
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent sx={selectOptionWrapperStyles}>
          <PopoverBody>
            <VStack spacing={0} className="select__options">
              {Options.length || multi ? Options : NoResultsOption}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
