import { inputAnatomy as parts } from '@chakra-ui/anatomy';

const baseStyles = {
  borderRadius: 0,
  borderWidth: '2px',
  borderStyle: 'solid',
  _disabled: {
    opacity: 1,
    px: 0,
    borderColor: 'transparent !important',
    bg: 'transparent !important',
    cursor: 'text',
  },
};

export const Input = {
  parts: parts.keys,
  baseStyles: {
    field: baseStyles,
  },
  variants: {
    filled: {
      field: {
        bg: 'primary.darker',
        borderColor: 'transparent',
        _hover: {
          bg: 'primary.darker',
          borderColor: 'accent.default',
        },
        _focusVisible: {
          bg: 'primary.darker',
          borderColor: 'accent.default',
        },
      },
    },
    filledLighter: {
      field: {
        bg: 'primary.lighter',
        borderColor: 'transparent',
        _hover: {
          bg: 'primary.lighter',
          borderColor: 'accent.default',
        },
        _focusVisible: {
          bg: 'primary.lighter',
          borderColor: 'accent.default',
        },
      },
    },
    unstyled: {
      field: {
        width: '100%',
        border: 'none',
        outline: 'none',
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
  },
  sizes: {
    lg: {
      field: baseStyles,
    },
  },
  defaultProps: {
    variant: 'filled',
    size: 'lg',
  },
};
