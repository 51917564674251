export class EventSoundCatalog extends Map<string, string> {
  protected readonly soundEventMapping = {
    'task/completed': 'task-completed.mp3',
  };

  constructor() {
    super();

    Object.entries(this.soundEventMapping).forEach(([event, fileName]) => {
      this.set(event, `${process.env.PUBLIC_URL}/sounds/${fileName}`);
    });
  }
}
