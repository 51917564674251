import { CollisionDetection } from '@dnd-kit/core';
import { closestBottom } from '@prodelio/components/sortable-list/collisions/closestBottom';
import { getProject } from '@prodelio/config/state/projects/selectors/getProject';

export const collisionDetection = (
  canBeSubproject: boolean
): CollisionDetection => {
  if (canBeSubproject) {
    return closestBottom;
  }

  return collisionAlgorithm;
};

const collisionAlgorithm: CollisionDetection = ({
  droppableRects,
  collisionRect,
  droppableContainers,
  ...args
}) => {
  const filteredRects = droppableContainers.filter(({ id }) => {
    const project = getProject(id as string);
    if (!project && id !== 'start') {
      return false;
    }

    return project ? !project.parentProject : true;
  });

  return closestBottom({
    droppableContainers: filteredRects,
    collisionRect,
    droppableRects,
    ...args,
  });
};
