import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/400.css';

import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@prodelio/config/theme';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from '@prodelio/router/AppRoutes';
import { RoutePropsProvider } from '@prodelio/context/route-props-provider/RouteProps.provider';
import { EventEmitter } from '@prodelio/event-system/EventEmitter';
import { TimeTrackerProvider } from '@prodelio/context/time-tracker/TimeTracker.provider';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { DndContext } from '@dnd-kit/core';
import { AuthProvider } from '@prodelio/context/auth-provider/Auth.provider';
import { KeyShortcuts } from '@prodelio/modules/common/contexts/KeyShortcuts.context';

const emotionCache = createCache({
  key: 'chakra',
  prepend: true,
});

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <CacheProvider value={emotionCache}>
          <ChakraProvider theme={theme}>
            <EventEmitter>
              <DndContext>
                <RoutePropsProvider>
                  <KeyShortcuts>
                    <TimeTrackerProvider>
                      <AppRoutes />
                    </TimeTrackerProvider>
                  </KeyShortcuts>
                </RoutePropsProvider>
              </DndContext>
            </EventEmitter>
          </ChakraProvider>
        </CacheProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
