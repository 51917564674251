import { add, endOfDay, startOfToday } from 'date-fns';
import { TaskView } from '@prodelio/components/task-view/TaskView';
import { AppViews } from '@prodelio/config/state/app-config/AppConfig';
import { useKanbanDateSections } from '@prodelio/modules/tasks/hooks/useKanbanDateSections';
import { GetNextSevenDaysTasksRequest } from '@prodelio/modules/tasks/features/list-next-seven-days-tasks/GetNextSevenDaysTasks.request';
import { TaskSortOptions } from '@prodelio/modules/tasks/state/selectors/selectTasks';

export const ListNextSevenDaysTasks = () => {
  const startDate = startOfToday();
  const endDate = endOfDay(
    add(startDate, {
      days: 7,
    })
  );

  const taskFilters = {
    startDate,
    endDate,
  };

  const sort = [TaskSortOptions.MANUAL];

  const request = new GetNextSevenDaysTasksRequest();

  const sections = useKanbanDateSections(
    taskFilters,
    sort,
    request,
    AppViews.NEXT_7_DAYS
  );

  return <TaskView canChange taskFilters={taskFilters} sections={sections} />;
};
