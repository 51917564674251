export const Button = {
  baseStyle: {
    px: '18px',
    py: '10px',
    borderRadius: '2px',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
  },
  variants: {
    primary: {
      bg: 'accent.default',
      color: 'typography.heading.dark',
      px: '20px',
      _hover: {
        bg: 'accent.lighter',
        _disabled: {
          bg: 'accent.default',
        },
      },
    },
    'ghost-primary': {
      bg: 'transparent',
      color: 'typography.heading.light',
      px: '20px',
      _hover: {
        color: 'accent.default',
      },
    },
    menu: {
      px: '14px',
      bg: 'transparent',
      _hover: {
        bg: 'transparent',
      },
    },
    error: {
      bg: 'error.default',
      color: 'typography.heading.dark',
      px: '20px',
      _hover: {
        bg: 'error.darker',
        _disabled: {
          bg: 'error.default',
        },
      },
    },
    secondary: {
      bg: '#52585B',
      color: 'typography.text.light',
      px: '20px',
      _hover: {
        bg: '#62686C',
      },
    },
    link: {
      padding: '5px',
      height: '29px',
      color: 'typography.text.disabled',
    },
    navlink: {
      justifyContent: 'left',
      width: 'auto',
      minWidth: 'initial',
      height: 'auto',
      padding: 0,
      color: 'typography.text.light',
      _hover: {
        color: 'accent.default',
      },
      '&.active': {
        color: 'accent.default',
      },
    },
    'icon-primary': {
      bg: 'accent.default',
      borderRadius: '50%',
      color: 'typography.heading.dark',
      _hover: {
        bg: 'accent.lighter',
      },
    },
    icon: {
      bg: 'transparent',
      borderRadius: '50%',
      color: 'typography.text.light',
    },
    'icon-link-primary': {
      bg: 'transparent',
      borderRadius: '50%',
      color: 'accent.default',
      _hover: {
        color: 'accent.lighter',
      },
    },
  },
  size: {
    sm: {
      height: '48px',
    },
  },
  defaultProps: {
    size: 'lg',
  },
};
