import { CreateSubprojectRequest } from '@prodelio/modules/projects/features/create-project/requests/CreateSubproject.request';
import { apiWrapper } from '@prodelio/config/api/api';
import { store } from '@prodelio/config/state/store';
import { Project } from '@prodelio/config/state/projects/Project.interface';
import { getProject } from '@prodelio/config/state/projects/selectors/getProject';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const createSubproject = (projectId: string, subprojectId: string) => {
  const state = store.getState();
  const project = getProject(projectId);
  const subproject = getProject(subprojectId);
  if (!project || !subproject) {
    return;
  }

  const parentProject = project.parentProject
    ? project.parentProject
    : projectId;

  const createSubprojectRequest = new CreateSubprojectRequest({
    subprojectId: subprojectId,
    parentProjectId: parentProject,
  });

  const newState = {
    ...state,
    projects: state.projects.map((project) => {
      if (project.id !== subprojectId) {
        return project;
      }

      return {
        ...project,
        parentProject,
      };
    }) as Project[],
  };

  apiWrapper(createSubprojectRequest).catch(() => {
    const syncProjects = state.syncProjects;
    const updatedAt = new Date().toISOString();

    console.warn('Error: could not create subproject');

    store.setState({
      ...newState,
      syncProjects: [
        ...syncProjects,
        {
          ...subproject,
          parentProject,
          updatedAt,
          syncStatus: SyncStatus.EDITED,
        },
      ],
    });
  });

  store.setState(newState);
};
