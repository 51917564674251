import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { RegisterComponent } from '@prodelio/modules/auth/features/register/Register.component';
import { useEffect } from 'react';

export const RegisterPage = () => {
  const { setTitle } = useRouteProps();

  useEffect(() => {
    setTitle('Register');
  }, []);

  return <RegisterComponent />;
};
