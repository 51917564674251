import { useContext, useEffect } from 'react';
import { EventEmitterContext } from '@prodelio/event-system/EventEmitterContext';

export const useEvent = (events: string | string[], callback: any) => {
  const { subscribe, unsubscribe } = useContext(EventEmitterContext);

  const subscribeToAllEvents = () => {
    if (Array.isArray(events)) {
      events.forEach((event) => {
        subscribe(event, callback);
      });
    } else {
      subscribe(events, callback);
    }
  };

  const unsubscribeToAllEvents = () => {
    if (Array.isArray(events)) {
      events.forEach((event) => {
        unsubscribe(event, callback);
      });
    } else {
      unsubscribe(events, callback);
    }
  };

  useEffect(() => {
    subscribeToAllEvents();

    return unsubscribeToAllEvents;
  }, [subscribe, unsubscribe, events, callback]);
};
