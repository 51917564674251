import { apiWrapper } from '@prodelio/config/api/api';
import { toastError } from '@prodelio/config/toast/toast';
import { Request } from '@prodelio/hooks/api/Request.class';
import { SetStore, TaskState } from '@prodelio/modules/tasks/state/TaskStore';
import { AxiosError } from 'axios';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const editTask = (
  set: SetStore<TaskState>,
  request: Request | Request[]
) => {
  if (Array.isArray(request)) {
    set((state) => {
      const dataTaskMap = new Map();
      request.forEach((request: Request) => {
        const taskId = request.getId();
        dataTaskMap.set(taskId, request.body);
      });

      return {
        tasks: state.tasks.map((task) => {
          const requestBody = dataTaskMap.get(task.id);
          if (!requestBody) {
            return task;
          }

          return {
            ...task,
            ...requestBody,
            tags: requestBody.tags ? requestBody.tags.split(';') : task.tags,
            updatedAt: new Date().toISOString(),
          };
        }),
      };
    });

    return;
  }

  const taskId = request.getId();

  apiWrapper(request).catch((e: Error) => {
    if (e instanceof AxiosError && e.response?.status === 404) {
      toastError('Task does not exist.', '');
      set((state) => ({
        tasks: state.tasks.filter(({ id }) => id !== taskId),
      }));

      return;
    }

    toastError('Edit task error.', '');
    set((state) => ({
      tasks: state.tasks.map((task) => {
        if (task.id === taskId) {
          return {
            ...task,
            syncStatus: SyncStatus.EDITED,
          };
        }

        return task;
      }),
    }));
  });

  set((state) => ({
    tasks: state.tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          ...request.body,
          tags: request.body.tags ? request.body.tags.split(';') : task.tags,
          updatedAt: new Date().toISOString(),
        };
      }

      return task;
    }),
  }));
};
