import { ClientRect } from '@dnd-kit/core';
import { Point } from '@prodelio/components/sortable-list/Point';

export const getCentralPoint = (
  rect: ClientRect,
  y: number = rect.bottom
): Point => {
  const x = rect.left + rect.width / 2;

  return new Point(x, y);
};
