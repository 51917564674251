import { toastError, toastSuccess } from '@prodelio/config/toast/toast';
import { Status, useApi } from '@prodelio/hooks/api/useApi';
import { Navigate, useParams } from 'react-router-dom';
import { VerifyEmailRequest } from '@prodelio/modules/auth/features/verification/requests/VerifyEmail.request';

export const VerificationComponent = () => {
  const { token } = useParams();

  const request = new VerifyEmailRequest(token ?? '');
  const { data, status } = useApi<{ success: boolean }>(request);

  if (status === Status.LOADING || status === Status.WAITING) {
    return null;
  }

  if (!data || !data.success) {
    toastError('Invalid email verification link', '');

    return <Navigate to="/login" />;
  }

  toastSuccess('Email verified successfully', '');
  return <Navigate to="/login" />;
};
