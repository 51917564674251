import { useState } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  VStack,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useForm } from '@prodelio/hooks/form/useForm';
import { useValidation } from '@prodelio/hooks/form/useValidation';
import { CreateProjectRequest } from '@prodelio/modules/projects/features/create-project/requests/CreateProject.request';
import { ModalComponent } from '@prodelio/components/modal/Modal.component';
import { store } from '@prodelio/config/state/store';

export const CreateProject = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { formValues, onChange, resetForm } = useForm({
    title: {
      value: '',
    },
  });
  const { formErrors, validate, resetFormErrors } = useValidation({
    title: {
      validators: ['empty', 'length'],
    },
  });

  const createProject = store((state) => state.createProject);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    resetForm();
    resetFormErrors();
    setOpenModal(false);
  };

  const handleSubmit = async () => {
    if (!validate(formValues)) {
      return;
    }

    setIsLoading(true);

    const request = new CreateProjectRequest();
    request.body = {
      title: formValues.title.value,
    };
    createProject(request);

    setIsLoading(false);
    handleCloseModal();
  };

  const modalActions = () => {
    return (
      <HStack spacing="20px" mt="35px">
        <Button
          isLoading={isLoading}
          onClick={handleSubmit}
          variant="primary"
          loadingText="Creating..."
        >
          Create
        </Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
      </HStack>
    );
  };

  return (
    <>
      <IconButton
        onClick={handleOpenModal}
        size="sm"
        variant="icon-link-primary"
        aria-label="Create Project"
        icon={<AddIcon />}
      />
      <ModalComponent
        open={openModal}
        onClose={handleCloseModal}
        title="Create Project"
      >
        <VStack spacing={6} alignItems="left">
          <FormControl isInvalid={!!formErrors.title}>
            <FormLabel>Task name</FormLabel>
            <Input
              variant="filled"
              type="text"
              name="title"
              value={formValues.title.value}
              onChange={onChange}
            />
            {formErrors.title && (
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            )}
          </FormControl>
        </VStack>
        {modalActions()}
      </ModalComponent>
    </>
  );
};
