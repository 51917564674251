import { create } from 'zustand';
import { createProjectSlice } from '@prodelio/config/state/projects/Project.slice';
import { StoreType } from '@prodelio/config/state/store.type';
import { persist } from 'zustand/middleware';
import { createCommonSlice } from '@prodelio/config/state/Common.slice';

export const store = create<StoreType, [['zustand/persist', StoreType]]>(
  persist(
    (...a) => ({
      ...createProjectSlice(...a),
      ...createCommonSlice(...a),
    }),
    {
      name: 'catd-project-storage',
      getStorage: () => localStorage,
    }
  )
);
