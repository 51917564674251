import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import { ModalComponent } from '@prodelio/components/modal/Modal.component';
import { useForm } from '@prodelio/hooks/form/useForm';
import { useValidation } from '@prodelio/hooks/form/useValidation';
import { useState } from 'react';
import { Board } from '@prodelio/modules/projects/state/Board';
import { StateField } from '@prodelio/hooks/form/reducer';

interface Props {
  board?: Board;
  onSubmit: (formFields: { [key: string]: StateField }) => Promise<void>;
  onClose: () => void;
  open: boolean;
  title: string;
}

export const BoardForm = ({ board, onSubmit, open, onClose, title }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { formValues, onChange, resetForm } = useForm(
    {
      title: {
        value: board?.title ?? '',
      },
    },
    true
  );
  const { formErrors, validate, resetFormErrors } = useValidation({
    title: {
      validators: ['empty', 'length'],
    },
  });

  const handleCloseModal = () => {
    resetForm();
    resetFormErrors();
    onClose();
  };

  const handleSubmit = async () => {
    if (!validate(formValues)) {
      return;
    }

    setIsLoading(true);

    await onSubmit(formValues);

    setIsLoading(false);
    handleCloseModal();
  };

  const modalActions = () => {
    return (
      <HStack spacing="20px" mt="35px">
        <Button
          isLoading={isLoading}
          onClick={handleSubmit}
          variant="primary"
          loadingText="Saving..."
        >
          Save
        </Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
      </HStack>
    );
  };

  return (
    <>
      <ModalComponent open={open} onClose={handleCloseModal} title={title}>
        <VStack spacing={6} alignItems="left">
          <FormControl isInvalid={!!formErrors.title}>
            <FormLabel>Title</FormLabel>
            <Input
              variant="filled"
              type="text"
              name="title"
              value={formValues.title.value}
              onChange={onChange}
            />
            {formErrors.title && (
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            )}
          </FormControl>
        </VStack>
        {modalActions()}
      </ModalComponent>
    </>
  );
};
