import { Box, Checkbox, Heading, HStack, Input } from '@chakra-ui/react';
import { SyntheticEvent } from 'react';

import '@prodelio/components/create-task-input/CreateTaskInput.component.scss';

export interface TaskProps {
  value: string;
  name: string;
  onChange: (e: SyntheticEvent) => void;
  onBlur: (e: SyntheticEvent) => void;
}

export const CreateTaskInput = ({
  value,
  onChange,
  onBlur,
  name,
}: TaskProps) => {
  return (
    <Box className="task-input">
      <Box as="span" width={'24px'} height={'24px'}>
        <Checkbox iconSize="60px" className="task-input__checkbox" />
      </Box>
      <Heading className="task-input__header" as="h4">
        <HStack as="span" display="inline-flex" width="100%">
          <HStack
            as="span"
            className="task__header__title"
            width="100%"
            gap={2}
          >
            <Input
              variant="unstyled"
              placeholder="Write the task name..."
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              autoFocus
            />
          </HStack>
        </HStack>
      </Heading>
      <HStack
        className="task-input__time-tracker"
        ml="auto"
        gap={12}
        alignItems="center"
      >
        <HStack gap={5}>
          <HStack>
            <span>0 h</span>
            <span> / </span>
            <span>0 h</span>
          </HStack>
        </HStack>
      </HStack>
    </Box>
  );
};
