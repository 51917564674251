import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Swiper } from '@prodelio/components/swiper/Swiper';
import { FreeMode, Scrollbar } from 'swiper';
import { useDndContext } from '@dnd-kit/core';
import { useEffect, useState } from 'react';

import '@prodelio/components/kanban/Kanban.scss';

interface KanbanProps {
  children?: JSX.Element[] | JSX.Element;
}

export const Kanban = ({ children }: KanbanProps) => {
  const { activeNode } = useDndContext();
  const [swiper, setSwiper] = useState<any>();

  const swiperProps = useBreakpointValue({
    base: {
      freeMode: {
        enabled: false,
      },
      touchReleaseOnEdges: false,
      key: 'mobile',
    },
    md: {
      freeMode: {
        enabled: true,
        momentum: false,
      },
      touchReleaseOnEdges: true,
      key: 'desktop',
    },
  });

  useEffect(() => {
    if (swiper && activeNode) {
      swiper.allowTouchMove = false;
    } else if (swiper) {
      swiper.allowTouchMove = true;
    }
  }, [activeNode]);

  return (
    <Box className="kanban">
      <Box
        pointerEvents="none"
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
      />
      <Swiper
        spaceBetween={0}
        freeMode={{
          ...swiperProps?.freeMode,
        }}
        touchReleaseOnEdges={swiperProps?.touchReleaseOnEdges}
        modules={[FreeMode, Scrollbar]}
        key={swiperProps?.key}
        touchStartPreventDefault={false}
        onSwiper={setSwiper}
        scrollbar={{ draggable: true }}
      >
        {children}
      </Swiper>
    </Box>
  );
};
