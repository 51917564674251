import { Avatar as ChAvatar } from '@chakra-ui/react';

export enum AvatarSize {
  SM = 'sm',
  MD = 'md',
}

interface AvatarProps {
  name: string;
  src: string | null;
  size?: AvatarSize;
}

export const Avatar = ({ src, name, size = AvatarSize.MD }: AvatarProps) => {
  return <ChAvatar size={size} src={src ?? ''} name={name} />;
};
