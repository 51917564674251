import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoutes } from '@prodelio/router/ProtectedRoutes';
import { RegisterPage } from '@prodelio/modules/auth/pages/Register.page';
import { AuthLayout } from '@prodelio/layouts/Auth.layout';
import { LoginPage } from '@prodelio/modules/auth/pages/Login.page';
import { InboxPage } from '@prodelio/modules/tasks/pages/Inbox.page';
import { TodayPage } from '@prodelio/modules/tasks/pages/Today.page';
import { TomorrowPage } from '@prodelio/modules/tasks/pages/Tomorrow.page';
import { CurrentWeekPage } from '@prodelio/modules/tasks/pages/CurrentWeek.page';
import { NextSevenDaysPage } from '@prodelio/modules/tasks/pages/NextSevenDays.page';
import { NextFourteenDaysPage } from '@prodelio/modules/tasks/pages/NextFourteenDays.page';
import { ProjectPage } from '@prodelio/modules/projects/pages/Project.page';
import { ForgotPasswordPage } from '@prodelio/modules/auth/pages/ForgotPassword.page';
import { RestorePasswordPage } from '@prodelio/modules/auth/pages/RestorePassword.page';
import { NextActionsPage } from '@prodelio/modules/tasks/pages/planning/NextActions.page';
import { MaybePage } from '@prodelio/modules/tasks/pages/planning/Maybe.page';
import { DetailPage } from '@prodelio/modules/tasks/pages/Detail.page';
import { GeneralPage } from '@prodelio/modules/settings/pages/General.page';
import { MainLayout } from '@prodelio/layouts/main/Main.layout';
import { AuthRoutes } from '@prodelio/modules/auth/pages/Auth.routes';
import { VerificationPage } from '@prodelio/modules/auth/pages/Verification.page';
import { ResendEmailPage } from '@prodelio/modules/auth/pages/ResendEmail.page';
import { AcceptProjectInvitationPage } from '@prodelio/modules/projects/pages/AcceptProjectInvitation.page';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/app/inbox" />} />
      <Route
        element={
          <AuthRoutes>
            <AuthLayout />
          </AuthRoutes>
        }
      >
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          path="/reset-password/:token"
          element={<RestorePasswordPage />}
        />
        <Route
          path="/account/confirmation/:token"
          element={<VerificationPage />}
        />
        <Route path="/account/confirmation" element={<ResendEmailPage />} />
      </Route>
      <Route path="/app" element={<ProtectedRoutes defaultRedirect="/login" />}>
        <Route element={<MainLayout />}>
          <Route path="inbox" element={<InboxPage />} />
          <Route path="planning/next-actions" element={<NextActionsPage />} />
          <Route path="planning/maybe" element={<MaybePage />} />
          <Route path="today" element={<TodayPage />} />
          <Route path="tomorrow" element={<TomorrowPage />} />
          <Route path="week/current" element={<CurrentWeekPage />} />
          <Route path="next/7/days" element={<NextSevenDaysPage />} />
          <Route path="next/14/days" element={<NextFourteenDaysPage />} />
          <Route path="project">
            <Route path=":projectId" element={<ProjectPage />} />
            <Route
              path="invitation/:token"
              element={<AcceptProjectInvitationPage />}
            />
          </Route>
          <Route path="tasks">
            <Route path=":taskId" element={<DetailPage />} />
          </Route>
        </Route>
        <Route path="settings">
          <Route path="general" element={<GeneralPage />} />
        </Route>
      </Route>
    </Routes>
  );
};
