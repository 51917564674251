import { apiWrapper } from '@prodelio/config/api/api';
import { toastError } from '@prodelio/config/toast/toast';
import {
  Project,
  SyncProject,
} from '@prodelio/config/state/projects/Project.interface';
import { ArchiveProjectRequest } from '@prodelio/modules/projects/features/archive-project/request/ArchiveProject.request';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const archiveProject = (set: any, get: any, projectId: string) => {
  if (isNaN(+projectId)) {
    const archiveProjectReq = new ArchiveProjectRequest(projectId);
    apiWrapper(archiveProjectReq).catch((e: any) => {
      toastError('Project could not be archived.', e.error);

      const syncProjects = get().syncProjects;
      const projectData = get().projects.find(
        ({ id }: Project) => id === projectId
      );
      set(() => ({
        syncProjects: [
          ...syncProjects,
          {
            id: projectId,
            ...projectData,
            archived: true,
            syncStatus: SyncStatus.EDITED,
          },
        ],
      }));
    });
  }

  set(() => {
    const newState: { projects: Project[]; syncProjects?: SyncProject[] } = {
      projects: get().projects.map((project: Project) => {
        if (project.id !== projectId) {
          return project;
        }

        return {
          ...project,
          archived: true,
        };
      }),
    };

    return newState;
  });
};

export type ArchiveProjectType = (projectId: string) => void;
