import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';

export class DeleteProjectRequest extends Request {
  public method: ApiMethods = ApiMethods.DELETE;
  public url = '/projects/:projectId';

  constructor(id: string) {
    super();
    this.url = this.url.replace(':projectId', id);
  }
}
