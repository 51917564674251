import { Box, HStack, Heading } from '@chakra-ui/react';
import {
  TaskList,
  TaskListProps,
} from '@prodelio/components/task-list/TaskList';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import { Task } from '@prodelio/modules/tasks/state/types/Task';
import { ReactElement } from 'react';

interface SectionTaskListProps {
  displayHeader?: boolean;
  header: string;
  TaskListProps: TaskListProps;
  tasks?: Task[];
  sectionId: string;
  actions?: ReactElement[];
}

export const SectionTaskList = ({
  displayHeader = true,
  header,
  TaskListProps,
  tasks,
  sectionId,
  actions,
}: SectionTaskListProps) => {
  const { setNodeRef } = useDroppable({
    id: sectionId,
    data: {
      date: TaskListProps.filters.date,
      boardId: TaskListProps.filters.boardId,
    },
  });

  return (
    <Box position="relative" ref={setNodeRef}>
      <HStack alignItems="center" justifyContent="space-between" mb={6}>
        {displayHeader && <Heading size="md">{header}</Heading>}
        {actions && <HStack gap={2}>{actions}</HStack>}
      </HStack>
      <TaskList {...TaskListProps} initialTasks={tasks} areDraggable>
        {(taskElements, tasks) => (
          <SortableContext
            items={tasks.map(({ id }) => id)}
            strategy={verticalListSortingStrategy}
          >
            {tasks.length === 0 && (
              <Box position="absolute" height={200} width="100%" />
            )}
            {taskElements}
          </SortableContext>
        )}
      </TaskList>
    </Box>
  );
};
