import { Request } from '@prodelio/hooks/api/Request.class';
import { Board } from '@prodelio/modules/projects/state/Board';
import { useBoardStore } from '@prodelio/modules/projects/state/useBoardStore';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';
import { ObjectId } from 'bson';
import { StoreApi } from 'zustand';
import { BoardStore } from '@prodelio/modules/projects/state/BoardStore';
import { apiWrapper } from '@prodelio/config/api/api';

export const fetchBoards = async (
  set: StoreApi<BoardStore>['setState'],
  request: Request,
  filter: { projectId?: string }
): Promise<void> => {
  const { data } = await apiWrapper(request);

  const boardMap: Map<string, Board> = new Map();

  const localBoards = useBoardStore.getState().boards.filter((board) => {
    if (board.syncStatus === SyncStatus.DELETED) {
      return false;
    }

    return board.projectId === filter.projectId;
  });

  localBoards.forEach((board) => {
    boardMap.set(board._id.toString(), board);
  });

  if (data?.data) {
    data.data.forEach((item: Board) => {
      const originBoard: Board = {
        _id: new ObjectId(item._id),
        projectId: item.projectId,
        position: item.position,
        title: item.title,
        syncStatus: null,
      };

      const localBoard: Board | undefined = boardMap.get(
        originBoard._id.toString()
      );
      if (localBoard === undefined) {
        boardMap.set(originBoard._id.toString(), originBoard);
        return;
      }

      boardMap.set(originBoard._id.toString(), {
        ...originBoard,
        title: localBoard.title,
        syncStatus: localBoard.syncStatus,
      });
    });
  }

  set(() => {
    return {
      boards: [...boardMap.values()],
    };
  });
};
