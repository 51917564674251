export const FormLabel = {
  baseStyle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.06em',
    mb: 2,
    _disabled: {
      opacity: 1,
      color: 'var(--chakra-colors-typography-text-light)',
      cursor: 'text',
    },
  },
};
