import { Event } from '@prodelio/modules/common/models/Event';
import { EventSoundCatalog } from '@prodelio/modules/common/notifications/EventSoundCatalog';

export class AudioNotifier {
  private readonly eventSoundCatalog = new EventSoundCatalog();

  public listen(event: Event): void {
    const sound = this.eventSoundCatalog.get(event.eventName);
    if (!sound) {
      return;
    }

    new Audio(sound).play();
  }
}
