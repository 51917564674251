import {
  Swiper as SwiperWrapper,
  SwiperSlide,
  SwiperProps as SwiperWrapperProps,
} from 'swiper/react';
import { getChildrenArray } from '@prodelio/utils/getChildrenArray';

// Import Swiper styles
import 'swiper/css';
import '@prodelio/components/swiper/Swiper.scss';

interface SwiperProps extends SwiperWrapperProps {
  children?: JSX.Element[] | JSX.Element;
}

export const Swiper = ({ children, ...rest }: SwiperProps) => {
  const sliders = getChildrenArray(children);

  return (
    <>
      <SwiperWrapper
        centeredSlides
        slidesPerView="auto"
        spaceBetween={30}
        {...rest}
      >
        {sliders.map((slide, i) => (
          <SwiperSlide key={i}>{slide}</SwiperSlide>
        ))}
      </SwiperWrapper>
    </>
  );
};
