import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';

export class GetTasksByProjectRequest extends Request {
  public method: ApiMethods = ApiMethods.GET;
  public url = '/projects/:projectId/tasks';
  public defaultUrl = '/projects/:projectId/tasks';

  constructor(projectId: string) {
    super();
    this.url = this.defaultUrl.replace(':projectId', projectId);
  }
}
