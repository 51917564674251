import { IRoute } from '@prodelio/router/routes';
import { ILink } from '@prodelio/router/breadcrumbs/interfaces/ILink';
import {
  IStrategy,
  Strategy,
} from '@prodelio/router/breadcrumbs/interfaces/IStrategy';

export class PageStrategy extends Strategy implements IStrategy {
  private pageRoutes: IRoute[];

  constructor(pageRoutes: IRoute[]) {
    super();
    this.pageRoutes = pageRoutes;
  }

  getLinks(): ILink[] {
    const pathname = window.location.pathname
      .replace('/app/', '')
      .split('/')
      .reverse();
    const links: ILink[] = [];

    for (const path of pathname) {
      const pageRoute = this.pageRoutes.find(({ slug }) => slug.endsWith(path));
      if (!pageRoute) {
        break;
      }

      links.push(this.transformItem(pageRoute));
    }

    links.push({
      url: '/app/inbox',
      title: 'Home',
      active: false,
    });

    return links.reverse();
  }

  protected transformItem(item: IRoute): ILink {
    return {
      url: '/app/' + item.slug,
      title: item.title,
      active: window.location.pathname.endsWith(item.slug),
    };
  }
}
