import { createContext } from 'react';

export type EventCallback = (payload?: any) => void;

interface IEventEmitterContext {
  subscribe: (event: string, callback: EventCallback) => void;
  unsubscribe: (event: string, callback: EventCallback) => void;
  dispatchEvent: (event: string, payload?: any) => void;
}

export const EventEmitterContext = createContext<IEventEmitterContext>({
  subscribe: (event: string, callback: EventCallback) => {},
  unsubscribe: (event: string, callback: EventCallback) => {},
  dispatchEvent: (event: string, payload: any) => {},
});
