import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';

export class DeleteSubprojectRequest extends Request {
  public method: ApiMethods = ApiMethods.DELETE;
  public url = '/subprojects/:subprojectId';

  constructor(id: string) {
    super();
    this.url = this.url.replace(':subprojectId', id);
  }
}
