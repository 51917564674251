import { FlattenedProject } from '@prodelio/config/state/projects/Project.interface';

export const mapIdsWithProjects = (
  ids: string[],
  projects: FlattenedProject[]
): FlattenedProject[] => {
  const mappedProjects = [];
  for (const id of ids) {
    const project = projects.find((project) => project.id === id);
    if (project) {
      mappedProjects.push(project);
    }
  }

  return mappedProjects;
};
