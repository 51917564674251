import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Actions, reducer } from '@prodelio/hooks/form/reducer';

const useFormHook = (initialState: any, reinitialize = false) => {
  const [state, dispatch] = useReducer(reducer, {
    formValues: initialState,
    initialFormValues: initialState,
  });

  useEffect(() => {
    if (reinitialize) {
      dispatch({
        type: Actions.INITIALIZE_FORM,
        payload: initialState,
      });
    }
  }, [initialState]);

  const onChange = useCallback(({ target }: any) => {
    const name = target.name || '';
    const value = target.value || '';

    dispatch({
      type: Actions.EDIT_VALUE,
      payload: {
        [name]: {
          value,
        },
      },
    });
  }, []);

  const resetForm = useCallback(() => {
    dispatch({
      type: Actions.RESET_FORM,
    });
  }, []);

  return {
    formValues: state.formValues,
    onChange,
    resetForm,
  };
};

export const useForm = (initialState: any, reinitialize = false) => {
  const stateKeys = Object.keys(initialState);
  const stateDeps = stateKeys.map((key) => initialState[key].value);
  const initialStateMemo = useMemo(() => initialState, stateDeps);

  return useFormHook(initialStateMemo, reinitialize);
};
