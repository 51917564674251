import { createContext } from 'react';
import {
  TimeTrackerEvent,
  UseTimeTrackerReturn,
} from '@prodelio/hooks/time-tracker/useTimeTracker';

export enum TimeTrackerStatus {
  'ACTIVE' = 'ACTIVE',
  'PAUSE' = 'PAUSE',
  'IDLE' = 'IDLE',
}

export interface TimeTrackerContextState {
  status: TimeTrackerStatus;
  time: number;
  taskId: string | null;
  secondInterval?: NodeJS.Timer | null | number;
  minuteInterval?: NodeJS.Timer | null | number;
}

export const defaultTimeTrackerContext: UseTimeTrackerReturn = {
  state: {
    time: 0,
    taskId: null,
    status: TimeTrackerStatus.IDLE,
  },
  onNextTime: (event: TimeTrackerEvent) => {},
  onPlay: (event: TimeTrackerEvent) => {},
  onPause: () => {},
  onStop: () => {},
};

export const TimeTrackerContext = createContext<UseTimeTrackerReturn>(
  defaultTimeTrackerContext
);
