import { StateCreator } from 'zustand';
import { FetchProjectAction } from '@prodelio/config/state/projects/actions/FetchProject.action';
import { Request } from '@prodelio/hooks/api/Request.class';
import { Status } from '@prodelio/hooks/api/useApi';
import { createProject } from '@prodelio/config/state/projects/actions/createProject';
import { editProject } from '@prodelio/config/state/projects/actions/editProject';
import { editLocalProject } from '@prodelio/config/state/projects/actions/editLocalProject';
import { deleteProject } from '@prodelio/config/state/projects/actions/deleteProject';
import { Project } from '@prodelio/config/state/projects/Project.interface';
import { archiveProject } from '@prodelio/config/state/projects/actions/archiveProject';

export const createProjectSlice: StateCreator<any, any> = (set, get) => ({
  projects: [],
  syncProjects: [],
  projectsStatus: Status.WAITING,
  fetchProjects: (request: Request) => FetchProjectAction(set, get, request),
  createProject: (request: Request) => createProject(set, get, request),
  deleteProject: (projectId: string) => deleteProject(set, get, projectId),
  editLocalProject: (projectId: string, data: Partial<Project>) =>
    editLocalProject(set, get, projectId, data),
  editProject: (projectId: string, request: Request) =>
    editProject(set, get, projectId, request),
  archiveProject: (projectId: string, request: Request) =>
    archiveProject(set, get, projectId),
});
