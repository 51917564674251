import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useBoardStore } from '@prodelio/modules/projects/state/useBoardStore';
import { CreateBoardRequest } from '@prodelio/modules/projects/features/create-board/CreateBoardRequest';
import { Board } from '@prodelio/modules/projects/state/Board';
import { ObjectId } from 'bson';
import { useParams } from 'react-router-dom';
import { BoardForm } from '@prodelio/modules/projects/components/BoardForm/BoardForm';
import { StateField } from '@prodelio/hooks/form/reducer';

export const CreateBoard = () => {
  const { projectId } = useParams();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const createBoard = useBoardStore().createBoard;
  const latestBoardPosition = useBoardStore((store) =>
    Math.max(...store.boards.map((board) => board.position), -1)
  );

  if (!projectId) {
    return null;
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async (formValues: { [key: string]: StateField }) => {
    const board: Board = {
      _id: new ObjectId(),
      title: formValues.title.value,
      projectId: projectId,
      position: latestBoardPosition + 1,
      syncStatus: null,
    };

    const request = new CreateBoardRequest();
    request.url = request.url.replace(':projectId', projectId);
    request.body = board;

    createBoard(request, board);
    handleCloseModal();
  };

  return (
    <>
      <Button
        onClick={handleOpenModal}
        size="sm"
        variant="icon-link-primary"
        aria-label="Create board"
      >
        Create board
      </Button>
      <BoardForm
        title="Create board"
        onSubmit={handleSubmit}
        onClose={handleCloseModal}
        open={openModal}
      />
    </>
  );
};
