import { Box, Flex } from '@chakra-ui/react';

import '@prodelio/components/navbar/mobile/MobileNavbar.component.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const MobileNavbar = ({ children }: Props) => {
  return (
    <Flex
      w="100%"
      h="60px"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className="mobile-navbar"
    >
      {children}
    </Flex>
  );
};

export const MobileNavbarMenu = ({ children }: Props) => {
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const menuClass = open ? 'mobile-navbar__menu--open' : '';

  const handleOpenMenu = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Box className={`mobile-navbar__menu ${menuClass}`}>
      <FontAwesomeIcon
        onClick={handleOpenMenu}
        className="mobile-navbar__menu__button"
        icon={solid('bars')}
      />
      <Box className="mobile-navbar__menu__items">{children}</Box>
    </Box>
  );
};
