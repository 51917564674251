import { CollisionDescriptor, CollisionDetection } from '@dnd-kit/core';
import { calculateDistance } from '@prodelio/components/sortable-list/utils/calculateDistance';
import { getCentralPoint } from '@prodelio/components/sortable-list/utils/getCentralPoint';

export const closestBottom: CollisionDetection = ({
  droppableRects,
  collisionRect,
  droppableContainers,
}) => {
  const draggingRectPoint = getCentralPoint(collisionRect, collisionRect.top);

  const collisions: CollisionDescriptor[] = [];
  for (const droppableContainer of droppableContainers) {
    const rect = droppableRects.get(droppableContainer.id);

    if (rect) {
      const rectPoint = getCentralPoint(rect);
      const distance = calculateDistance(rectPoint, draggingRectPoint);

      collisions.push({
        id: droppableContainer.id,
        data: {
          droppableContainer,
          value: distance,
        },
      });
    }
  }

  collisions.sort((a, b) => a.data.value - b.data.value);

  return collisions;
};
