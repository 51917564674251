import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';

import '@prodelio/components/dropdown/Dropdown.component.scss';

interface DropdownProps {
  button: JSX.Element;
  children: JSX.Element[] | JSX.Element | undefined;
  className: string;
}

export const Dropdown = ({ button, className, children }: DropdownProps) => {
  let items: JSX.Element[] = [];
  if (Array.isArray(children)) {
    items = children;
  } else if (children) {
    items = [children];
  }

  const menuItems = items.map((item, index) => (
    <MenuItem className="dropdown__list__item" key={index}>
      {item}
    </MenuItem>
  ));

  return (
    <div className={`dropdown ${className}`}>
      <Menu strategy="fixed" placement="bottom-end">
        <MenuButton cursor="pointer" as={Box} className="dropdown__button">
          {button}
        </MenuButton>
        <Portal>
          <MenuList className="dropdown__list">{menuItems}</MenuList>
        </Portal>
      </Menu>
    </div>
  );
};
