import { TaskConstants } from '@prodelio/modules/tasks/Task.constants';

const TimeCalc: { [key: string]: (time: string) => number } = {
  h: (time: string) => parseFloat(time) * 60 * 60 * 1000,
  m: (time: string) => parseFloat(time) * 60 * 1000,
  s: (time: string) => parseFloat(time) * 1000,
};

export const timeParser = (time: string): number => {
  if (TaskConstants.TIME_PATTERN_PARSER.FULL.test(time)) {
    const results = time.trim().split(' ');

    return results.reduce((previousValue: number, currentValue: string) => {
      const unit = currentValue[currentValue.length - 1];
      const timeFn = TimeCalc[unit] || TimeCalc['h'];

      return previousValue + timeFn(currentValue);
    }, 0);
  }

  return 0;
};
