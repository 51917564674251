import { useLocation, Location } from 'react-router-dom';
import { useToast, UseToastOptions } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { LoginComponent } from '@prodelio/modules/auth/features/login/Login.component';

interface LoginLocationState {
  toastOptions: UseToastOptions;
}

interface LoginLocation extends Location {
  state: LoginLocationState;
}

export const LoginPage = () => {
  const toast = useToast();
  const { state } = useLocation() as LoginLocation;
  const { setTitle } = useRouteProps();

  useEffect(() => {
    setTitle('Login');
  }, []);

  useEffect(() => {
    if (state?.toastOptions) {
      toast(state.toastOptions);
    }
  }, []);

  return (
    <>
      <LoginComponent />
    </>
  );
};
