import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { Task } from '@prodelio/modules/tasks/state/types/Task';

export class EditTaskRequest extends Request {
  public method: ApiMethods = ApiMethods.PUT;
  public url = '/tasks/:taskId';
  public body: Partial<Task>;

  constructor(id: string, body: any, immediate = true) {
    super(body, immediate);
    this.url = this.url.replace(':taskId', id);
    this.body = body;

    this.id = id;
  }
}
