import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';

export class DeleteTaskRequest extends Request {
  public method: ApiMethods = ApiMethods.DELETE;
  public url = '/tasks/:taskId';

  constructor(id: string) {
    super();
    this.url = this.url.replace(':taskId', id);

    this.id = id;
  }
}
