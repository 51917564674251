import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';

export class ArchiveProjectRequest extends Request {
  public method: ApiMethods = ApiMethods.POST;
  public url = '/projects/archive';

  constructor(id: string) {
    super();
    this.body = {
      projectId: id,
    };
  }
}
