import { Heading, VStack, Text, Button } from '@chakra-ui/react';
import { apiWrapper } from '@prodelio/config/api/api';
import { toastError, toastSuccess } from '@prodelio/config/toast/toast';
import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { ResendEmailRequest } from '@prodelio/modules/auth/features/resend-email/requests/ResendEmail.request';

export const ResendEmailFeature = () => {
  const { state, setState } = useRouteProps();

  const handleResendVerification = async () => {
    try {
      const request = new ResendEmailRequest({
        email: state.email,
      });

      const response = await apiWrapper(request);
      if (!response?.data?.success) {
        throw new Error(
          "Something went wrong, we couldn't send the verification email. Try again later"
        );
      }

      toastSuccess('Email sent successfully', '');
      setState({
        email: '',
      });
    } catch (err) {
      toastError(
        "Something went wrong, we couldn't send the verification email. Try again later",
        ''
      );
    }
  };

  return (
    <VStack spacing={7}>
      <Heading>Your account has been disabled</Heading>
      <VStack spacing={4}>
        <Text>
          To enable your account you must verify your email address by clicking
          the button below
        </Text>
        <Button onClick={handleResendVerification} variant="primary" size="sm">
          Resend verification email
        </Button>
      </VStack>
    </VStack>
  );
};
