import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';

export class GetInboxTasksRequest extends Request {
  public method: ApiMethods = ApiMethods.GET;
  public url = '/tasks';

  constructor() {
    super();

    this.addFilters({
      projectId__eq: 'null',
    });
  }
}
