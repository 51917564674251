import { apiWrapper } from '@prodelio/config/api/api';
import { toastError } from '@prodelio/config/toast/toast';
import { Request } from '@prodelio/hooks/api/Request.class';
import { SetStore, TaskState } from '@prodelio/modules/tasks/state/TaskStore';
import { AxiosError } from 'axios';
import { getTask } from '@prodelio/modules/tasks/state/selectors/getTask';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const deleteTask = (set: SetStore<TaskState>, request: Request) => {
  const taskId = request.getId();
  const taskToDelete = getTask(taskId as string);

  apiWrapper(request).catch((e: Error) => {
    if (e instanceof AxiosError && e.response?.status === 404) {
      toastError('Task does not exist.', e.message);

      return;
    }

    toastError('Task could not be deleted', e.message);
    set((state) => ({
      tasks: [
        ...state.tasks,
        {
          ...taskToDelete,
          syncStatus: SyncStatus.DELETED,
          updatedAt: new Date().toISOString(),
        },
      ],
    }));
  });

  set((state) => ({
    tasks: state.tasks.filter(
      ({ id, parentTaskId }) => id !== taskId && parentTaskId !== taskId
    ),
  }));
};
