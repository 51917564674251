import { AlertProps } from '@chakra-ui/react';

const AlertStatusStyles = {
  info: {
    container: {
      background: 'info.default',
      color: 'typography.heading.dark',
    },
  },
  warning: {},
  success: {},
  error: {},
  loading: {},
};

export const Alert = {
  baseStyle: ({ status }: AlertProps) => {
    return {
      ...AlertStatusStyles[status || 'info'],
    };
  },
};
