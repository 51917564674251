import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export enum AppViews {
  CURRENT_WEEK = 'CURRENT_WEEK',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  NEXT_14_DAYS = 'NEXT_14_DAYS',
  INBOX = 'INBOX',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  PROJECT = 'PROJECT',
}

export enum TaskViewEnum {
  KANBAN = 'KANBAN',
  LIST = 'LIST',
}

interface ViewConfig {
  taskView: TaskViewEnum;
  sortedTasks: string[] | string[][];
  pathname: string;
  sortedTaskSections: number;
  displayViewSelector: boolean;
}

export const AppRoutes: { [key: string]: ViewConfig } = {
  [AppViews.CURRENT_WEEK]: {
    taskView: TaskViewEnum.LIST,
    sortedTasks: [],
    pathname: '/app/week/current',
    sortedTaskSections: 7,
    displayViewSelector: true,
  },
  [AppViews.NEXT_7_DAYS]: {
    taskView: TaskViewEnum.LIST,
    sortedTasks: [],
    pathname: '/app/next/7/days',
    sortedTaskSections: 7,
    displayViewSelector: true,
  },
  [AppViews.NEXT_14_DAYS]: {
    taskView: TaskViewEnum.LIST,
    sortedTasks: [],
    pathname: '/app/next/14/days',
    sortedTaskSections: 14,
    displayViewSelector: true,
  },
  [AppViews.INBOX]: {
    taskView: TaskViewEnum.LIST,
    sortedTasks: [],
    pathname: '/app/inbox',
    sortedTaskSections: 1,
    displayViewSelector: false,
  },
  [AppViews.TODAY]: {
    taskView: TaskViewEnum.LIST,
    sortedTasks: [],
    pathname: '/app/today',
    sortedTaskSections: 1,
    displayViewSelector: false,
  },
  [AppViews.TOMORROW]: {
    taskView: TaskViewEnum.LIST,
    sortedTasks: [],
    pathname: '/app/tomorrow',
    sortedTaskSections: 1,
    displayViewSelector: false,
  },
  [AppViews.PROJECT]: {
    taskView: TaskViewEnum.LIST,
    sortedTasks: [],
    pathname: '/app/project',
    sortedTaskSections: 1,
    displayViewSelector: true,
  },
};

const commonViewConfig: Partial<ViewConfig> = {
  taskView: TaskViewEnum.LIST,
  sortedTasks: [],
};

const viewsConfig = Object.entries(AppRoutes).reduce(
  (currentValue, [view, config]) => ({
    ...currentValue,
    [view]: {
      ...commonViewConfig,
      ...config,
    },
  }),
  {}
);

const updateViewConfigUtil = (
  set: any,
  view: AppViews,
  config: Partial<ViewConfig>
) => {
  set((state: AppConfigSlice) => {
    const {
      config: { views, ...restConfig },
    } = state;

    return {
      ...state,
      config: {
        ...restConfig,
        views: {
          ...views,
          [view]: {
            ...views[view],
            ...config,
          },
        },
      },
    };
  });
};

export const useAppConfigStore = create<AppConfigSlice>()(
  persist(
    (set, get) => ({
      config: {
        global: {
          views: commonViewConfig,
        },
        views: viewsConfig,
      },
      changeViewConfig: (view: AppViews, config: Partial<ViewConfig>) => {
        set((state: AppConfigSlice) => {
          const {
            config: { views, ...restConfig },
          } = state;

          return {
            ...state,
            config: {
              ...restConfig,
              views: {
                ...views,
                [view]: {
                  ...views[view],
                  ...config,
                },
              },
            },
          };
        });
      },
      initSortedTasks: (taskIds: string[], currentView: string) => {
        const sortedTasks = get().config.views[currentView]?.sortedTasks ?? [];

        const maxSortedTasksSectionLength =
          AppRoutes[currentView]?.sortedTaskSections;
        if (
          !maxSortedTasksSectionLength ||
          sortedTasks.length === maxSortedTasksSectionLength
        )
          return;

        updateViewConfigUtil(set, currentView as AppViews, {
          sortedTasks: [...(sortedTasks as string[][]), taskIds],
        });
      },
    }),
    {
      name: 'catd-config',
      version: 1,
      getStorage: () => localStorage,
      migrate: (persistedState: unknown, version: number) => {
        const updatedState = persistedState as AppConfigSlice | null;
        if (!updatedState) {
          return updatedState;
        }

        if (version === 0) {
          const updatedViews: Record<string, ViewConfig> = {};

          Object.entries(updatedState.config.views).forEach(
            ([key, view]: [string, ViewConfig]) => {
              if (!view || !key) {
                return;
              }

              const defaultViewConfig = AppRoutes[key];
              if (defaultViewConfig) {
                updatedViews[key] = {
                  ...defaultViewConfig,
                  ...view,
                  sortedTaskSections: defaultViewConfig.sortedTaskSections,
                  displayViewSelector: defaultViewConfig.displayViewSelector,
                  pathname: defaultViewConfig.pathname,
                };
              } else {
                updatedViews[key] = view;
              }
            }
          );

          updatedState.config.views = updatedViews;
        }

        return updatedState;
      },
    }
  )
);

export interface AppConfigSlice {
  config: {
    global: {
      views: Partial<ViewConfig>;
    };
    views: {
      [key: string]: ViewConfig;
    };
  };
  changeViewConfig: (view: AppViews, config: Partial<ViewConfig>) => void;
  initSortedTasks: (taskIds: string[], currentView: string) => void;
}
