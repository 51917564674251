import { HStack, Kbd } from '@chakra-ui/react';

import '@prodelio/components/shortcut/Shortcut.component.scss';

interface ShortcutProps {
  children: string;
}

export const Shortcut = ({ children }: ShortcutProps) => {
  const SHORTCUT_SEPARATOR = ' + ';
  const shortcutTokens = children.split(SHORTCUT_SEPARATOR);

  const shortcutKeys = shortcutTokens.map((token) => (
    <Kbd className="shortcut__key">{token}</Kbd>
  ));

  const shortcutCombination = [];
  for (const shortcutKey of shortcutKeys) {
    if (shortcutCombination.length) {
      shortcutCombination.push(<>{SHORTCUT_SEPARATOR.trim()}</>, shortcutKey);
    } else {
      shortcutCombination.push(shortcutKey);
    }
  }

  return (
    <HStack className="shortcut" gap={2}>
      {shortcutCombination}
    </HStack>
  );
};
