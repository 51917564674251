import { Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import '@prodelio/components/logo/Logo.component.scss';

export const LogoComponent = () => {
  return (
    <Heading as={Link} to="/app/inbox" className="logo" size="lg" mx="auto">
      Prodelio
    </Heading>
  );
};
