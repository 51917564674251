import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';
import { Board } from '@prodelio/modules/projects/state/Board';
import { useBoardStore } from '@prodelio/modules/projects/state/useBoardStore';

export const getBoards = (filter: { projectId?: string }): Board[] => {
  const boards = useBoardStore((store) =>
    store.boards.filter((board) => {
      if (board.syncStatus === SyncStatus.DELETED) {
        return false;
      }

      return board.projectId === filter.projectId;
    })
  );

  boards.sort((leftBoard, rightBoard) => {
    return leftBoard.position - rightBoard.position;
  });

  return boards;
};
