import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Button,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '@prodelio/hooks/auth/useAuth';
import { Logout } from '@prodelio/modules/auth/features/logout/Logout';
import { Avatar } from '@prodelio/components/avatar/Avatar.component';

import '@prodelio/components/user-menu/UserMenu.component.scss';

interface UserMenuProps {
  direction?: 'vertical' | 'horizontal';
}

export const UserMenu = ({ direction = 'horizontal' }: UserMenuProps) => {
  const { user, checkAuth } = useAuth();

  useEffect(() => {
    checkAuth();
  }, []);

  const flexDirection = {
    vertical: 'column-reverse',
    horizontal: 'row',
  };

  return (
    <Box
      className="user-menu"
      width={direction === 'vertical' ? '100%' : 'initial'}
    >
      <Menu
        strategy="fixed"
        placement={direction === 'vertical' ? 'bottom' : 'auto'}
      >
        <MenuButton
          sx={{
            width: '100%',
            '& > span': {
              flexDirection: flexDirection[direction],
            },
          }}
          className="user-menu__avatar"
          aria-label="Profile"
        >
          <span className="user-menu__avatar__username">{user?.username}</span>
          <Avatar src={null} name={user?.username ?? ''} />
        </MenuButton>
        <MenuList>
          <MenuItem as="div" className="user-menu__action">
            <Button
              to="/app/settings/general"
              as={Link}
              width="100%"
              variant="menu"
              justifyContent="left"
            >
              Settings
            </Button>
          </MenuItem>
          <MenuItem as="div" className="user-menu__action">
            <Logout />
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
