import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@prodelio/App';
import { ServiceWorkerInitializer } from '@prodelio/config/pwa/ServiceWorker.initializer';
import { ToastContainer } from '@prodelio/config/toast/toast';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <App />
    <ToastContainer />
  </>
);

ServiceWorkerInitializer.register();
