export enum Actions {
  'RESET_FORM' = 'RESET_FORM',
  'EDIT_VALUE' = 'EDIT_VALUE',
  'INITIALIZE_FORM' = 'INITIALIZE_FORM',
}

export interface StateField {
  value: any;
}

interface Payload {
  [key: string]: StateField;
}

interface Action {
  type: Actions;
  payload?: Payload;
}

export interface FormValues {
  [key: string]: StateField;
}

interface State {
  formValues: FormValues;
  initialFormValues: FormValues;
}

export const reducer = (state: State, { type, payload }: Action) => {
  const actions = {
    [Actions.EDIT_VALUE]: () => ({
      ...state,
      formValues: {
        ...state.formValues,
        ...payload,
      },
    }),
    [Actions.RESET_FORM]: () => ({
      ...state,
      formValues: {
        ...state.initialFormValues,
      },
    }),
    [Actions.INITIALIZE_FORM]: () => ({
      ...state,
      formValues: {
        ...payload,
      },
      initialFormValues: {
        ...payload,
      },
    }),
  };

  return actions[type]();
};
