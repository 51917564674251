import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';

export class TimeTrackerRequest extends Request {
  public method: ApiMethods = ApiMethods.PATCH;
  public url = '/tasks/:taskId/track';
  protected initialUrl = '/tasks/:taskId/track';

  public init(id: string): void {
    this.url = this.initialUrl.replace(':taskId', id);
  }
}
