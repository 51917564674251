import { useCallback, useReducer } from 'react';
import {
  defaultTimeTrackerContext,
  TimeTrackerContextState,
} from '@prodelio/context/time-tracker/TimeTracker.context';
import {
  TimeTrackerActions,
  TimeTrackerReducer,
} from '@prodelio/hooks/time-tracker/TimeTracker.reducer';
import { TimeTrackerConstants } from '@prodelio/hooks/time-tracker/TimeTracker.constants';

export interface TimeTrackerEvent {
  target: {
    value?: number;
    taskId: string;
    secondInterval?: NodeJS.Timer | null;
    minuteInterval?: NodeJS.Timer | null;
  };
}

export interface UseTimeTrackerReturn {
  state: TimeTrackerContextState;
  onNextTime: (event: TimeTrackerEvent) => void;
  onPlay: (event: TimeTrackerEvent) => void;
  onPause: () => void;
  onStop: () => void;
}

export const useTimeTracker = (): UseTimeTrackerReturn => {
  const [state, dispatch] = useReducer(
    TimeTrackerReducer,
    defaultTimeTrackerContext.state
  );

  const onNextTime = useCallback(() => {
    dispatch({
      type: TimeTrackerActions.NEXT_TIME,
    });
  }, []);

  const onPlay = useCallback(
    ({ target: { taskId, minuteInterval } }: TimeTrackerEvent) => {
      dispatch({
        type: TimeTrackerActions.PLAY,
        payload: {
          taskId,
          secondInterval: setInterval(onNextTime, TimeTrackerConstants.SECOND),
          minuteInterval,
        },
      });
    },
    [state]
  );

  const onPause = useCallback(() => {
    dispatch({
      type: TimeTrackerActions.PAUSE,
    });
  }, [state]);

  const onStop = useCallback(() => {
    dispatch({
      type: TimeTrackerActions.STOP,
    });
  }, [state]);

  return {
    state,
    onPause,
    onPlay,
    onStop,
    onNextTime,
  };
};
