import { store } from '@prodelio/config/state/store';
import {
  FlattenedProject,
  Project,
} from '@prodelio/config/state/projects/Project.interface';
import { isArrayEqual } from '@prodelio/utils/isArrayEqual';

interface GetProjectsSort {
  orderBy: keyof Project;
  sortType: 'ASC' | 'DESC';
}

interface getProjectsQuery {
  archived: boolean;
}

const defaultQuery = {
  archived: false,
};

export const getProjects = (
  query: getProjectsQuery = defaultQuery
): FlattenedProject[] => {
  return store
    .getState()
    .projects.filter(({ archived }) => archived === query.archived);
};

export const useProjects = (
  query: getProjectsQuery = defaultQuery,
  sort?: [GetProjectsSort]
) => {
  const projects = store(
    (state) =>
      state.projects.filter(({ archived }) => archived === query.archived),
    isArrayEqual
  );

  if (sort && sort.length) {
    const sortQuery = sort[0];

    projects.sort((projectA, projectB) => {
      let keyA = projectA[sortQuery.orderBy];
      let keyB = projectB[sortQuery.orderBy];

      if (keyA === null || keyA === undefined) {
        return 1;
      } else if (keyB === null || keyB === undefined) {
        return 1;
      }

      const order = sortQuery.sortType === 'ASC' ? 1 : -1;
      let comparison = 0;

      if (typeof keyA === 'string' && typeof keyB === 'string') {
        keyA = keyA.toLowerCase();
        keyB = keyB.toLowerCase();

        comparison = keyA.localeCompare(keyB);
      } else if (typeof keyA === 'number' && typeof keyB === 'number') {
        comparison = keyA - keyB;
      }

      if (comparison > 0) {
        return order;
      } else if (comparison < 0) {
        return -order;
      }

      return 0;
    });
  }

  return projects;
};
