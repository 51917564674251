import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FormEvent } from 'react';
import { useRestorePasswordValidation } from '@prodelio/modules/auth/features/restore-password/useRestorePasswordValidation.hook';
import { RestorePasswordRequest } from '@prodelio/modules/auth/features/restore-password/requests/RestorePassword.request';
import { useForm } from '@prodelio/hooks/form/useForm';
import { useApi } from '@prodelio/hooks/api/useApi';

export const RestorePasswordComponent = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { token } = useParams();

  const { formValues, onChange, resetForm } = useForm({
    password: {
      value: '',
    },
    repeatPassword: {
      value: '',
    },
  });
  const { formErrors, validate } = useRestorePasswordValidation(formValues);

  const transformFormValues = () => {
    return {
      token,
      password: formValues.password.value,
      repeatPassword: formValues.repeatPassword.value,
    };
  };

  const restorePasswordRequest = new RestorePasswordRequest(
    transformFormValues()
  );
  const { fetch } = useApi(restorePasswordRequest, false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validate(formValues)) {
      return;
    }

    try {
      await fetch();
      resetForm();
      toast({
        status: 'success',
        duration: 5000,
        title: 'Password restored.',
        isClosable: true,
        position: 'top-left',
      });
      navigate('/login');
    } catch (e: any) {
      toast({
        status: 'error',
        duration: 5000,
        title: 'Error updating the password.',
        description: e.error,
        isClosable: true,
        position: 'top-left',
      });
    }
  };

  return (
    <>
      <Heading as="h2" size="md" textAlign="center">
        Restore Password
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={6} mt={8} align="left" maxW="350px" mx="auto">
          <FormControl isInvalid={!!formErrors.password}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={formValues.password.value}
              onChange={onChange}
            />
            {formErrors.password && (
              <FormErrorMessage>{formErrors.password}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!formErrors.repeatPassword}>
            <FormLabel>Repeat Password</FormLabel>
            <Input
              type="password"
              name="repeatPassword"
              value={formValues.repeatPassword.value}
              onChange={onChange}
            />
            {formErrors.repeatPassword && (
              <FormErrorMessage>{formErrors.repeatPassword}</FormErrorMessage>
            )}
          </FormControl>
          <HStack spacing={2}>
            <Button variant="primary" type="submit">
              Update password
            </Button>
          </HStack>
          <HStack justifyContent="flex-start">
            <Button variant="link" as={Link} to="/login" size="sm" p="0">
              Login
            </Button>
            <Button variant="link" as={Link} to="/register" size="sm">
              Don't have an account?
            </Button>
          </HStack>
        </VStack>
      </form>
    </>
  );
};
