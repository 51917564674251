import { useEffect } from 'react';
import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { SettingsLayout } from '@prodelio/layouts/settings/Settings.layout';
import { EditUserFeature } from '@prodelio/modules/settings/features/edit-user/EditUser.feature';

export const GeneralPage = () => {
  const { setTitle } = useRouteProps();

  useEffect(() => {
    setTitle('General Settings');
  }, []);

  return (
    <SettingsLayout>
      <EditUserFeature />
    </SettingsLayout>
  );
};
