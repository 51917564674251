import { add, endOfDay, startOfToday } from 'date-fns';
import { TaskView } from '@prodelio/components/task-view/TaskView';
import { AppViews } from '@prodelio/config/state/app-config/AppConfig';
import { useKanbanDateSections } from '@prodelio/modules/tasks/hooks/useKanbanDateSections';
import { GetNextFourteenDaysTasksRequest } from '@prodelio/modules/tasks/features/list-next-fourteen-days-tasks/GetNextFourteenDaysTasks.request';
import { TaskSortOptions } from '@prodelio/modules/tasks/state/selectors/selectTasks';

export const ListNextFourteenDaysTasks = () => {
  const startDate = startOfToday();
  const endDate = endOfDay(
    add(startDate, {
      days: 14,
    })
  );

  const taskFilters = {
    startDate,
    endDate,
  };

  const sort = [TaskSortOptions.MANUAL];

  const request = new GetNextFourteenDaysTasksRequest();

  const sections = useKanbanDateSections(
    taskFilters,
    sort,
    request,
    AppViews.NEXT_14_DAYS
  );

  return <TaskView canChange taskFilters={taskFilters} sections={sections} />;
};
