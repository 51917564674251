import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { Request } from '@prodelio/hooks/api/Request.class';

interface RestorePasswordRequestBody {
  token: string;
  password: string;
  repeatPassword: string;
}

export class RestorePasswordRequest extends Request {
  public method: ApiMethods = ApiMethods.POST;
  public url = '/mail/password/reset';

  public init(body: RestorePasswordRequestBody) {
    this.body = body;
  }
}
