export const global = {
  body: {
    h: '100%',
    bg: 'common.background',
    color: 'typography.text.light',
    overflow: 'hidden',
  },
  '*': {
    fontFamily: `'Open Sans', sans-serif`,
  },
  '#root': {
    h: '100vh',
  },
  '::-webkit-scrollbar': {
    width: '12px',
    '&-track': {
      background: '#1B1F22',
    },
    '&-thumb': {
      backgroundColor: 'primary.main',
      border: '4px solid',
      borderColor: '#1B1F22',
      borderRadius: 12,
      '&:hover': {
        backgroundColor: '#3E5363',
        borderColor: '#B1F22',
      },
    },
  },
  '.swiper-scrollbar-horizontal': {
    position: 'absolute',
    bottom: 0,
    zIndex: 5,
    width: '100%',
    height: '12px',
    background: '#1B1F22',
    '& .swiper-scrollbar-drag': {
      position: 'relative',
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      backgroundColor: 'primary.main',
      border: '4px solid',
      borderColor: '#1B1F22',
      borderRadius: 12,
      '&:hover': {
        backgroundColor: '#3E5363',
        borderColor: '#B1F22',
      },
    },
  },
};
