import { Box, Button, Heading, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { AppLayout } from '@prodelio/layouts/app/App.layout';
import { ReactNode } from 'react';
import { Breadcrumbs } from '@prodelio/components/breadcrumbs/Breadcrumbs.component';

interface SettingsLayoutProps {
  children: ReactNode;
}

export const SettingsLayout = ({ children }: SettingsLayoutProps) => {
  const SidebarContent = (
    <>
      <Box w="100%">
        <Heading as="h3" mb="12px" size="md">
          User Settings
        </Heading>
        <VStack alignItems="left" spacing="12px">
          <Button variant="navlink" as={NavLink} to="/app/settings/general">
            General
          </Button>
        </VStack>
      </Box>
    </>
  );

  const headerContent = <Breadcrumbs />;

  return (
    <AppLayout
      fullWidthHeader={false}
      sidebarLinks={SidebarContent}
      headerComponents={headerContent}
    >
      {children}
    </AppLayout>
  );
};
