import { StoreApi, create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { BoardStore } from '@prodelio/modules/projects/state/BoardStore';
import { Request } from '@prodelio/hooks/api/Request.class';
import { Board } from '@prodelio/modules/projects/state/Board';
import { createBoard } from '@prodelio/modules/projects/state/actions/createBoard';
import { ObjectId } from 'bson';
import { editBoard } from '@prodelio/modules/projects/state/actions/editBoard';
import { deleteBoard } from '@prodelio/modules/projects/state/actions/deleteBoard';
import { fetchBoards } from '@prodelio/modules/projects/state/actions/fetchBoards';

export const useBoardStore = create<BoardStore>()(
  persist(
    (set: StoreApi<BoardStore>['setState']): BoardStore => {
      return {
        boards: [],
        createBoard: (request: Request, data: Board) =>
          createBoard(set, request, data),
        editBoard: (request: Request, data: Board) =>
          editBoard(set, request, data),
        deleteBoard: (request: Request, data: Board) =>
          deleteBoard(set, request, data),
        fetchBoards: (
          request: Request,
          filters: { projectId: string | undefined }
        ) => fetchBoards(set, request, filters),
      };
    },
    {
      name: 'prodelio-board-storage',
      storage: createJSONStorage(() => localStorage, {
        reviver: (key: unknown, value: unknown) => {
          if (key === '_id' && typeof value === 'string') {
            return new ObjectId(value);
          }

          return value;
        },
      }),
    }
  )
);
