export class KeyCombinationFormatError extends TypeError {
  constructor(msg: string) {
    super(msg);
  }
}

export class KeyCombination {
  private FORMAT = /(CTRL\+?|ALT\+?|SHIFT\+?){0,3}[a-zA-Z]{0,1}/;
  private _value: string;

  static KeyCodeMap: { [key: string]: string } = {
    CTRL: 'ctrlKey',
    SHIFT: 'shiftKey',
    ALT: 'altKey',
  };

  constructor(value: string) {
    const combination = value.trim();

    if (!value) {
      throw new KeyCombinationFormatError('KeyCombination can not be empty');
    }

    const isValueValid = combination.match(this.FORMAT);
    if (!isValueValid) {
      throw new KeyCombinationFormatError(
        'KeyCombination does not follow correct format at: ' + value
      );
    }

    this._value = value;
  }

  toString() {
    return this._value;
  }

  get value() {
    const valueObject: Record<string, boolean | null | string> = {
      altKey: false,
      shiftKey: false,
      ctrlKey: false,
      key: null,
    };

    for (const key of this._value.split('+')) {
      const keyProp: string | undefined = KeyCombination.KeyCodeMap[key];

      if (keyProp) {
        valueObject[keyProp] = true;
      } else {
        valueObject.key = key;
      }
    }

    return valueObject;
  }
}
