import { extendTheme } from '@chakra-ui/react';
import { colors } from '@prodelio/config/theme/colors/colors';
import { styles } from '@prodelio/config/theme/styles/styles';
import { components } from '@prodelio/config/theme/components/components';

export const theme = extendTheme({
  colors,
  styles,
  components,
  fonts: {
    body: `'Open Sans', sans-serif`,
    heading: `'Open Sans', sans-serif`,
  },
});
