import { Request } from '@prodelio/hooks/api/Request.class';
import { Project } from '@prodelio/config/state/projects/Project.interface';
import { Status } from '@prodelio/hooks/api/useApi';
import { apiWrapper } from '@prodelio/config/api/api';
import { isAfter, parseISO } from 'date-fns';
import { isNaN } from 'lodash';
import { flattenProjects } from '@prodelio/config/state/projects/utils/flattenProjects';

export const FetchProjectAction = async (
  set: any,
  get: any,
  request: Request
): Promise<void> => {
  try {
    set({
      projectsStatus: Status.LOADING,
    });
    const res = await apiWrapper(request);

    const localProjects = get().projects;
    const syncProjects = get().syncProjects;
    const serverProjects = res ? res.data.data : [];

    let projectsToSave: any = [];
    if (serverProjects.length && !localProjects.length) {
      projectsToSave = serverProjects;
    } else {
      projectsToSave = [
        ...updateProjects(localProjects, serverProjects),
        ...extractUniqueServerProjects(
          localProjects,
          serverProjects,
          syncProjects
        ),
      ];
    }

    const orderedProjects = flattenProjects(projectsToSave);

    set({
      projectsStatus: Status.DONE,
      projects: orderedProjects.map((project) => ({
        ...project,
        sharedWith: project.sharedWith ?? [],
      })),
    });
  } catch (e) {
    set({
      projectsStatus: Status.ERROR,
    });
  }
};

export type FetchProjectActionType = (request: Request) => Promise<void>;

const updateProjects = (
  localProjects: Project[],
  serverProjects: Project[]
): Project[] => {
  const localProjectsToSync = localProjects.filter(({ id }) => isNaN(+id));

  const updatedProjects: Project[] = [];
  localProjectsToSync.forEach((project) => {
    const serverProject = serverProjects.find(
      (serverProject) => serverProject.id === project.id
    );
    if (!serverProject) {
      return;
    }

    if (
      isAfter(parseISO(project.updatedAt), parseISO(serverProject.updatedAt)) ||
      project.archived
    ) {
      updatedProjects.push(project);
    } else {
      updatedProjects.push(serverProject);
    }
  });

  return updatedProjects;
};

const extractUniqueServerProjects = (
  localProjects: Project[],
  serverProjects: Project[],
  syncProjects: Project[]
): Project[] => {
  const localProjectsToSync = [
    ...localProjects.filter(({ id }) => isNaN(+id)),
    ...syncProjects,
  ];

  return serverProjects.filter(
    ({ id }) =>
      !localProjectsToSync.find((localProject) => localProject.id === id)
  );
};

export const compareProjects = (
  oldProjects: Project[],
  newProjects: Project[]
): boolean => {
  if (oldProjects.length !== newProjects.length) {
    return false;
  }

  return newProjects.every((newProject): boolean => {
    const oldProject = oldProjects.find(
      (oldProject) => newProject.id === oldProject.id
    );
    if (!oldProject) {
      return false;
    }

    return compareProjectProps(oldProject, newProject);
  });
};

const compareProjectProps = (
  oldProject: Project,
  newProject: Project
): boolean => {
  return Object.keys(oldProject).every((key: string) => {
    const projectPropKey = key as keyof Project;
    return oldProject[projectPropKey] === newProject[projectPropKey];
  });
};
