import { createContext, useContext, useState } from 'react';
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StyleProps,
} from '@chakra-ui/react';

type SelectValue = number | string | null;

export interface SelectChangeEvent {
  target: {
    value: SelectValue;
  };
}

const SelectContext = createContext<any>({
  onChange: (event: SelectChangeEvent) => {},
  selectedValue: '',
});

interface SelectProps {
  children: JSX.Element | JSX.Element[];
  placeholder?: SelectValue;
  onChange?: (event: SelectChangeEvent) => void;
  value?: string;
  sx?: StyleProps;
  endIcon?: JSX.Element;
}

const selectStyles = {
  height: 50,
  '.select__button': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    textTransform: 'capitalize',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      gap: 4,
      py: 2,
      px: 4,
    },
  },
  '.select__list': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    backgroundColor: 'primary.darker',
    borderColor: 'primary.darker',
    boxShadow: '0 2px 12px rgb(22 22 22 / 66%)',
    '&__item': {
      textTransform: 'capitalize',
      '&:focus': {
        backgroundColor: 'inherit',
        color: 'inherit',
      },
      '&:hover, &--selected, &--selected:focus': {
        backgroundColor: 'accent.default',
        color: 'typography.text.dark',
      },
      '&--selected:hover': {
        backgroundColor: 'accent.lighter',
      },
    },
  },
};

export const Select = ({
  children,
  placeholder,
  onChange,
  value,
  sx = {},
  endIcon,
}: SelectProps) => {
  const [internalValue, setValue] = useState<SelectValue>(
    value || placeholder || ''
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const internalChangeHandler = (event: SelectChangeEvent) => {
    setValue(event.target.value as SelectValue);
  };

  const handleClose = () => setIsOpen(false);

  const valueToShow = value || internalValue;

  return (
    <Box className="select" sx={{ ...selectStyles, ...sx }}>
      <Menu
        isOpen={isOpen}
        onClose={handleClose}
        onOpen={() => setIsOpen(true)}
        placement="bottom-end"
      >
        <MenuButton
          width="100%"
          height={sx?.height}
          cursor="pointer"
          as={Box}
          className="select__button"
        >
          <Box>
            {typeof valueToShow === 'string'
              ? valueToShow.toLowerCase()
              : valueToShow}
          </Box>
          <Box display="flex" ml="auto">
            {endIcon}
          </Box>
        </MenuButton>
        <MenuList className="select__list">
          <SelectContext.Provider
            value={{
              onChange: onChange || internalChangeHandler,
              selectedValue: value,
            }}
          >
            {children}
          </SelectContext.Provider>
        </MenuList>
      </Menu>
      {isOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000,
          }}
          onClick={handleClose}
        />
      )}
    </Box>
  );
};

interface SelectItemProps {
  value: SelectValue;
  children?: string;
}

export const SelectItem = ({ value, children }: SelectItemProps) => {
  const { onChange, selectedValue } = useContext(SelectContext);

  const onClickHandler = () => {
    onChange({
      target: {
        value,
      },
    });
  };

  return (
    <MenuItem
      className={`select__list__item ${
        selectedValue === value ? 'select__list__item--selected' : ''
      }`}
      onClick={onClickHandler}
    >
      {children || value}
    </MenuItem>
  );
};
