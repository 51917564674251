import {
  FlattenedProject,
  Project,
} from '@prodelio/config/state/projects/Project.interface';

export const flattenProjects = (projects: Project[]): FlattenedProject[] => {
  const sortedProjects = projects.sort((a, b) => a.sort - b.sort);

  return sortedProjects.reduce(
    (
      flattenedProjects: FlattenedProject[],
      { subprojects = [], ...project }
    ) => {
      const mappedSubprojects: FlattenedProject[] = subprojects
        .map((subproject) => ({
          ...subproject,
          parentProject: project.id,
        }))
        .sort((a, b) => a.sort - b.sort);
      const mappedProject = {
        ...project,
        parentProject: null,
      };

      return [...flattenedProjects, mappedProject, ...mappedSubprojects];
    },
    []
  );
};
