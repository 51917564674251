import { HStack, Input, Tag } from '@chakra-ui/react';
import { SelectOption } from '@prodelio/components/form/select/SelectOption';
import { KeyCombination } from '@prodelio/modules/common/models/KeyCombination';
import {
  KeyboardEventHandler,
  Ref,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';

interface SelectMultiInputProps {
  selectedOptions: SelectOption[];
  removeSelectedOption: (option: SelectOption) => void;
  addOption: (option: SelectOption) => void;
  inputRef: Ref<HTMLInputElement>;
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  onWrite: KeyboardEventHandler;
  deleteCounter: number;
  value: string;
}

export const SelectMultiInput = ({
  selectedOptions,
  inputRef,
  onChange,
  onWrite,
  deleteCounter,
  value,
}: SelectMultiInputProps) => {
  const wrapperStyles = useRef({
    border: '2px solid',
    borderRadius: 0,
    bg: 'primary.darker',
    borderColor: 'transparent',
    minHeight: 12,
    paddingX: 4,
    paddingY: 2,
    _hover: {
      bg: 'primary.darker',
      borderColor: 'accent.default',
    },
    _focusVisible: {
      bg: 'primary.darker',
      borderColor: 'accent.default',
    },
    flexWrap: 'wrap',
    gap: 2,
  });

  const inputStyle = useRef({
    width: 'auto',
  });

  const deleteOptionStyles = useRef({
    outline: '2px solid',
    outlineColor: 'accent.default',
    outlineOffset: '2px',
    bg: 'info.default',
  });

  const lastOptionIndex = selectedOptions.length - 1;

  const isLastOptionMarkToDelete = deleteCounter > 0;

  return (
    <HStack spacing={0} sx={wrapperStyles.current}>
      {selectedOptions.map(({ value, label }, i) => (
        <Tag
          size="md"
          key={value as string}
          variant="solid"
          bg="accent.default"
          color="typography.heading.dark"
          sx={
            isLastOptionMarkToDelete && i === lastOptionIndex
              ? deleteOptionStyles.current
              : {}
          }
        >
          {label ?? value}
        </Tag>
      ))}
      <Input
        variant="unstyled"
        className="select__search__input"
        value={value}
        onChange={onChange}
        onKeyDown={onWrite}
        sx={inputStyle.current}
        ref={inputRef}
      />
    </HStack>
  );
};
