import {
  endOfDay,
  isMonday,
  isSunday,
  nextSunday,
  previousMonday,
  startOfDay,
} from 'date-fns';
import { TaskView } from '@prodelio/components/task-view/TaskView';
import { AppViews } from '@prodelio/config/state/app-config/AppConfig';
import { useKanbanDateSections } from '@prodelio/modules/tasks/hooks/useKanbanDateSections';
import { GetCurrentWeekTasksRequest } from '@prodelio/modules/tasks/features/list-current-week-tasks/GetCurrentWeekTasks.request';
import { TaskSortOptions } from '@prodelio/modules/tasks/state/selectors/selectTasks';

export const ListCurrentWeekTasks = () => {
  const now = new Date();
  const startDate = startOfDay(isMonday(now) ? now : previousMonday(now));
  const endDate = endOfDay(isSunday(now) ? now : nextSunday(now));

  const taskFilters = {
    startDate,
    endDate,
  };

  const sort = [TaskSortOptions.MANUAL];

  const request = new GetCurrentWeekTasksRequest();

  const sections = useKanbanDateSections(
    taskFilters,
    sort,
    request,
    AppViews.CURRENT_WEEK
  );

  return <TaskView canChange taskFilters={taskFilters} sections={sections} />;
};
