import { ReactElement } from 'react';
import { TimeTrackerContext } from '@prodelio/context/time-tracker/TimeTracker.context';
import { useTimeTracker } from '@prodelio/hooks/time-tracker/useTimeTracker';

interface TimeTrackerProviderProps {
  children: ReactElement;
}

export const TimeTrackerProvider = ({ children }: TimeTrackerProviderProps) => {
  const timeTracker = useTimeTracker();

  return (
    <TimeTrackerContext.Provider value={timeTracker}>
      {children}
    </TimeTrackerContext.Provider>
  );
};
