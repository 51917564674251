import { StatusEnum } from '@prodelio/modules/tasks/types/Status.enum';
import { TaskView } from '@prodelio/components/task-view/TaskView';
import { useEffect } from 'react';
import { GetInboxTasksRequest } from '@prodelio/modules/tasks/features/list-inbox-tasks/GetInboxTasks.request';
import {
  TaskSortOptions,
  useSelectTasksV2,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';

export const ListInboxTasks = () => {
  const request = new GetInboxTasksRequest();
  const sort = [TaskSortOptions.MANUAL];

  const filters = {
    status: StatusEnum.TODO,
    projectId: null,
    parentTaskId: null,
  };

  const syncTasks = useTaskStore((state) => state.syncTasks);

  const tasks = useSelectTasksV2(filters, sort);

  useEffect(() => {
    syncTasks(request, filters);
  }, []);

  const sections = [
    {
      key: 'ListInboxTasks',
      header: '',
      tasks,
      filters,
      sort,
      displayHeader: false,
    },
  ];

  return <TaskView taskFilters={filters} sections={sections} />;
};
