import { Box, Skeleton } from '@chakra-ui/react';
import { Status } from '@prodelio/hooks/api/useApi';
import { ProjectWithDropdown } from '@prodelio/components/project-with-dropdown/ProjectWithDropdown.component';
import { EditProject } from '@prodelio/modules/projects/features/edit-project/EditProject';
import { DeleteProject } from '@prodelio/modules/projects/features/delete-project/DeleteProject';
import { store } from '@prodelio/config/state/store';
import { ArchiveProject } from '@prodelio/modules/projects/features/archive-project/ArchiveProject';
import { DragMoveEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import { useState } from 'react';
import { createSubproject } from '@prodelio/config/state/projects/actions/createSubProject';
import { useProjects } from '@prodelio/config/state/projects/selectors/getProjects';
import { deleteSubproject } from '@prodelio/config/state/projects/actions/deleteSubproject';
import { SortableList } from '@prodelio/components/sortable-list/SortableList';
import { SortingStrategy } from '@dnd-kit/sortable';
import { sortingStrategy } from '@prodelio/modules/projects/features/list-projects-by-user/utils/sortingStrategy';
import { reorderProjects } from '@prodelio/modules/projects/features/list-projects-by-user/utils/reorderProjects';
import { collisionDetection } from '@prodelio/modules/projects/features/list-projects-by-user/utils/collisionAlgorithm';
import { FlattenedProject } from '@prodelio/config/state/projects/Project.interface';
import { mapIdsWithProjects } from '@prodelio/modules/projects/features/list-projects-by-user/utils/mapIdsWithProjects';

const MIN_OFFSET_X = 30;
const VERTICAL_SPACING = 12;

export const ListProjectsByUser = () => {
  const projects = useProjects();

  const status = store(({ projectsStatus }) => projectsStatus);

  const [activeId, setActiveId] = useState<null | string>(null);
  const [overId, setOverId] = useState<null | string>(null);
  const [willBeSubproject, setWillBeSubproject] = useState<boolean>(false);

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setWillBeSubproject(false);
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id as string);
  };

  const handleDragMove = ({ activatorEvent, over, delta }: DragMoveEvent) => {
    let xPos = 0;
    if (activatorEvent instanceof MouseEvent) {
      xPos = activatorEvent.x;
    } else if (activatorEvent instanceof TouchEvent) {
      const touchElement =
        activatorEvent.touches[0] ?? activatorEvent.changedTouches[0];
      xPos = touchElement.clientX;
    } else {
      setWillBeSubproject(false);
      return;
    }

    const projectCanBeNested =
      projects.findIndex(({ id }) => id === activeId) > 0 ||
      over?.id !== activeId;

    const offsetStartX = xPos - MIN_OFFSET_X + delta.x;
    if (
      offsetStartX >= 50 &&
      over !== null &&
      over.id !== 'start' &&
      projectCanBeNested
    ) {
      setWillBeSubproject(true);
    } else {
      setWillBeSubproject(false);
    }
  };

  const handleDragOver = ({ over }: DragOverEvent) => {
    setOverId((over?.id as string) ?? null);
  };

  const handleDragEnd = (items: string[]) => {
    resetState();
    if (!overId) {
      return;
    }

    const hasSubprojects = projects.filter(
      ({ parentProject }) => parentProject === activeId
    ).length;

    let overIndex = items.findIndex((id) => id === overId);
    const activeIndex = items.findIndex((id) => id === activeId);
    if (overIndex === activeIndex && activeIndex > 0) {
      overIndex = activeIndex - 1;
    }

    const expectedProjectOrder: FlattenedProject[] = mapIdsWithProjects(
      items,
      projects
    );
    reorderProjects(expectedProjectOrder);

    const previousProject = expectedProjectOrder[activeIndex - 1];
    const nextProject = expectedProjectOrder[activeIndex + 1];

    const isPreviousProjectSubproject = !!previousProject?.parentProject;
    const isNextProjectSubproject = !!nextProject?.parentProject;

    const isInvalidDrop =
      hasSubprojects &&
      (willBeSubproject ||
        (!isPreviousProjectSubproject && isNextProjectSubproject) ||
        (isPreviousProjectSubproject && isNextProjectSubproject));

    const isInvalidPosition =
      !willBeSubproject &&
      isPreviousProjectSubproject === false &&
      isNextProjectSubproject;

    if (isInvalidDrop || isInvalidPosition) {
      return;
    }

    if (willBeSubproject && previousProject !== undefined) {
      const parentProjectId =
        expectedProjectOrder[overIndex].parentProject ??
        expectedProjectOrder[overIndex].id;

      createSubproject(parentProjectId, activeId as string);
    } else if (!willBeSubproject) {
      deleteSubproject(activeId as string);
    }
  };

  const canBeSubproject =
    activeId &&
    projects.filter(({ parentProject }) => parentProject === activeId).length >
      0
      ? false
      : true;
  const strategy: SortingStrategy = sortingStrategy(
    projects,
    activeId,
    overId,
    canBeSubproject && willBeSubproject,
    VERTICAL_SPACING
  );

  const renderBody = () => (
    <SortableList
      items={projects.map(
        ({ id, title, parentProject, sharedWith, userId }) => {
          const moveGroup = projects.filter(
            (project) => project.parentProject === id
          ).length;

          let indentation = 0;
          if (activeId === id) {
            indentation = willBeSubproject && canBeSubproject ? 20 : 0;
          } else {
            indentation = parentProject ? 20 : 0;
          }

          return {
            id,
            indentation,
            moveGroup,
            parent: parentProject,
            display: (!activeId || activeId !== parentProject) as boolean,
            props: {
              isDraggable: true,
              id,
              title,
              actions: [
                <EditProject
                  key={id}
                  id={id}
                  title={title}
                  sharedWith={sharedWith}
                  userId={userId}
                />,
                <ArchiveProject key={id} id={id} />,
                <DeleteProject key={id} id={id} />,
              ],
            },
          };
        }
      )}
      Component={ProjectWithDropdown}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      strategy={strategy}
      collisionDetection={collisionDetection(
        canBeSubproject && willBeSubproject
      )}
    />
  );

  const renderSkeleton = () => (
    <>
      <Skeleton width="100px" height="30px" />
      <Skeleton width="100px" height="30px" />
      <Skeleton width="100px" height="30px" />
      <Skeleton width="100px" height="30px" />
      <Skeleton width="100px" height="30px" />
    </>
  );

  const isLoading =
    [Status.WAITING, Status.LOADING].includes(status) && !projects.length;

  return <Box>{isLoading ? renderSkeleton() : renderBody()}</Box>;
};
