import { create } from 'zustand';
import {
  SetStore,
  TaskState,
  TaskStore,
} from '@prodelio/modules/tasks/state/TaskStore';
import { createTask } from '@prodelio/modules/tasks/state/actions/createTask';
import { Request } from '@prodelio/hooks/api/Request.class';
import { syncTasks } from '@prodelio/modules/tasks/state/actions/syncTasks';
import { editTask } from '@prodelio/modules/tasks/state/actions/editTask';
import { deleteTask } from '@prodelio/modules/tasks/state/actions/deleteTask';
import { createJSONStorage, persist } from 'zustand/middleware';
import { TaskFilters } from '@prodelio/modules/tasks/state/selectors/selectTasks';
import { Status } from '@prodelio/hooks/api/useApi';
import { ObjectId } from 'bson';
import localforage from 'localforage';

export const useTaskStore = create<TaskStore>()(
  persist(
    (set) => {
      const setCallback = set as SetStore<TaskState>;

      return {
        tasks: [],
        statusTask: Status.WAITING,
        create: (request: Request) =>
          createTask(set as SetStore<TaskState>, request),
        edit: (request: Request | Request[]) => editTask(setCallback, request),
        delete: (request: Request) => deleteTask(setCallback, request),
        syncTasks: (request: Request, filters: Partial<TaskFilters>) =>
          syncTasks(set as SetStore<TaskStore>, request, filters),
      };
    },
    {
      name: 'prodelio-task-storage',
      storage: createJSONStorage(() => localforage, {
        reviver(key, value) {
          if (key === 'boardId' && typeof value === 'string') {
            return new ObjectId(value);
          }

          if (key === 'statusTask') {
            return Status.DONE;
          }

          return value;
        },
      }),
    }
  )
);
