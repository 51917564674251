import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect } from 'react';
import { ResendEmailFeature } from '@prodelio/modules/auth/features/resend-email/ResendEmail.feature';

export const ResendEmailPage = () => {
  const { setTitle } = useRouteProps();

  useEffect(() => {
    setTitle('Login');
  }, []);

  return <ResendEmailFeature />;
};
