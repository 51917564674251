import { TaskViewSection } from '@prodelio/components/task-view/TaskView';
import { Request } from '@prodelio/hooks/api/Request.class';
import { eachDayOfInterval, endOfDay, format, startOfDay } from 'date-fns';
import { useEffect } from 'react';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import {
  TaskSortOptions,
  useSelectTasksV2,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';

interface IDateRange {
  startDate: Date;
  endDate: Date;
}

export const useKanbanDateSections = (
  dateRange: IDateRange,
  sort: TaskSortOptions[],
  request: Request,
  key: string
): TaskViewSection[] => {
  const syncTasks = useTaskStore((state) => state.syncTasks);
  const tasks = useSelectTasksV2(
    { endDate: endOfDay(dateRange.endDate) },
    sort
  );

  const groupedTasksByDate = tasks.reduce((groupedTasks, task) => {
    const taskDueDate = startOfDay(new Date(task.dueDate));

    const key =
      taskDueDate.getTime() < startOfDay(dateRange.startDate).getTime()
        ? 'overdue'
        : taskDueDate.getTime();

    const tasksInDate = groupedTasks.get(key) ?? [];
    tasksInDate.push(task);

    groupedTasks.set(key, tasksInDate);

    return groupedTasks;
  }, new Map());

  useEffect(() => {
    syncTasks(request, dateRange);
  }, []);

  const dateInterval = eachDayOfInterval({
    start: dateRange.startDate,
    end: dateRange.endDate,
  });

  const sections: TaskViewSection[] = dateInterval.map((day) => {
    const filters = {
      date: endOfDay(day),
    };

    const sectionId = startOfDay(day).getTime();

    return {
      key: String(sectionId),
      header: format(day, 'EEEE'),
      tasks: groupedTasksByDate.get(sectionId) ?? [],
      filters,
      sort,
    };
  });

  const filterOverdue = {
    endDate: dateRange.startDate,
  };

  const overdueTasks = groupedTasksByDate.get('overdue') ?? [];
  if (overdueTasks.length) {
    sections.unshift({
      key: key + 'OVERDUE',
      header: 'Overdue',
      tasks: overdueTasks,
      filters: filterOverdue,
      sort: sort,
      droppable: false,
      quickCreateTasks: false,
    });
  }

  return sections;
};
