import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect } from 'react';
import { startOfToday } from 'date-fns';
import { ListTodayTasks } from '@prodelio/modules/tasks/features/list-today-tasks/ListTodayTasks';

export const TodayPage = () => {
  const { setTitle, setState } = useRouteProps();

  useEffect(() => {
    setTitle('Today');
    setState({
      date: startOfToday(),
      tags: 'next_actions',
    });
  }, []);

  return (
    <>
      <ListTodayTasks />
    </>
  );
};
