import { FlattenedProject } from '@prodelio/config/state/projects/Project.interface';
import { getProject } from '@prodelio/config/state/projects/selectors/getProject';
import { getDefaultDraggableScale } from '@prodelio/components/sortable-list/utils/getDefaultDraggableScale';
import { getItemGap } from '@prodelio/components/sortable-list/utils/getItemGap';
import { CustomSortingStrategy } from '@prodelio/components/sortable-list/strategies/CustomSortingStrategy.type';

export const bottomVerticalStrategy: CustomSortingStrategy<FlattenedProject> = (
  activeId,
  overId
) => {
  return ({
    activeIndex,
    activeNodeRect: fallbackActiveRect,
    index,
    rects,
    overIndex,
  }) => {
    const result = getDefaultDraggableScale();
    const activeNodeRect = rects[activeIndex] ?? fallbackActiveRect;

    if (!activeNodeRect || !activeId || !overId || overIndex === activeIndex) {
      return null;
    }

    let overProject = getProject(overId);
    if (overProject && overProject.parentProject) {
      overProject = getProject(overProject?.parentProject ?? '');
    }
    const itemGap = getItemGap(rects, index, activeIndex);

    if (index === activeIndex) {
      const overIndexRect = rects[overIndex];

      if (!overIndexRect) {
        return null;
      }

      let yPosition =
        overIndexRect.top + overIndexRect.height + itemGap - activeNodeRect.top;

      // Position going down
      if (activeIndex < overIndex) {
        const bottomOverIndex = overIndexRect.top + overIndexRect.height;
        const bottomActiveIndex = activeNodeRect.top + activeNodeRect.height;

        yPosition = bottomOverIndex - bottomActiveIndex;
      }

      result.y = yPosition;
    }

    // Above static active index
    if (index > activeIndex && index <= overIndex) {
      result.y = -activeNodeRect.height - itemGap;
    }

    if (index < activeIndex && index > overIndex) {
      result.y = activeNodeRect.height + itemGap;
    }

    return result;
  };
};
