import { DeleteSubprojectRequest } from '@prodelio/modules/projects/features/delete-project/request/DeleteSubproject.request';
import { apiWrapper } from '@prodelio/config/api/api';
import { store } from '@prodelio/config/state/store';
import { getProject } from '@prodelio/config/state/projects/selectors/getProject';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const deleteSubproject = (subprojectId: string) => {
  const state = store.getState();
  const subproject = getProject(subprojectId);
  if (!subproject) {
    return;
  }

  const newState = {
    ...state,
    projects: state.projects.map((project) => {
      if (project.id !== subprojectId) {
        return project;
      }

      return {
        ...project,
        parentProject: null,
      };
    }),
  };

  const deleteSubprojectRequest = new DeleteSubprojectRequest(subprojectId);
  apiWrapper(deleteSubprojectRequest).catch(() => {
    const syncProjects = state.syncProjects;

    console.warn('Error: could not delete subproject');

    store.setState({
      ...newState,
      syncProjects: [
        ...syncProjects,
        {
          ...subproject,
          syncStatus: SyncStatus.CREATED,
        },
      ],
    });
  });

  store.setState(newState);
};
