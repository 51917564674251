import { Box, VStack } from '@chakra-ui/react';
import '@prodelio/components/sidebar/Sidebar.component.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const SidebarComponent = ({ children }: Props) => {
  return (
    <Box className="sidebar" py={6} pb="70px">
      <VStack className="sidebar__content" spacing={'50px'} alignItems="left">
        {children}
      </VStack>
    </Box>
  );
};
