type taskStore = 'tasks' | 'syncTasks';
type projectStore = 'projects' | 'syncProjects';

export type cleanStoreProp = taskStore | projectStore;

export const cleanStore = (set: any, get: any, prop: cleanStoreProp) => {
  set({
    [prop]: [],
  });
};

export type CleanStoreType = (prop: cleanStoreProp) => void;
