import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FormEvent } from 'react';
import { useLoginValidation } from '@prodelio/modules/auth/features/forgot-password/useLoginValidation.hook';
import { ForgotPasswordRequest } from '@prodelio/modules/auth/features/forgot-password/requests/ForgotPassword.request';
import { useForm } from '@prodelio/hooks/form/useForm';
import { useApi } from '@prodelio/hooks/api/useApi';

export const ForgoPasswordComponent = () => {
  const toast = useToast();

  const { formValues, onChange, resetForm } = useForm({
    email: {
      value: '',
    },
  });
  const { formErrors, validate } = useLoginValidation();

  const transformFormValues = () => {
    return {
      email: formValues.email.value,
    };
  };

  const forgotPasswordRequest = new ForgotPasswordRequest(
    transformFormValues()
  );
  const { fetch } = useApi(forgotPasswordRequest, false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validate(formValues)) {
      return;
    }

    try {
      await fetch();
      resetForm();
      toast({
        status: 'success',
        duration: 5000,
        title: 'Reset link has been sent.',
        isClosable: true,
        position: 'top-left',
      });
    } catch (e: any) {
      toast({
        status: 'error',
        duration: 5000,
        title: 'Error sending password link.',
        description: e.error,
        isClosable: true,
        position: 'top-left',
      });
    }
  };

  return (
    <>
      <Heading as="h2" size="md" textAlign="center">
        Fill your email to send you a reset link
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={5} mt={8} align="left" mx="auto">
          <FormControl isInvalid={!!formErrors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={formValues.email.value}
              onChange={onChange}
            />
            {formErrors.email && (
              <FormErrorMessage>{formErrors.email}</FormErrorMessage>
            )}
          </FormControl>
          <HStack pt={2} justifyContent="center">
            <Button width={200} py={'13px'} variant="primary" type="submit">
              Send reset link
            </Button>
          </HStack>
          <VStack alignItems="center">
            <Button variant="link" as={Link} to="/login" size="sm">
              Sign In
            </Button>
            <Button variant="link" as={Link} to="/register" size="sm">
              Sign Up
            </Button>
          </VStack>
        </VStack>
      </form>
    </>
  );
};
