import { apiWrapper } from '@prodelio/config/api/api';
import {
  FlattenedProject,
  Project,
  SyncProject,
} from '@prodelio/config/state/projects/Project.interface';
import { store } from '@prodelio/config/state/store';
import { ReorderProjectsRequest } from '@prodelio/modules/projects/features/list-projects-by-user/requests/ReorderProjects.request';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';

export const reorderProjects = (projects: FlattenedProject[]) => {
  const state = store.getState();

  const newState = {
    ...state,
    projects: projects as Project[],
  };

  const reorderRequest = new ReorderProjectsRequest(
    projects
      .filter((project) => isNaN(Number(project.id)))
      .map(({ id }, index) => ({ id, sort: index + 1 }))
  );

  apiWrapper(reorderRequest).catch(() => {
    const syncProjects: SyncProject[] = state.syncProjects;

    console.warn('Error: could not reorder project');

    store.setState({
      ...newState,
      syncProjects: [
        ...syncProjects,
        ...reorderRequest.body.map((project: Project) => ({
          ...project,
          syncStatus: SyncStatus.EDITED,
        })),
      ] as SyncProject[],
    });
  });

  store.setState(newState);
};
