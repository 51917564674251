import { TimeTrackerRequest } from '@prodelio/modules/tasks/time-tracker/request/TimeTracker.request';
import { useApi } from '@prodelio/hooks/api/useApi';
import { useToast } from '@chakra-ui/react';
import { TimeTrackerConstants } from '@prodelio/hooks/time-tracker/TimeTracker.constants';
import { useTimeTrackerContext } from '@prodelio/hooks/time-tracker/useTimeTrackerContext';

export const TrackTimeInterval = (id: string) => {
  const { onPlay } = useTimeTrackerContext();

  const timeTrackerRequest = new TimeTrackerRequest();
  const { fetch } = useApi(timeTrackerRequest, false);
  const toast = useToast();

  const trackTimeTask = async () => {
    try {
      timeTrackerRequest.init(id);
      await fetch();
    } catch (e: any) {
      toast({
        status: 'error',
        duration: 5000,
        title: 'Error when tracking the task time.',
        description: e.error,
        isClosable: true,
        position: 'top-left',
      });
    }
  };

  const minuteTrack = () => {
    trackTimeTask();
  };

  return () => {
    onPlay({
      target: {
        taskId: id,
        minuteInterval: setInterval(minuteTrack, TimeTrackerConstants.MINUTE),
      },
    });
  };
};
