import {
  hoursToMilliseconds,
  millisecondsToHours,
  millisecondsToMinutes,
  millisecondsToSeconds,
  minutesToMilliseconds,
} from 'date-fns';

interface Format {
  units: string[];
  separator: string;
}

interface MsToTimeParserFormatsInterface {
  UNIT: Format;
  CLOCK: Format;
}

type MsToTimeParserFormatType = keyof MsToTimeParserFormatsInterface;

interface ValuesToDisplay {
  hours: boolean;
  minutes: boolean;
  seconds: boolean;
}

const MsToTimeParserFormats: MsToTimeParserFormatsInterface = {
  UNIT: {
    units: ['h', 'm', 's'],
    separator: ' ',
  },
  CLOCK: {
    units: ['', '', ''],
    separator: ':',
  },
};

const DefaultValuesToDisplay: ValuesToDisplay = {
  hours: true,
  minutes: true,
  seconds: true,
};

export const MsToTimeParser = (
  time: number,
  format: MsToTimeParserFormatType = 'UNIT',
  valuesToDisplay: ValuesToDisplay = DefaultValuesToDisplay
): string => {
  const { units, separator } = MsToTimeParserFormats[format];

  const hours = millisecondsToHours(time);
  const minutes = millisecondsToMinutes(time - hoursToMilliseconds(hours));
  const seconds = millisecondsToSeconds(
    time - hoursToMilliseconds(hours) - minutesToMilliseconds(minutes)
  );

  const formattedTimeArr = [hours, minutes, seconds]
    .reduce((res: Array<string | null>, time: number, index: number) => {
      if (time === 0 && index === 0) {
        return [...res, null];
      }

      const timeString =
        index > 0 ? String(time).padStart(2, '0') : String(time);

      return [...res, `${timeString}${units[index]}`];
    }, [])
    .filter((value: string | null) => value);

  return formattedTimeArr.length ? formattedTimeArr.join(separator) : '00';
};
