import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, HStack } from '@chakra-ui/react';
import { EditTask } from '@prodelio/modules/tasks/features/edit-task/EditTask';
import { Breadcrumbs } from '@prodelio/components/breadcrumbs/Breadcrumbs.component';
import { getTask } from '@prodelio/modules/tasks/state/selectors/getTask';

export const DetailPage = () => {
  const { setTitle, setState } = useRouteProps();
  const { taskId } = useParams();

  const task = getTask(taskId ?? '');

  useEffect(() => {
    setTitle('Task Detail');
    setState({
      date: null,
      tags: 'next_actions',
    });
  }, []);

  return (
    <>
      <Box className="task task--detail">
        <HStack className="task__header" alignItems="center" spacing="20px">
          <Heading as="h3">{task?.title}</Heading>
          <Breadcrumbs />
        </HStack>
      </Box>

      <EditTask id={taskId ?? ''} />
    </>
  );
};
