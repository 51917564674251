import { ValidatorOptions } from '@prodelio/hooks/form/useValidation';

export const ValidatorFunctions: { [key: string]: any } = {
  length: (field: string, value: string, initOptions: ValidatorOptions) => {
    const options = {
      min: 3,
      max: 80,
      ...initOptions,
    };

    if (value.length < options.min) {
      return `${field} length should be greater than ${options.min}`;
    } else if (value.length > options.max) {
      return `${field} length should be greater than ${options.min}`;
    }
  },
  empty: (field: string, value: any, options: ValidatorOptions) => {
    if (!value || value.trim().length === 0) {
      return `${field} should not be empty`;
    }
  },
  quantity: (field: string, value: number, initOptions: ValidatorOptions) => {
    const options = {
      min: 2,
      max: 80,
      ...initOptions,
    };

    if (value < options.min) {
      return `${field} should be greater than ${options.min}`;
    } else if (value > options.max) {
      return `${field} should be greater than ${options.min}`;
    }
  },
  same: (field: string, value: number, options: ValidatorOptions) => {
    if (value !== options.comparison!.value) {
      return `${field} should be equal to ${options.comparison!.field}`;
    }
  },
  password: (field: string, value: string, initOptions: ValidatorOptions) => {
    const options = {
      min: 8,
      max: 80,
      ...initOptions,
    };

    if (value.length < options.min) {
      return `${field} length should be greater than ${options.min}`;
    } else if (value.length > options.max) {
      return `${field} length should be greater than ${options.min}`;
    }
  },
  pattern: (field: string, value: string, initOptions: ValidatorOptions) => {
    const options = {
      ...initOptions,
    };

    const isValueEmpty = options.isPatternEmpty && !value.length;
    if (!isValueEmpty && !options.pattern!.test(value)) {
      return `${field} does not match pattern: 0h 0m 0s`;
    }
  },
};
