import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { RegisterRequest } from '@prodelio/modules/auth/features/register/Register.request';
import { FormEvent } from 'react';
import { useRegisterValidation } from '@prodelio/modules/auth/features/register/useRegisterValidation.hook';
import { useForm } from '@prodelio/hooks/form/useForm';
import { useApi } from '@prodelio/hooks/api/useApi';

export const RegisterComponent = () => {
  const request = new RegisterRequest();
  const { fetch } = useApi(request, false);
  const toast = useToast();
  const navigate = useNavigate();
  const { formValues, onChange } = useForm({
    fullName: {
      value: '',
    },
    username: {
      value: '',
    },
    email: {
      value: '',
    },
    password: {
      value: '',
    },
    repeatPassword: {
      value: '',
    },
  });
  const { formErrors, validate } = useRegisterValidation(formValues);

  const transformFormValues = () => {
    return {
      email: formValues.email.value,
      name: formValues.fullName.value,
      username: formValues.username.value,
      password: formValues.password.value,
      repeatPassword: formValues.repeatPassword.value,
    };
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validate(formValues)) {
      return;
    }

    try {
      request.body = transformFormValues();
      await fetch();
      navigate('/login', {
        state: {
          toastOptions: {
            status: 'success',
            duration: 5000,
            title: 'User signed up successfully.',
            isClosable: true,
            position: 'top-left',
          },
        },
      });
    } catch (e: any) {
      toast({
        status: 'error',
        duration: 5000,
        title: 'Error signing up.',
        description: e.error,
        isClosable: true,
        position: 'top-left',
      });
    }
  };

  return (
    <>
      <Heading as="h2" size="md" textAlign="center">
        Sign Up and Be Productive
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={5} mt={8} align="left" mx="auto">
          <FormControl isInvalid={!!formErrors.fullName}>
            <FormLabel>Full Name</FormLabel>
            <Input
              type="text"
              name="fullName"
              value={formValues.fullName.value}
              onChange={onChange}
            />
            {formErrors.fullName && (
              <FormErrorMessage>{formErrors.fullName}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!formErrors.username}>
            <FormLabel>Username</FormLabel>
            <Input
              type="text"
              name="username"
              value={formValues.username.value}
              onChange={onChange}
            />
            {formErrors.username && (
              <FormErrorMessage>{formErrors.username}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!formErrors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={formValues.email.value}
              onChange={onChange}
            />
            {formErrors.email && (
              <FormErrorMessage>{formErrors.email}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!formErrors.password}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={formValues.password.value}
              onChange={onChange}
            />
            {formErrors.password && (
              <FormErrorMessage>{formErrors.password}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!formErrors.repeatPassword}>
            <FormLabel>Repeat Password</FormLabel>
            <Input
              type="password"
              name="repeatPassword"
              value={formValues.repeatPassword.value}
              onChange={onChange}
            />
            {formErrors.repeatPassword && (
              <FormErrorMessage>{formErrors.repeatPassword}</FormErrorMessage>
            )}
          </FormControl>
          <HStack pt={2} justifyContent="center">
            <Button width={200} py={'13px'} variant="primary" type="submit">
              Sign Up
            </Button>
          </HStack>
          <HStack justifyContent="center">
            <Button variant="link" as={Link} to="/login" size="sm">
              Already have an account? Sign In
            </Button>
          </HStack>
        </VStack>
      </form>
    </>
  );
};
