import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { Request } from '@prodelio/hooks/api/Request.class';

export class VerifyEmailRequest extends Request {
  public url = '/mail/verification';
  public method: ApiMethods = ApiMethods.POST;

  constructor(token: string) {
    super({
      token: token,
    });
  }
}
