import { apiWrapper } from '@prodelio/config/api/api';
import { toastError } from '@prodelio/config/toast/toast';
import { Request } from '@prodelio/hooks/api/Request.class';
import { Board } from '@prodelio/modules/projects/state/Board';
import { BoardStore } from '@prodelio/modules/projects/state/BoardStore';
import { SyncStatus } from '@prodelio/modules/tasks/state/types/Task';
import { StoreApi } from 'zustand';

export const createBoard = (
  set: StoreApi<BoardStore>['setState'],
  request: Request,
  data: Board
): void => {
  apiWrapper(request).catch((e: Error) => {
    toastError('Board could not be created', e.message);
    set((state: BoardStore) => ({
      boards: state.boards.map((board: Board) => {
        if (board._id.equals(data._id)) {
          return {
            ...board,
            syncStatus: SyncStatus.CREATED,
          };
        }

        return board;
      }),
    }));
  });

  set((state: BoardStore) => ({
    ...state,
    boards: [...state.boards, data],
  }));
};
