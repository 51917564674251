import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { api } from '@prodelio/config/api/api';
import { AuthResponse } from '@prodelio/config/api/response/Auth.response';
import { useAuth } from '@prodelio/hooks/auth/useAuth';

export const AuthInterceptor = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  useEffect(() => {
    let displayError = true;

    const authError = (err: AxiosError) => {
      const { response } = err;
      if (!response || !displayError) {
        return Promise.reject(err);
      }

      if (response.status === 401) {
        displayError = false;
        const data: AuthResponse = response.data as AuthResponse;

        setAuth(null);
        localStorage.removeItem('catd-user');
        toast({
          status: 'error',
          duration: 5000,
          title: data.message,
          description: "You're not logged in.",
          isClosable: true,
          position: 'top-left',
        });
        navigate('/login');
      }

      return Promise.reject(err);
    };

    const interceptor = api.interceptors.response.use((res) => res, authError);

    return () => api.interceptors.response.eject(interceptor);
  }, []);

  return null;
};
