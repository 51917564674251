import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { Request } from '@prodelio/hooks/api/Request.class';
import { Board } from '@prodelio/modules/projects/state/Board';

export class EditBoardRequest extends Request {
  public method: ApiMethods = ApiMethods.PUT;
  public url = '/projects/:projectId/boards/:boardId';

  constructor(data: Board) {
    super();
    this.url = this.url
      .replace(':projectId', data.projectId)
      .replace(':boardId', data._id.toString());
    this.body = data;
  }
}
