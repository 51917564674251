import { Box } from '@chakra-ui/react';

import '@prodelio/components/navbar/desktop/DesktopNavbar.component.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const DesktopNavbarComponent = ({ children }: Props) => {
  return <Box className="desktop-navbar">{children}</Box>;
};
