import { Box, ToastId, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ReactNode, useEffect, useRef } from 'react';

type SnackbarOnClose = () => void;

interface SnackbarProps {
  children: ReactNode;
  open?: boolean;
  onClose?: SnackbarOnClose;
}

export const Snackbar = ({
  children,
  open = false,
  onClose,
}: SnackbarProps) => {
  const toast = useToast();
  const toastRef = useRef<ToastId | null>(null);

  const styles = {
    position: 'relative',
    bg: 'info.default',
    padding: '20px 30px 20px 25px',
    borderRadius: '5px',
    color: 'var(--chakra-colors-typography-heading-dark)',
    '& .snackbar__close': {
      width: '20px',
      height: '20px',
      position: 'absolute',
      top: '12px',
      right: '12px',
      cursor: 'pointer',
      color: 'var(--chakra-colors-typography-text-dark)',
      '&:hover': {
        color: 'var(--chakra-colors-typography-heading-dark)',
      },
      '&:active': {
        color: 'black',
      },
    },
  };

  const handleCloseSnackbar = () => {
    if (toastRef.current) {
      toast.close(toastRef.current);
      onClose && onClose();
    }
  };

  const snackbar = (
    <Box className="snackbar" sx={styles}>
      <Box>{children}</Box>
      <Box className="snackbar__close" onClick={handleCloseSnackbar}>
        <FontAwesomeIcon size="lg" icon={faXmark} />
      </Box>
    </Box>
  );

  useEffect(() => {
    if (open && !toastRef.current) {
      toastRef.current = toast({
        position: 'bottom-right',
        render: () => snackbar,
        duration: null,
      });
    } else if (!open && toastRef.current) {
      toast.close(toastRef.current);
    }

    return () => {
      if (toastRef.current) {
        toast.close(toastRef.current);
      }
    };
  }, [open]);

  return null;
};
