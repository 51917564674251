import { selectAnatomy as parts } from '@chakra-ui/anatomy';

const baseStyles = {
  borderRadius: 0,
  borderWidth: '2px',
  borderStyle: 'solid',
};

export const Select = {
  parts: parts.keys,
  baseStyles: {
    field: baseStyles,
  },
  variants: {
    filled: {
      field: {
        bg: 'primary.darker',
        borderColor: 'transparent',
        _hover: {
          bg: 'primary.darker',
          borderColor: 'accent.default',
        },
        _focusVisible: {
          bg: 'primary.darker',
          borderColor: 'accent.default',
        },
      },
    },
    filledLighter: {
      field: {
        bg: 'primary.lighter',
        borderColor: 'transparent',
        _hover: {
          bg: 'primary.lighter',
          borderColor: 'accent.default',
        },
        _focusVisible: {
          bg: 'primary.lighter',
          borderColor: 'accent.default',
        },
        '> option, > optgroup': {
          bg: 'primary.lighter',
        },
      },
    },
  },
  sizes: {
    lg: {
      field: baseStyles,
    },
  },
  defaultProps: {
    variant: 'filled',
    size: 'lg',
  },
};
