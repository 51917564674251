import { Box, Heading, Card, CardBody } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect } from 'react';

export const AuthLayout = () => {
  const { title } = useRouteProps();

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Box mx="auto" maxW="720px" my="67px">
      <Heading mb={25} textAlign="center">
        Prodelio
      </Heading>
      <Card>
        <CardBody>
          <Outlet />
        </CardBody>
      </Card>
    </Box>
  );
};
