import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect, useState } from 'react';
import { ListProjectsByUser } from '@prodelio/modules/projects/features/list-projects-by-user/ListProjectsByUser';
import { CreateProject } from '@prodelio/modules/projects/features/create-project/CreateProject';

import { GetProjectsByUserRequest } from '@prodelio/modules/projects/features/list-projects-by-user/requests/GetProjectsByUser.request';
import { store } from '@prodelio/config/state/store';
import { useSyncProjects } from '@prodelio/config/state/projects/syncProjects';
import { ViewSelector } from '@prodelio/components/view-selector/ViewSelector';
import { ModalComponent } from '@prodelio/components/modal/Modal.component';
import { EditTask } from '@prodelio/modules/tasks/features/edit-task/EditTask';
import { useEvent } from '@prodelio/event-system/hooks/useEvent';
import { TaskEvents } from '@prodelio/modules/tasks/Task.events';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AppLayout } from '@prodelio/layouts/app/App.layout';
import { Snackbar } from '@prodelio/components/snackbar/Snackbar';
import { useAuth } from '@prodelio/hooks/auth/useAuth';
import { ProjectEvents } from '@prodelio/modules/projects/Project.events';
import { TaskSyncronizer } from '@prodelio/modules/tasks/services/task-synchronizer/TaskSynchronizer';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import { CreateBoard } from '@prodelio/modules/projects/features/create-board/CreateBoard';
import { Event } from '@prodelio/modules/common/models/Event';

export const MainLayout = () => {
  const navigate = useNavigate();

  const { setState } = useRouteProps();
  const [openTaskId, setOpenTaskId] = useState<null | string>(null);

  const { user } = useAuth();

  const taskSynchronizer = new TaskSyncronizer();
  const syncProjects = useSyncProjects();

  const getProjectsRequest = new GetProjectsByUserRequest();
  const fetchProjects = store((state) => state.fetchProjects);

  useEvent(TaskEvents.OPEN_EDIT_TASK_MODAL, ({ data }: Event) => {
    if (!data) {
      return;
    }
    const { id } = data;

    setOpenTaskId(id);
    setState({ modalTaskId: id });
  });

  const handleCloseModal = () => {
    setOpenTaskId(null);
    setState({ modalTaskId: null });
  };

  useEvent(
    [TaskEvents.CLOSE_EDIT_TASK_MODAL, TaskEvents.DELETED_TASK],
    handleCloseModal
  );

  useEvent(ProjectEvents.RELOAD_PROJECTS, () => {
    fetchProjects(getProjectsRequest);
  });

  useEffect(() => {
    fetchProjects(getProjectsRequest);
    taskSynchronizer.syncTasks(useTaskStore.getState().tasks);
    syncProjects();
  }, []);

  const handleExpand = () => {
    navigate('/app/tasks/' + openTaskId);
    setOpenTaskId(null);
    setState({ modalTaskId: null });
  };

  const SidebarContent = (
    <>
      <Box w="100%">
        <Heading as="h3" mb="12px" size="md">
          Views
        </Heading>
        <VStack alignItems="left" spacing="12px">
          <Button variant="navlink" as={NavLink} to="/app/inbox">
            Inbox
          </Button>
          <Button variant="navlink" as={NavLink} to="/app/today">
            Today
          </Button>
          <Button variant="navlink" as={NavLink} to="/app/tomorrow">
            Tomorrow
          </Button>
          <Button variant="navlink" as={NavLink} to="/app/week/current">
            Current Week
          </Button>
          <Button variant="navlink" as={NavLink} to="/app/next/7/days">
            Next 7 Days
          </Button>
          <Button variant="navlink" as={NavLink} to="/app/next/14/days">
            Next 14 Days
          </Button>
        </VStack>
      </Box>
      <Box w="100%">
        <Heading as="h3" mb="12px" size="md">
          Planning
        </Heading>
        <VStack alignItems="left" spacing="12px">
          <Button
            variant="navlink"
            as={NavLink}
            to="/app/planning/next-actions"
          >
            Next Actions
          </Button>
          <Button variant="navlink" as={NavLink} to="/app/planning/maybe">
            Maybe
          </Button>
        </VStack>
      </Box>
      <Box w="100%">
        <HStack mb="12px" justifyContent="space-between" alignItems="center">
          <Heading as="h3" size="md">
            Projects
          </Heading>
          <CreateProject />
        </HStack>
        <ListProjectsByUser />
      </Box>
    </>
  );

  const headerComponents = (
    <HStack>
      <CreateBoard />
      <ViewSelector />
    </HStack>
  );

  return (
    <AppLayout
      sidebarLinks={SidebarContent}
      headerComponents={headerComponents}
    >
      <Outlet />
      <Snackbar open={!!user && !user.confirmed}>
        You have to verify your email address within 14 days or your account
        will be disabled
      </Snackbar>
      <ModalComponent
        onClose={handleCloseModal}
        title="Edit task"
        open={!!openTaskId}
      >
        <IconButton
          variant="icon"
          size="sm"
          className="modal__expand"
          aria-label="Expand"
          onClick={handleExpand}
        >
          <FontAwesomeIcon icon={solid('expand')} />
        </IconButton>
        <EditTask id={openTaskId ?? ''} />
      </ModalComponent>
    </AppLayout>
  );
};
