import { useValidation } from '@prodelio/hooks/form/useValidation';

export const useLoginValidation = () => {
  return useValidation({
    email: {
      validators: ['empty'],
    },
    password: {
      validators: ['empty'],
    },
  });
};
