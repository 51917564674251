import { useRouteProps } from '@prodelio/hooks/route-props/useRouteProps';
import { useEffect, useRef } from 'react';
import { StatusEnum } from '@prodelio/modules/tasks/types/Status.enum';
import { ListTasksByTagsRequest } from '@prodelio/modules/tasks/requests/ListTasksByTags.request';
import {
  GroupedByType,
  GroupedListTask,
} from '@prodelio/components/grouped-list-task/GroupedListTask';

export const NextActionsPage = () => {
  const { setTitle, setState } = useRouteProps();

  const filters = useRef({
    status: StatusEnum.TODO,
    tags: ['next_actions'],
    parentTaskId: null,
  });

  const request = new ListTasksByTagsRequest(['next_actions']);

  useEffect(() => {
    setTitle('Next Actions');
    setState({
      date: null,
      tags: 'next_actions',
    });
  }, []);

  return (
    <>
      <GroupedListTask
        TaskListProps={{ filters: filters.current }}
        filters={filters.current}
        groupedBy={GroupedByType.PROJECT}
        request={request}
      />
    </>
  );
};
