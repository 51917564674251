import { GetTomorrowTasksRequest } from '@prodelio/modules/tasks/features/list-tomorrow-tasks/GetTomorrowTasks.request';
import { startOfTomorrow } from 'date-fns';
import { TaskView } from '@prodelio/components/task-view/TaskView';
import { useEffect } from 'react';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import {
  TaskSortOptions,
  useSelectTasksV2,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';

export const ListTomorrowTasks = () => {
  const request = new GetTomorrowTasksRequest();
  const syncTasks = useTaskStore((state) => state.syncTasks);

  const filters = {
    date: startOfTomorrow(),
  };

  useEffect(() => {
    syncTasks(request, filters);
  }, []);

  const sort = [TaskSortOptions.MANUAL];

  const tasks = useSelectTasksV2(filters, sort);

  const sections = [
    {
      key: 'ListTomorrowTasks',
      header: '',
      tasks,
      filters,
      sort,
      displayHeader: false,
    },
  ];

  return <TaskView taskFilters={filters} sections={sections} />;
};
