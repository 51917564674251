import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '@prodelio/hooks/auth/useAuth';
import React, { useEffect } from 'react';
import { AuthInterceptor } from '@prodelio/config/api/Auth.interceptor';
import { AudioNotifier } from '@prodelio/modules/common/notifications/AudioNotifier';
import { useEvent } from '@prodelio/event-system/hooks/useEvent';
import { Event } from '@prodelio/modules/common/models/Event';
import { TaskEvents } from '@prodelio/modules/tasks/Task.events';

interface Props {
  defaultRedirect: string;
}

const audioNotifier = new AudioNotifier();

export const ProtectedRoutes = ({ defaultRedirect }: Props) => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();

  useEffect(() => {
    checkAuth().then((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        navigate(defaultRedirect);
      }
    });
  }, []);

  useEvent([TaskEvents.TASK_COMPLETED], (event: Event) =>
    audioNotifier.listen(event)
  );

  return (
    <>
      <AuthInterceptor />
      <Outlet />
    </>
  );
};
