export const colors = {
  primary: {
    darkest: '#141A1E',
    darker: '#24323C',
    lighter: '#6E777E',
    main: '#293B47',
    secondary: '#24323C',
    tertiary: '#243038',
    highlight: '#364C5B',
  },
  accent: {
    default: '#72CF7B',
    lighter: '#9DEDA5',
  },
  success: {
    default: '#9DEDA5',
  },
  error: {
    default: '#EA8686',
    darker: '#DA5757',
  },
  alert: {
    default: '#FCA778',
  },
  info: {
    default: '#86AEEA',
  },
  warning: {
    default: '#FCEF78',
  },
  typography: {
    heading: {
      light: '#E6E6E6',
      dark: '#161616',
    },
    text: {
      light: '#D1D1D1',
      dark: '#2E383F',
      disabled: '#AEAEAE',
    },
  },
  common: {
    background: '#1A2228',
  },
};
