import { Navigate, useParams } from 'react-router-dom';
import { AcceptProjectInvitationRequest } from '@prodelio/modules/projects/features/accept-project-invitation/requests/AcceptProjectInvitation.request';
import { Status, useApi } from '@prodelio/hooks/api/useApi';
import { toastError, toastSuccess } from '@prodelio/config/toast/toast';
import { useEventDispatch } from '@prodelio/event-system/hooks/useEventDispatch';
import { ProjectEvents } from '@prodelio/modules/projects/Project.events';

export const AcceptProjectInvitation = () => {
  const { token } = useParams();

  const request = new AcceptProjectInvitationRequest({
    token: token ?? '',
  });
  const { data, status } = useApi<{ success: boolean }>(request);

  const dispatchEvent = useEventDispatch();

  if (status === Status.LOADING || status === Status.WAITING) {
    return null;
  }

  if (!data) {
    toastError(
      'Invalid project invitation link.',
      'Make sure you have the correct url.'
    );

    return <Navigate to="/app/inbox" />;
  }

  dispatchEvent(ProjectEvents.RELOAD_PROJECTS);

  toastSuccess('Project invitation accepted.', '');
  return <Navigate to="/app/inbox" />;
};
