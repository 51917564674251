import { createStandaloneToast, ToastPosition } from '@chakra-ui/react';
import { theme } from '@prodelio/config/theme';

const { toast, ToastContainer } = createStandaloneToast(theme);

const defaultToastConfig = {
  duration: 5000,
  isClosable: true,
  position: 'top-left' as ToastPosition,
};

enum ToastStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

const throwToast = (
  title: string,
  description: string,
  status: ToastStatus
) => {
  toast({
    ...defaultToastConfig,
    status,
    title,
    description,
    duration: null,
  });
};

export const toastError = (title: string, description: string) => {
  throwToast(title, description, ToastStatus.ERROR);
};

export const toastSuccess = (title: string, description: string) => {
  throwToast(title, description, ToastStatus.SUCCESS);
};

export { ToastContainer, toast };
