import { Request } from '@prodelio/hooks/api/Request.class';
import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { endOfWeek, startOfWeek } from 'date-fns';
import { DateRange } from '@prodelio/modules/common/date/DateRange';

export class GetCurrentWeekTasksRequest extends Request {
  public method: ApiMethods = ApiMethods.GET;
  public url = '/tasks';

  constructor() {
    super();

    const today = new Date();

    const dateRange = new DateRange(startOfWeek(today), endOfWeek(today));

    this.addFilters({
      dueDate__lte: dateRange.end.toISOString(),
    });
  }
}
