import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    zIndex: 1001,
    border: 'none',
    borderRadius: 1,
    boxShadow: '0 4px 27px 0 rgba(22, 22, 22, 0.57)',
    bg: 'primary.highlight',
  },
  item: {
    bg: 'transparent',
    _hover: {
      bg: 'primary.main',
    },
    _focus: {
      bg: 'primary.main',
    },
  },
});

export const Menu = defineMultiStyleConfig({ baseStyle });
