import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { Request } from '@prodelio/hooks/api/Request.class';

interface ForgotPasswordRequestBody {
  email: string;
}

export class ForgotPasswordRequest extends Request {
  public method: ApiMethods = ApiMethods.POST;
  public url = '/mail/password/forgot';

  public init(body: ForgotPasswordRequestBody) {
    this.body = body;
  }
}
