import {
  TimeTrackerContextState,
  TimeTrackerStatus,
} from '@prodelio/context/time-tracker/TimeTracker.context';
import { TimeTrackerConstants } from '@prodelio/hooks/time-tracker/TimeTracker.constants';

export enum TimeTrackerActions {
  'PLAY' = 'PLAY',
  'PAUSE' = 'PAUSE',
  'STOP' = 'STOP',
  'NEXT_TIME' = 'NEXT_TIME',
}

interface TimeTrackerReducerPayload {
  taskId: string;
  secondInterval?: NodeJS.Timer | null | number;
  minuteInterval?: NodeJS.Timer | null | number;
}

interface TimeTrackerReducerAction {
  type: TimeTrackerActions;
  payload?: TimeTrackerReducerPayload;
}

export const TimeTrackerReducer = (
  state: TimeTrackerContextState,
  { type, payload }: TimeTrackerReducerAction
): TimeTrackerContextState => {
  const clearTimer = () => {
    const { secondInterval, minuteInterval } = state;
    if (!secondInterval || !minuteInterval) {
      return;
    }

    clearInterval(secondInterval!);
    clearInterval(minuteInterval!);
  };

  const actions = {
    [TimeTrackerActions.PLAY]: () => {
      clearTimer();

      return {
        ...state,
        ...payload,
        time: state.taskId !== payload?.taskId ? 0 : state.time,
        status: TimeTrackerStatus.ACTIVE,
      };
    },
    [TimeTrackerActions.NEXT_TIME]: () => ({
      ...state,
      ...payload,
      time: state.time + TimeTrackerConstants.SECOND,
    }),
    [TimeTrackerActions.PAUSE]: () => {
      clearTimer();

      return {
        ...state,
        status: TimeTrackerStatus.PAUSE,
        secondInterval: null,
        minuteInterval: null,
      };
    },
    [TimeTrackerActions.STOP]: () => {
      clearTimer();

      return {
        status: TimeTrackerStatus.IDLE,
        time: 0,
        taskId: null,
        secondInterval: null,
        minuteInterval: null,
      };
    },
  };

  return actions[type]();
};
