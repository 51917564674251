import { Project } from '@prodelio/config/state/projects/Project.interface';
import { getProject } from '@prodelio/config/state/projects/selectors/getProject';
import { getTask } from '@prodelio/modules/tasks/state/selectors/getTask';
import { Task } from '@prodelio/modules/tasks/state/types/Task';
import { ILink } from '@prodelio/router/breadcrumbs/interfaces/ILink';
import {
  IStrategy,
  Strategy,
} from '@prodelio/router/breadcrumbs/interfaces/IStrategy';

export class TaskStrategy extends Strategy implements IStrategy {
  private taskId = '';

  constructor(id: string) {
    super();
    this.taskId = id;
  }

  public getLinks(): ILink[] {
    const links: ILink[] = [];
    const activeTask = getTask(this.taskId);
    if (!activeTask) {
      return links;
    }

    let currentTask: Task | undefined = activeTask;
    while (currentTask) {
      links.push(this.transformItem(currentTask));
      if (currentTask.parentTaskId === null && currentTask.projectId) {
        const project = getProject(currentTask.projectId);
        if (project) {
          links.push(this.transformItem(project));
        }
      }

      currentTask = getTask(currentTask?.parentTaskId ?? '');
    }

    return links.reverse();
  }

  protected transformItem(item: Task | Project): ILink {
    const prefix = 'parentTaskId' in item ? 'tasks/' : 'project/';

    return {
      url: '/app/' + prefix + item.id,
      title: item.title,
      active: item.id === this.taskId,
    };
  }
}
