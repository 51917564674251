import { TimeTracker } from '@prodelio/components/time-tracker/TimeTracker.component';
import { useTimeTrackerContext } from '@prodelio/hooks/time-tracker/useTimeTrackerContext';
import { TrackTimeInterval } from '@prodelio/modules/tasks/time-tracker/TrackTime.interval';

export const TimeTrackerFeature = ({ id }: { id: string }) => {
  const {
    state: { time, taskId },
    onStop,
    onPause,
  } = useTimeTrackerContext();

  const onPlay = TrackTimeInterval(id);

  return (
    <TimeTracker
      time={time}
      isActive={id === taskId}
      displayTimer={false}
      onPlay={onPlay}
      onStop={onStop}
      onPause={onPause}
    />
  );
};
