import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';

export abstract class Request {
  public method: ApiMethods = ApiMethods.GET;
  public url = '';
  public body?: any;

  public immediate: boolean;

  protected id?: string;

  public constructor(body?: any, immediate?: boolean) {
    this.body = body;
    this.immediate = immediate ?? true;
  }

  getId() {
    return this.id;
  }

  public addFilters(filters: Record<string, string | number | Date>) {
    if (this.method === ApiMethods.GET) {
      this.url += '?';

      const params = [];
      for (const [key, value] of Object.entries(filters)) {
        params.push(`${key}=${value}`);
      }

      this.url += params.join('&');
    } else {
      this.body = this.body ? { ...this.body, ...filters } : filters;
    }
  }
}
