import {
  Select,
  SelectChangeEvent,
  SelectItem,
} from '@prodelio/components/select/Select';
import { ViewIcon } from '@chakra-ui/icons';
import { useViewConfig } from '@prodelio/config/state/app-config/selectors/useViewConfig';
import {
  AppViews,
  TaskViewEnum,
  useAppConfigStore,
} from '@prodelio/config/state/app-config/AppConfig';
import { useGetCurrentView } from '@prodelio/hooks/route-props/useGetCurrentView';

export const ViewSelector = () => {
  const currentView = useGetCurrentView();
  const { taskView, displayViewSelector } = useViewConfig(
    currentView ? currentView : ''
  );

  const changeViewConfig = useAppConfigStore(
    ({ changeViewConfig }) => changeViewConfig
  );

  if (!displayViewSelector) {
    return null;
  }

  const handleChange = (event: SelectChangeEvent) => {
    const newTaskView = event.target.value as TaskViewEnum;

    changeViewConfig(currentView as AppViews, {
      taskView: newTaskView,
    });
  };

  return (
    <Select
      onChange={handleChange}
      value={taskView}
      endIcon={<ViewIcon />}
      sx={{ height: 50 }}
    >
      <SelectItem value={TaskViewEnum.LIST}>List</SelectItem>
      <SelectItem value={TaskViewEnum.KANBAN}>Kanban</SelectItem>
    </Select>
  );
};
