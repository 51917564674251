import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortableItemProps {
  id: string;
  indentation: number;
  children: ReactNode;
}

export const SortableItem = ({
  id,
  indentation,
  children,
}: SortableItemProps) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    isDragging,
    transition,
    transform,
  } = useSortable({
    id,
  });

  const baseStyles = {
    transform: CSS.Transform.toString({
      y: 0,
      x: 0,
      scaleX: 1,
      ...transform,
      scaleY: 1,
    }),
    transition,
    pointerEvents: (isDragging
      ? 'none'
      : 'auto') as React.CSSProperties['pointerEvents'],
    paddingLeft: indentation + 'px',
  };

  const draggingStyles = {
    ...baseStyles,
    position: 'relative',
    left: indentation + 'px',
    height: '4px',
    overflow: 'hidden',
    background: 'var(--chakra-colors-accent-default)',
  };

  return (
    <Box
      ref={setNodeRef}
      sx={isDragging ? draggingStyles : baseStyles}
      {...listeners}
      {...attributes}
    >
      {children}
    </Box>
  );
};
