import { FormValues } from '@prodelio/hooks/form/reducer';
import { useValidation } from '@prodelio/hooks/form/useValidation';

export const useRegisterValidation = (formValues: FormValues) => {
  return useValidation({
    fullName: {
      validators: ['length', 'empty'],
    },
    username: {
      validators: ['length', 'empty'],
    },
    email: {
      validators: ['length', 'empty'],
    },
    password: {
      validators: ['password', 'empty'],
    },
    repeatPassword: {
      validators: ['same', 'empty'],
      options: {
        comparison: {
          field: 'password',
          value: formValues.password.value,
        },
      },
    },
  });
};
