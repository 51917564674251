import axios from 'axios';
import { Request } from '@prodelio/hooks/api/Request.class';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

export const apiWrapper = async (request: Request) => {
  if (!request.immediate) {
    return {
      data: {},
    };
  }

  return api({
    method: request.method,
    url: request.url,
    data: request.body,
  });
};
