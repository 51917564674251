import { apiWrapper } from '@prodelio/config/api/api';
import { Request } from '@prodelio/hooks/api/Request.class';
import { SetStore, TaskState } from '@prodelio/modules/tasks/state/TaskStore';
import {
  selectTasksV2,
  TaskFilters,
} from '@prodelio/modules/tasks/state/selectors/selectTasks';
import { SyncStatus, Task } from '@prodelio/modules/tasks/state/types/Task';
import { Status } from '@prodelio/hooks/api/useApi';
import { useTaskStore } from '@prodelio/modules/tasks/state/useTaskStore';
import { ObjectId } from 'bson';

export const syncTasks = async (
  set: SetStore<TaskState>,
  request: Request,
  filters: Partial<TaskFilters>
): Promise<void> => {
  try {
    set(() => ({
      statusTask: Status.LOADING,
    }));
    const res = await apiWrapper(request);
    if (!res) {
      return;
    }

    const serverTasks: Task[] = flattenTasks(res.data.data).map((task) => ({
      ...task,
      boardId: task.boardId ? new ObjectId(task.boardId) : null,
    }));
    const filteredLocalTasks: Task[] = flattenTasks(selectTasksV2(filters));

    const filteredLocalTasksMap: Map<string, Task> = filteredLocalTasks.reduce(
      (map, task) => {
        map.set(task.id, task);

        return map;
      },
      new Map()
    );

    const localTasks = useTaskStore
      .getState()
      .tasks.filter((task) => !filteredLocalTasksMap.has(task.id));

    const tasks = serverTasks
      .map((serverTask: Task) => {
        const localTask = filteredLocalTasksMap.get(serverTask.id);
        if (!localTask) {
          return serverTask;
        }

        filteredLocalTasksMap.delete(serverTask.id);

        return new Date(serverTask.updatedAt).getTime() >=
          new Date(localTask.updatedAt).getTime()
          ? serverTask
          : localTask;
      })
      .filter((task) => task.syncStatus !== SyncStatus.DELETED);

    tasks.push(
      ...Array.from(filteredLocalTasksMap.values()).filter(
        (task) => task.syncStatus !== SyncStatus.DELETED
      )
    );

    tasks.push(...localTasks);

    set(() => ({
      tasks,
      statusTask: Status.DONE,
    }));
  } catch (error) {
    set(() => ({
      statusTask: Status.ERROR,
    }));
  }
};

const flattenTasks = (tasks: Task[]): Task[] => {
  const flattenedTasks: Task[] = [];

  for (const { subtasks, ...task } of tasks) {
    flattenedTasks.push(task);

    if (subtasks) {
      flattenedTasks.push(...subtasks);
    }
  }

  return flattenedTasks;
};
