import { IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import { useBoardStore } from '@prodelio/modules/projects/state/useBoardStore';
import { Board } from '@prodelio/modules/projects/state/Board';
import { useParams } from 'react-router-dom';
import { BoardForm } from '@prodelio/modules/projects/components/BoardForm/BoardForm';
import { StateField } from '@prodelio/hooks/form/reducer';
import { EditBoardRequest } from '@prodelio/modules/projects/features/edit-board/EditBoardRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
  board: Board;
}

export const EditBoard = ({ board }: Props) => {
  const { projectId } = useParams();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const editBoard = useBoardStore().editBoard;

  if (!projectId) {
    return null;
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async (formValues: { [key: string]: StateField }) => {
    const updatedBoard: Board = {
      _id: board._id,
      title: formValues.title.value,
      projectId: projectId,
      position: board.position,
      syncStatus: null,
    };

    const request = new EditBoardRequest(updatedBoard);

    editBoard(request, updatedBoard);
    handleCloseModal();
  };

  return (
    <>
      <IconButton
        onClick={handleOpenModal}
        size="sm"
        variant="icon-link-primary"
        aria-label="Edit board"
        icon={<FontAwesomeIcon icon={solid('pen')} />}
      />
      <BoardForm
        title="Edit board"
        onSubmit={handleSubmit}
        onClose={handleCloseModal}
        open={openModal}
        board={board}
      />
    </>
  );
};
