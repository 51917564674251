import { ApiMethods } from '@prodelio/hooks/api/ApiMethods.enum';
import { Request } from '@prodelio/hooks/api/Request.class';

export interface AuthBody {
  username: string;
  password: string;
}

export class LoginRequest extends Request {
  public method: ApiMethods = ApiMethods.POST;
  public url = '/auth/login';
}
